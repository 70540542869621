import { create } from "zustand";
import { lib } from "jacy";

import { Cube, Lightning } from "@components/icons";

import { generateGroupName } from "@lib/helpers/block/generateBlockInfo";

import { useEventsEditorStore } from "./events-editor";
import { useControlsStore } from "./controls";
import { useWorldStore } from "./world";
import { Engine, useEngineStore } from "./bb";
import { useInventoryStore } from "./dialogs/inventory";
import { IEventsJSON } from "@lib/types/nodes/node-defs";
import { useConfirmPromptStore } from "./dialogs";

export enum WrenchMode {
	BLOCK = "block",
	AI = "ai",
}

export const TABS = [
	{ label: "Basic", icon: Cube },
	{ label: "Events", icon: Lightning },
];

type ISelectedGroup = Map<string, number>;
type IBlock = { x: number; y: number; z: number };
type IUseWrenchBlockArgs = {
	selectedGroups?: ISelectedGroup;
	blocks?: IBlock[] | null;
	group?: string;
};

type IBlockWrenchStore = {
	open: boolean;
	selectedTab: string;
	isRefreshing: boolean;

	selectedGroups: ISelectedGroup;
	group: null | string;
	blocks: null | IBlock[];
	events: null | IEventsJSON;

	// Handlers
	setSelectedTab: (selectedTab: string) => void;
	setGroup: (group: string) => void;

	// Actions
	use: (args?: IUseWrenchBlockArgs) => Promise<void>;
	save: () => void;
	close: (runSideEffects?: boolean) => void;
};

export const useBlockWrenchStore = create<IBlockWrenchStore>((set, get) => ({
	open: false,
	selectedTab: TABS[0].label,
	isRefreshing: false,

	selectedGroups: new Map(),
	group: null,
	blocks: null,
	events: null,

	// Handlers
	setSelectedTab: (selectedTab) => {
		set({ selectedTab });

		if (selectedTab === "Events") {
			useEventsEditorStore.getState().setOpen(true);
		} else {
			useEventsEditorStore.getState().setOpen(false);
		}
	},
	setGroup: (group) => {
		set({ group });

		const isModified = useEventsEditorStore.getState().modified;
		if (isModified) return;

		const { use, selectedGroups, blocks } = get();

		use({
			group,
			selectedGroups,
			blocks,
		});
	},

	use: async (args = {}) => {
		const { BB, gbi } = new Engine();
		const { selectedGroups, blocks } = args;

		if (BB == null || gbi == null) return;

		let group = args.group ?? null;
		if (group == null && selectedGroups && selectedGroups.size === 1)
			group = Array.from(selectedGroups.keys())[0];

		const updatedState = {
			selectedGroups,
			blocks,
			group: group || (await generateGroupName()),
			events: null,
		};

		if (group != null) {
			set({ isRefreshing: true });

			const events = await gbi.router.BlockGroupRouter.getWrenchEvents(
				BB.world.scene,
				updatedState.group,
			);

			if (events) {
				updatedState.events = JSON.parse(events);
			}

			set({ isRefreshing: false });
		}

		useEventsEditorStore.getState().setEvents(updatedState.events);

		const lastOpenUI = useWrenchStore.getState().lastOpenUI;

		set(updatedState);
		set({ open: true });

		get().setSelectedTab(lastOpenUI ? TABS[1].label : TABS[0].label);

		useWorldStore.getState().refreshCpu();
	},
	save: () => {
		const {
			engine: { BB, gbi, Metrics },
		} = useEngineStore.getState();

		const { client, clear } = useEventsEditorStore.getState();
		const { group, blocks } = get();

		if (!gbi || !client) return;

		if (blocks) {
			for (const block of blocks) {
				BB.world.scene.setMetadata(block, "group", group);
			}
		}

		gbi.router.BlockGroupRouter.setWrenchEvents(
			BB.world.scene,
			group,
			client.getEvents(),
		);

		clear();

		useWrenchStore.getState().close();

		Metrics.addCount({
			name: "WrenchSaveBlockGroup",
		});
	},
	close: async (runSideEffects = true) => {
		const isModified = useEventsEditorStore.getState().modified;
		if (isModified) {
			const confirmed = await (useConfirmPromptStore.getState().prompt({
				title: "Unsaved changes",
				description: `Are you sure you want to close the editor without saving your changes?`,
				confirmText: "Yes",
				cancelText: "No",
			}) ?? Promise.resolve(true));

			if (!confirmed) return;
		}

		set({
			open: false,
			selectedGroups: new Map(),
			group: null,
			events: null,
			isRefreshing: false,
		});

		useEventsEditorStore.getState().clear();

		if (
			runSideEffects &&
			lib.helpers.general.isNullish(useWrenchStore.getState().lastOpenUI)
		) {
			useControlsStore.getState().exitPointerLock(false);
		}
	},
}));

type IUseWrenchAIArgs = {
	character?: string;
};

export enum UIModals {
	INVENTORY = "inventory",
}

type IWrenchStore = {
	mode: WrenchMode | null;
	lastOpenUI: UIModals | null;

	use: (
		mode: WrenchMode | null,
		args?: IUseWrenchBlockArgs | IUseWrenchAIArgs,
		lastOpenUI?: UIModals | null,
	) => void;
	close: () => void;
};

export const useWrenchStore = create<IWrenchStore>((set, get) => ({
	mode: null,
	lastOpenUI: null,

	use: (mode, args, lastOpenUI = null) => {
		if (mode && !Object.values(WrenchMode).includes(mode)) {
			throw new Error(`Invalid wrench mode: ${mode}`);
		}

		set({ mode, lastOpenUI });

		if (mode === WrenchMode.BLOCK) {
			useBlockWrenchStore.getState().use(args as IUseWrenchBlockArgs);
		} else {
			get().close();
		}

		useControlsStore.getState().exitPointerLock(true);
	},
	close: () => {
		const lastOpenUI = get().lastOpenUI;

		set({ mode: null, lastOpenUI: null });
		const isModified = useEventsEditorStore.getState().modified;
		useEventsEditorStore.getState().setOpen(false);
		useBlockWrenchStore.getState().close();

		if (lastOpenUI === "inventory" || isModified) {
			useInventoryStore.getState().toggle(true);
		} else {
			useControlsStore.getState().exitPointerLock(false);
		}
	},
}));

export default {
	useWrench: useWrenchStore.getState,
	useBlockWrench: useBlockWrenchStore.getState,
};
