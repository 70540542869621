import { create } from "zustand";
import { Jacy } from "@jacy-client";
import { AssetType, type ISelectorSlot, lib } from "jacy";

import { generateGroupName } from "@lib/helpers/block/generateBlockInfo";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";
import { Engine } from "../bb";
import { useBulkBloganStore } from "../bulk-blogan";
import { usePermissionStore } from "../player";
import { useSelectorStore } from "./selector";

export enum InteractableType {
	PLAYER = "player",
	ITEM_PICKUP = "item_pickup",
}

export enum WrenchState {
	SELECT_CORNER_1 = 0,
	SELECT_CORNER_2 = 1,
	ACTION = 2,
}

export enum EquippedItem {
	WRENCH = "ItemWrench",
	SPAWNER = "ItemSpawner",
	BULK_BLOGAN = "ItemBulkBlogan",
	CAMERA_TOOL = "ItemCameraTool",
	MANIPULATOR = "ItemManipulator",
	BLOCK = "ItemBlock_", // this is a prefix - ItemBlock_ + blockType (e.g. ItemBlock_bb.block.world.stone)
}

export const CREATOR_SELECTOR_SLOT = {
	test: "Test Mode",
	play: "Play Mode",
};

export const CREATOR_SELECTOR_SLOTS = [
	CREATOR_SELECTOR_SLOT.test,
	CREATOR_SELECTOR_SLOT.play,
];

type IItemState = {
	equippedItem: string | null;
	wrenchState: WrenchState;
	interactable: InteractableType | null;

	// Handlers
	setWrenchState: (wrenchState: WrenchState) => void;
	interact: () => void;
	setInteractable: (interactable: InteractableType | null) => void;

	// Actions
	use: (args: {
		selection: string;
		group?: string;
		slot?: ISelectorSlot;
	}) => Promise<void>;
	usePrimary: () => void;
	useSecondary: () => void;
	unequipItem: () => void;
	setEquippedItem: (equippedItem: string | null) => void;
	cycleTool: () => void;
};

export const useItemStore = create<IItemState>((set) => ({
	equippedItem: null, // item def or null
	wrenchState: 0,
	interactable: null,

	// Handlers
	setWrenchState: (wrenchState) => set({ wrenchState }),
	interact: () => {
		if (isAnyModalOpen()) return;

		const { BB, client } = new Engine();
		const input = BB[client].input;

		input.playerDoInteract = true;
	},
	setInteractable: (interactable) => set({ interactable }),
	use: async ({ selection, group, slot }) => {
		if (slot?.type !== AssetType.CHARACTER) return;

		const state = {
			group: group || (await generateGroupName()),
			selection,
			events: null,
		};

		const { BB, gbi, Rete } = new Engine();

		if (!BB?.world || !gbi || !Rete) return;

		if (!lib.helpers.general.isNullish(state.group)) {
			const events = await gbi.router.BlockGroupRouter.getWrenchEvents(
				BB.world.scene,
				state.group,
			);

			if (events) {
				state.events = JSON.parse(events);
			}
		}

		if (state.selection) {
			for (const block of gbi.base.bulkBox(state.selection, true)) {
				BB.world.scene.setMetadata(block, "group", state.group);
			}
		}

		const character = Jacy.state.characters.get(slot.pk);

		if (!character) {
			console.error("Failed to add character node");
			return;
		}

		const editor = new Rete.EventsManipulator(state.group, state.events);
		const { defNodeId } = editor.addSpawner("character", { name: slot.id });

		if (!defNodeId) {
			console.error("Failed to add character node");
			return;
		}

		editor.attachCharacterEvents(defNodeId, slot.id, character.events);
		editor.execute();
	},
	// Triggers the left-click action for the current object (used on mobile)
	usePrimary: () => {
		const { BB, client } = new Engine();
		BB[client].input.playerDoItemPrimary = true;
	},
	// Triggers the right-click action for the current object (used on mobile)
	useSecondary: () => {
		const { BB, client } = new Engine();
		BB[client].input.playerDoItemSecondary = true;
	},
	unequipItem: () => {
		const { BB, client } = new Engine();

		const target = BB.world?.[client].camera.target;
		if (!target.type.def.isPlayer) return;
		target.unequipItem();
	},
	setEquippedItem: (equippedItem) => {
		set({ equippedItem });

		if (
			equippedItem === "ItemBulkBlogan" &&
			useBulkBloganStore.getState().bloganContext > 0
		) {
			const selectedBlock = useSelectorStore.getState().selectedBlock;
			if (!selectedBlock) return;

			const { BB, client } = new Engine();
			if (!BB?.world) return;

			BB.world[client].selector.mostRecentType = selectedBlock.id;
		}
	},
	cycleTool: () => {
		const { BB, client } = new Engine();

		const target = BB.world[client].camera.target;
		if (!target || !target.type.def.isPlayer) return;

		const toolCycleTools = target.equipment.getToolCycle();
		if (toolCycleTools.length === 0) return;

		const canBuild = usePermissionStore.getState().checkCanBuild();
		let i = toolCycleTools.indexOf(target.getEquippedItem()?.def) + 1;

		const input = BB[client].input;

		if (i === toolCycleTools.length && canBuild) {
			const { selector, selectedSlot, setSelectedCharacter } =
				useSelectorStore.getState();

			const item = selector[selectedSlot];

			if (!item) {
				input.playerDoEquip = null;
			} else if (item.type === AssetType.BLOCK) {
				input.playerDoEquip = "ItemBlock_" + item.id;
			} else if (item.type === AssetType.CHARACTER) {
				setSelectedCharacter(item);
			}
		} else {
			i = i % toolCycleTools.length;
			input.playerDoEquip = toolCycleTools[i];
		}
	},
}));

export default {
	useItem: useItemStore.getState,
};
