import type { BlockTypeName, TerrainObject } from "jacy";

const BLOCK_CUBE = 15;

const LEAVES_BLOCK: BlockTypeName = "bb.block.world.leaves";

const getLog = (height: number) => {
	const result: TerrainObject = [];
	for (let y = 0; y < height; y++) {
		result.push([0, y, 0, BLOCK_CUBE, LEAVES_BLOCK]);
	}
	return result;
};

const getLeaf = (
	yOffset: number,
	height: number,
	xOffset: number,
	zOffset: number,
) => {
	const result: TerrainObject = [];
	const max = yOffset + height;
	for (let y = yOffset; y < max; y++) {
		result.push([xOffset, y, zOffset, BLOCK_CUBE, LEAVES_BLOCK]);
	}

	if (xOffset !== 0) {
		const xDir = Math.sign(xOffset);
		for (let x = xOffset - xDir; x !== 0; x -= xDir) {
			result.push([x, yOffset, zOffset, BLOCK_CUBE, LEAVES_BLOCK]);
		}
	}

	if (zOffset !== 0) {
		const zDir = Math.sign(zOffset);
		for (let z = zOffset - zDir; z !== 0; z -= zDir) {
			result.push([xOffset, yOffset, z, BLOCK_CUBE, LEAVES_BLOCK]);
		}
	}

	return result;
};

export const CactusesObjects: TerrainObject[] = [
	[...getLog(5), ...getLeaf(1, 3, 2, 0)],
	[...getLog(6), ...getLeaf(1, 3, -2, 0), ...getLeaf(2, 3, 0, 2)],
	[
		...getLog(7),
		...getLeaf(1, 3, -2, 0),
		...getLeaf(2, 3, 0, -2),
		...getLeaf(3, 4, 2, 0),
	],
];

export const SPACE_BLOCK_NAME: BlockTypeName = "bb.block.spaceStation.metal-grate-2";

export const createPlatform = (
	pivotX: number,
	pivotY: number,
	pivotZ: number,
	width: number,
	height: number,
	depth: number,
	blockName: BlockTypeName,
) => {
	const blocks: TerrainObject = [];

	for (let y = 0; y < height; y++) {
		for (let z = 0; z < depth; z++) {
			for (let x = 0; x < width; x++) {
				blocks.push([pivotX + x, pivotY + y, pivotZ + z, BLOCK_CUBE, blockName]);
			}
		}
	}

	return blocks;
};

export const SIMPLE_TREE: TerrainObject = [
	[0, 0, 0, 101, "bb.block.world.wood"],
	[0, 0, 1, 15, "bb.block.world.wood"],
	[0, 0, 2, 26, "bb.block.world.wood"],
	[1, 0, 1, 74, "bb.block.world.wood"],
	[0, 1, 1, 15, "bb.block.world.wood"],
	[0, 2, 1, 15, "bb.block.world.wood"],
	[-1, 3, 0, 15, "bb.block.world.leaves"],
	[0, 3, 0, 15, "bb.block.world.leaves"],
	[1, 3, 0, 15, "bb.block.world.leaves"],
	[-1, 3, 1, 15, "bb.block.world.leaves"],
	[0, 3, 1, 15, "bb.block.world.wood"],
	[1, 3, 1, 15, "bb.block.world.leaves"],
	[-1, 3, 2, 15, "bb.block.world.leaves"],
	[0, 3, 2, 15, "bb.block.world.leaves"],
	[1, 3, 2, 15, "bb.block.world.leaves"],
	[-2, 3, -1, 84, "bb.block.world.leaves"],
	[-1, 3, -1, 85, "bb.block.world.leaves"],
	[0, 3, -1, 85, "bb.block.world.leaves"],
	[1, 3, -1, 85, "bb.block.world.leaves"],
	[2, 3, -1, 81, "bb.block.world.leaves"],
	[-2, 3, 0, 92, "bb.block.world.leaves"],
	[2, 3, 0, 83, "bb.block.world.leaves"],
	[-2, 3, 1, 92, "bb.block.world.leaves"],
	[2, 3, 1, 83, "bb.block.world.leaves"],
	[-2, 3, 2, 92, "bb.block.world.leaves"],
	[2, 3, 2, 83, "bb.block.world.leaves"],
	[-2, 3, 3, 88, "bb.block.world.leaves"],
	[-1, 3, 3, 90, "bb.block.world.leaves"],
	[0, 3, 3, 90, "bb.block.world.leaves"],
	[1, 3, 3, 90, "bb.block.world.leaves"],
	[2, 3, 3, 82, "bb.block.world.leaves"],
	[-2, 4, -1, 108, "bb.block.world.leaves"],
	[-1, 4, -1, 15, "bb.block.world.leaves"],
	[0, 4, -1, 15, "bb.block.world.leaves"],
	[1, 4, -1, 15, "bb.block.world.leaves"],
	[2, 4, -1, 99, "bb.block.world.leaves"],
	[-2, 4, 0, 15, "bb.block.world.leaves"],
	[-1, 4, 0, 15, "bb.block.world.leaves"],
	[0, 4, 0, 15, "bb.block.world.leaves"],
	[1, 4, 0, 15, "bb.block.world.leaves"],
	[2, 4, 0, 15, "bb.block.world.leaves"],
	[-2, 4, 1, 15, "bb.block.world.leaves"],
	[-1, 4, 1, 15, "bb.block.world.leaves"],
	[0, 4, 1, 15, "bb.block.world.wood"],
	[1, 4, 1, 15, "bb.block.world.leaves"],
	[2, 4, 1, 15, "bb.block.world.leaves"],
	[-2, 4, 2, 15, "bb.block.world.leaves"],
	[-1, 4, 2, 15, "bb.block.world.leaves"],
	[0, 4, 2, 15, "bb.block.world.leaves"],
	[1, 4, 2, 15, "bb.block.world.leaves"],
	[2, 4, 2, 15, "bb.block.world.leaves"],
	[-2, 4, 3, 28, "bb.block.world.leaves"],
	[-1, 4, 3, 15, "bb.block.world.leaves"],
	[0, 4, 3, 15, "bb.block.world.leaves"],
	[1, 4, 3, 15, "bb.block.world.leaves"],
	[2, 4, 3, 19, "bb.block.world.leaves"],
	[-2, 5, -1, 108, "bb.block.world.leaves"],
	[-1, 5, -1, 15, "bb.block.world.leaves"],
	[0, 5, -1, 15, "bb.block.world.leaves"],
	[1, 5, -1, 15, "bb.block.world.leaves"],
	[2, 5, -1, 99, "bb.block.world.leaves"],
	[-2, 5, 0, 15, "bb.block.world.leaves"],
	[-1, 5, 0, 15, "bb.block.world.leaves"],
	[0, 5, 0, 15, "bb.block.world.leaves"],
	[1, 5, 0, 15, "bb.block.world.leaves"],
	[2, 5, 0, 15, "bb.block.world.leaves"],
	[-2, 5, 1, 15, "bb.block.world.leaves"],
	[-1, 5, 1, 15, "bb.block.world.leaves"],
	[0, 5, 1, 15, "bb.block.world.leaves"],
	[1, 5, 1, 15, "bb.block.world.leaves"],
	[2, 5, 1, 15, "bb.block.world.leaves"],
	[-2, 5, 2, 15, "bb.block.world.leaves"],
	[-1, 5, 2, 15, "bb.block.world.leaves"],
	[0, 5, 2, 15, "bb.block.world.leaves"],
	[1, 5, 2, 15, "bb.block.world.leaves"],
	[2, 5, 2, 15, "bb.block.world.leaves"],
	[-2, 5, 3, 28, "bb.block.world.leaves"],
	[-1, 5, 3, 15, "bb.block.world.leaves"],
	[0, 5, 3, 15, "bb.block.world.leaves"],
	[1, 5, 3, 15, "bb.block.world.leaves"],
	[2, 5, 3, 19, "bb.block.world.leaves"],
	[-2, 6, -1, 40, "bb.block.world.leaves"],
	[-1, 6, -1, 42, "bb.block.world.leaves"],
	[0, 6, -1, 42, "bb.block.world.leaves"],
	[1, 6, -1, 42, "bb.block.world.leaves"],
	[2, 6, -1, 34, "bb.block.world.leaves"],
	[-2, 6, 0, 44, "bb.block.world.leaves"],
	[-1, 6, 0, 15, "bb.block.world.leaves"],
	[0, 6, 0, 15, "bb.block.world.leaves"],
	[1, 6, 0, 15, "bb.block.world.leaves"],
	[2, 6, 0, 35, "bb.block.world.leaves"],
	[-2, 6, 1, 44, "bb.block.world.leaves"],
	[-1, 6, 1, 15, "bb.block.world.leaves"],
	[0, 6, 1, 15, "bb.block.world.leaves"],
	[1, 6, 1, 15, "bb.block.world.leaves"],
	[2, 6, 1, 35, "bb.block.world.leaves"],
	[-2, 6, 2, 44, "bb.block.world.leaves"],
	[-1, 6, 2, 15, "bb.block.world.leaves"],
	[0, 6, 2, 15, "bb.block.world.leaves"],
	[1, 6, 2, 15, "bb.block.world.leaves"],
	[2, 6, 2, 35, "bb.block.world.leaves"],
	[-2, 6, 3, 36, "bb.block.world.leaves"],
	[-1, 6, 3, 37, "bb.block.world.leaves"],
	[0, 6, 3, 37, "bb.block.world.leaves"],
	[1, 6, 3, 37, "bb.block.world.leaves"],
	[2, 6, 3, 33, "bb.block.world.leaves"],
	[-1, 7, 0, 40, "bb.block.world.leaves"],
	[0, 7, 0, 42, "bb.block.world.leaves"],
	[1, 7, 0, 34, "bb.block.world.leaves"],
	[-1, 7, 1, 44, "bb.block.world.leaves"],
	[0, 7, 1, 15, "bb.block.world.leaves"],
	[1, 7, 1, 35, "bb.block.world.leaves"],
	[-1, 7, 2, 36, "bb.block.world.leaves"],
	[0, 7, 2, 37, "bb.block.world.leaves"],
	[1, 7, 2, 33, "bb.block.world.leaves"],
];
