import { cn } from "@lib/helpers/cn";
import { CustomCSSProps, GeneralInputs, GeneralStyles } from "@stores/custom-ui";
import React from "react";
import { constants } from "rest-client";

interface IProps {
	styles: GeneralStyles;
	config: GeneralInputs;
	isPreview?: boolean;
}

export function BasicIndicator({ styles, config, isPreview = false }: IProps) {
	const styleConfiguration = constants.customUI.formatStyles(
		styles,
		"basic-indicator",
	) as CustomCSSProps;

	const textShadowConfig = {
		"--text-shadow-color": styleConfiguration["--basic-indicator-textShadowColor"],
		"--text-stroke-color": styleConfiguration["--basic-indicator-textStrokeColor"],
	} as React.CSSProperties;

	return (
		<div
			className={cn(
				"relative flex items-center",
				//font Weight
				"!font-[var(--basic-indicator-fontWeight)]",
				!isPreview && "lg:!font-[var(--basic-indicator-fontWeight-lg)]",
				// margin
				"m-[var(--basic-indicator-margin)]",
				!isPreview && "lg:m-[var(--basic-indicator-margin-lg)]",
				// border radius
				"rounded-[var(--basic-indicator-borderRadius)]",
				!isPreview && "lg:rounded-[var(--basic-indicator-borderRadius-lg)]",
				//background color
				"bg-[var(--basic-indicator-backgroundColor)]",
				!isPreview && "lg:bg-[var(--basic-indicator-backgroundColor-lg)]",
				// padding
				"p-[var(--basic-indicator-padding)]",
				!isPreview && "lg:p-[var(--basic-indicator-padding-lg)]",
				// font family
				"font-[family-name:var(--basic-indicator-fontFamily)]",
				!isPreview && "lg:font-[family-name:var(--basic-indicator-fontFamily-lg)]",
				// font size
				"text-[length:var(--basic-indicator-fontSize)]",
				!isPreview && "lg:text-[length:var(--basic-indicator-fontSize-lg)]",
				//color
				"text-[var(--basic-indicator-color)]",
				!isPreview && "lg:text-[var(--basic-indicator-color-lg)]",
				// position
				"!top-[var(--basic-indicator-position-top)]",
				"!left-[var(--basic-indicator-position-left)]",
				"!bottom-[var(--basic-indicator-position-bottom)]",
				"!right-[var(--basic-indicator-position-right)]",
				"gap-[var(--basic-indicator-gap)]",
				"lg:gap-[var(--basic-indicator-gap-lg)]",
			)}
			style={styleConfiguration}
		>
			<p
				className={cn(
					"!font-stroke-[var(--basic-indicator-textStroke)]",
					"!text-shadow-[var(--basic-indicator-textShadow)]",
					"text-inherit",
					styleConfiguration["--basic-indicator-textAlign"] === "center" &&
						"text-center lg:text-center",
					styleConfiguration["--basic-indicator-textAlign"] === "left" &&
						"text-left lg:text-left",
					styleConfiguration["--basic-indicator-textAlign"] === "right" &&
						"text-right lg:text-right",
					styleConfiguration["--basic-indicator-textAlign"] === "justify" &&
						"text-justify lg:text-justify",
				)}
				style={textShadowConfig}
			>
				{config?.text?.value ?? "Add Text"}
			</p>
			<p
				className={cn(
					"!font-stroke-[var(--basic-indicator-textStroke)]",
					"!text-shadow-[var(--basic-indicator-textShadow)]",
					"text-inherit",
					styleConfiguration["--basic-indicator-textAlign"] === "center" &&
						"text-center lg:text-center",
					styleConfiguration["--basic-indicator-textAlign"] === "left" &&
						"text-left lg:text-left",
					styleConfiguration["--basic-indicator-textAlign"] === "right" &&
						"text-right lg:text-right",
					styleConfiguration["--basic-indicator-textAlign"] === "justify" &&
						"text-justify lg:text-justify",
				)}
				style={textShadowConfig}
			>
				{config?.value?.value ?? "Add Value"}
			</p>
		</div>
	);
}
