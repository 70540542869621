import React from "react";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "@lib/helpers/cn";

const portalElement = document.getElementById("portal");

function DialogContent({ children, overlayClassname, className, ...props }) {
	const handleContextMenu = (evt) => evt.preventDefault();

	return (
		<DialogPrimitive.Portal container={portalElement}>
			<DialogPrimitive.Overlay
				className={cn("fixed inset-0 bg-slate-900 bg-opacity-25", overlayClassname)}
				onContextMenu={handleContextMenu}
			/>
			<DialogPrimitive.Content
				className={cn(
					"fixed left-1/2 top-1/2 z-10 h-full max-h-[80vh] w-full -translate-x-1/2 -translate-y-1/2 animate-show text-slate-900 sm-h:h-auto sm-h:max-h-[85vh]",
					className,
				)}
				{...props}
			>
				{children}
			</DialogPrimitive.Content>
		</DialogPrimitive.Portal>
	);
}

export const Dialog = {
	Root: DialogPrimitive.Root,
	Trigger: DialogPrimitive.Trigger,
	Content: DialogContent,
	Title: DialogPrimitive.Title,
	Description: DialogPrimitive.Description,
};
