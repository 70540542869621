import Pusher, { Channel } from "pusher-js";
import { AssetType, IAssetKey } from "jacy";

export type IWorldSaveEvent = {
	identifier: string;
	success: {
		pk: IAssetKey;
		resource: AssetType;
		message: string;
	}[];
	errors?: {
		resource: AssetType;
		error: string[];
	}[];
};

export type IWorldSavingEvent = {
	total: number;
	current: number;
	assetType: AssetType;
};

export class JacyWebsockets {
	pusher: Pusher;
	channel: Channel;

	constructor() {
		this.pusher = new Pusher(globalEnv.PUSHER_KEY, {
			cluster: "eu",
		});
		this.channel = this.pusher.subscribe("jamango");
	}

	createRequestPromise<EventResponse>(event: string) {
		return new Promise<EventResponse>((resolve) => {
			const callback = (data: EventResponse) => {
				this.channel.unbind(event, callback);
				resolve(data);
			};

			this.channel.bind(event, callback);
		});
	}

	createChunkRequestPromise<EventResponse>(event: string) {
		return new Promise<EventResponse>((resolve) => {
			const chunks: string[] = [];

			const callback = (payload: [number, number, string]) => {
				const [total, i, chunk] = payload;

				chunks[i] = chunk;

				if (chunks.length === total) {
					this.channel.unbind(event, callback);
					resolve(JSON.parse(chunks.join("")));
				}
			};

			this.channel.bind(event, callback);
		});
	}

	on(event: string, callback: (data: any) => void) {
		this.channel.bind(event, callback);

		return () => {
			this.channel.unbind(event, callback);
		};
	}
}
