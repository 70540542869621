import { cn } from "@lib/helpers/cn";
import { usePlayerStore } from "@stores/player";
import { constants } from "rest-client";
import { CustomCSSProps, GeneralInputs, GeneralStyles } from "@stores/custom-ui";
import React from "react";

interface IProps {
	styles: GeneralStyles;
	config: GeneralInputs;
	isPreview?: boolean;
}

export function HealthBar({ styles, config, isPreview = false }: IProps) {
	const health = usePlayerStore((state) => state.health);
	const stylesConfiguration = constants.customUI.formatStyles(
		styles,
		"health-bar",
	) as CustomCSSProps;

	const bgColorInStyle = {
		width: `${isPreview ? 50 : health}%`,
		"--health-bar-backgroundColorIn":
			stylesConfiguration["--health-bar-backgroundColorIn"],
		"--health-bar-backgroundColorIn-lg":
			stylesConfiguration["--health-bar-backgroundColorIn-lg"],
	} as React.CSSProperties;
	const bgColorOutStyle = {
		"--health-bar-backgroundColorOut-lg":
			stylesConfiguration["--health-bar-backgroundColorOut-lg"],
		"--health-bar-backgroundColorOut":
			stylesConfiguration["--health-bar-backgroundColorOut"],
	} as React.CSSProperties;
	const textShadowConfig = {
		"--text-shadow-color": stylesConfiguration["--health-bar-textShadowColor"],
		"--text-stroke-color": stylesConfiguration["--health-bar-textStrokeColor"],
	} as React.CSSProperties;

	return (
		<div
			className={cn(
				"relative flex select-none items-center",
				// font weight
				"!font-[var(--health-bar-fontWeight)]",
				"lg:!font-[var(--health-bar-fontWeight-lg)]",
				//margin
				"m-[var(--health-bar-margin)]",
				"lg:m-[var(--health-bar-margin-lg)]",
				// padding
				"p-[var(--health-bar-padding)]",
				"lg:p-[var(--health-bar-padding-lg)]",
				// font family
				"font-[family-name:var(--health-bar-fontFamily)]",
				"lg:font-[family-name:var(--health-bar-fontFamily-lg)]",
				// font size
				"text-[length:var(--health-bar-fontSize)]",
				"lg:text-[length:var(--health-bar-fontSize-lg)]",
				// color
				"text-[var(--health-bar-color)]",
				"lg:text-[var(--health-bar-color-lg)]",
				// position
				"!top-[var(--health-bar-position-top)]",
				"!left-[var(--health-bar-position-left)]",
				"!bottom-[var(--health-bar-position-bottom)]",
				"!right-[var(--health-bar-position-right)]",
				"gap-[var(--health-bar-gap)]",
				"lg:gap-[var(--health-bar-gap-lg)]",
			)}
			style={stylesConfiguration}
		>
			{config?.text?.value && (
				<p
					className={cn(
						"font-semibold text-inherit",
						"!font-stroke-[var(--health-bar-textStroke)]",
						"!text-shadow-[var(--health-bar-textShadow)]",
					)}
					style={textShadowConfig}
				>
					{config?.text?.value}
					<span className="sr-only">{health}%</span>
				</p>
			)}

			<div
				className={cn(
					"overflow-hidden rounded",
					// Border radius
					"rounded-[var(--health-bar-borderRadius)]",
					"lg:rounded-[var(--health-bar-borderRadius-lg)]",
					// background color out
					"bg-[var(--health-bar-backgroundColorOut)]",
					"lg:bg-[var(--health-bar-backgroundColorOut-lg)]",
					// width
					"w-[var(--health-bar-width)]",
					"lg:w-[var(--health-bar-width-lg)]",
					// Height
					"h-[var(--health-bar-height)]",
					"lg:h-[var(--health-bar-height-lg)]",
				)}
				aria-hidden="true"
				style={bgColorOutStyle}
			>
				<div
					className={cn(
						"h-full",
						// Background Color In
						"bg-[var(--health-bar-backgroundColorIn)]",
						"lg:bg-[var(--health-bar-backgroundColorIn-lg)]",
					)}
					style={bgColorInStyle}
				/>
			</div>
		</div>
	);
}
