import { PromptDialog } from "@components/ui/PromptDialog";
import { ConfirmDialog } from "@components/ui/ConfirmDialog";
import { ErrorDialog } from "@components/ui/ErrorDialog";
import { OtherAlertDialog } from "@components/ui/OtherAlertDialog";
import { GameCanvas } from "@components/game/GameCanvas";
import { SignInDialog } from "@components/auth/SignInDialog";
import { RegisterDialog } from "@components/auth/RegisterDialog";
import { HUDPopup } from "@components/hud/HUDPopup";
import { CustomUIHUD } from "@components/hud/CustomUIHUD";
import { LoadWorldModal } from "@components/landing/LoadWorldModal";
import { ImportBlockPackModal } from "@components/world/ImportBlockPackModal";

export function GlobalComponents() {
	return (
		<>
			<SignInDialog />
			<RegisterDialog />
			<LoadWorldModal />
			{/* YOU CAN ADD HUD POPUPS HERE */}
			<GameCanvas>
				<HUDPopup />
				<CustomUIHUD />
			</GameCanvas>
			<ImportBlockPackModal/>
			<ConfirmDialog />
			<PromptDialog />
			<ErrorDialog />
			<OtherAlertDialog />
		</>
	);
}
