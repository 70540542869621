import type {
	BlockTypeName,
	DeepPartial,
	IEnvironmentPreset,
	TerrainGenerationOptions,
} from "jacy";
import { constants } from "rest-client";
import { createPlatform } from "./objects";

type InventorySlot =
	| { type: "block"; id: BlockTypeName }
	| { type: "character"; id: string };

export type WorldTemplateOptions = {
	terrainGeneration: TerrainGenerationOptions;
	defaultInventory?: InventorySlot[];
	envPresetSettings?: DeepPartial<IEnvironmentPreset["preset"]>;
};

const BARREN_DESERT_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: [],
	ground: {
		amplitude: 2,
		vrTransition: 15,
		noiseOptions: { scale: 500, octaves: 3 },
		foundationLevel: -10,
		topBlock: "bb.block.world.sand",
		midBlock: "bb.block.world.sand",
		foundationBlock: "bb.block.world.sand",
	},
};

const TROPICAL_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksTropical"],
	ground: {
		amplitude: 3,
		vrTransition: 25,
		noiseOptions: { scale: 50, octaves: 3 },
		foundationLevel: -3,
		topBlock: "bb.block.tropical.jungle-moss",
		midBlock: "bb.block.tropical.jungle-mud",
		foundationBlock: "bb.block.tropical.jungle-mud",
	},
	water: {
		level: 1,
		sandBlock: "bb.block.world.sand",
	},
};

const SNOW_TUNDRA_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksTropical"],
	ground: {
		amplitude: 7,
		vrTransition: 15,
		noiseOptions: { scale: 250, octaves: 5 },
		foundationLevel: -10,
		topBlock: "bb.block.tropical.snowy-grass",
		midBlock: "bb.block.world.dirt",
		foundationBlock: "bb.block.world.stone",
	},
	mountains: {
		amplitude: 200,
		noiseOptions: { scale: 250, octaves: 4 },
		probability: 0.35,
		mountainBlock: "bb.block.world.snow",
	},
};

const DEEP_SPACE_TERRAIN: TerrainGenerationOptions = {
	type: "custom",
	version: 1,
	blockPacks: ["BlocksSpaceStation"],
	objectsPlacers: [
		{
			type: "static",
			objects: [
				{
					factory: {
						type: "static",
						template: createPlatform(
							0,
							0,
							0,
							10,
							1,
							10,
							"bb.block.spaceStation.metal-grate-1",
						),
					},
					positions: [[-5, -1, -5]],
				},
			],
		},
	],
};

type WorldTemplate = {
	title: string;
	description: string;
	thumbnailSrc: string;
	options: WorldTemplateOptions;
};

export const BlankWorldTemplate: WorldTemplate = {
	title: `Blank`,
	description: "An empty flat world",
	thumbnailSrc: constants.images.IMAGES.EMPTY_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: { type: "blank" },
		defaultInventory: [
			{ type: "block", id: "bb.block.color.red.tile" },
			{ type: "block", id: "bb.block.color.red.halftile" },
			{ type: "block", id: "bb.block.color.red.quartertile" },
			{ type: "block", id: "bb.block.color.orange.tile" },
			{ type: "block", id: "bb.block.color.orange.halftile" },
			{ type: "block", id: "bb.block.color.orange.quartertile" },
			{ type: "block", id: "bb.block.color.yellow.tile" },
			{ type: "block", id: "bb.block.color.yellow.halftile" },
			{ type: "block", id: "bb.block.color.yellow.quartertile" },
		],
	},
};

const MVRWorldTemplate: WorldTemplate = {
	title: `Grass and Mountains`,
	description: "A world with rivers, grass and mountains",
	thumbnailSrc: constants.images.IMAGES.DEFAULT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: { type: "mvr" },
		defaultInventory: [
			{ type: "block", id: "bb.block.world.grass" },
			{ type: "block", id: "bb.block.world.dirt" },
			{ type: "block", id: "bb.block.world.sand" },
			{ type: "block", id: "bb.block.world.ice" },
			{ type: "block", id: "bb.block.world.stone" },
			{ type: "block", id: "bb.block.world.cobblestone" },
			{ type: "block", id: "bb.block.world.wood" },
			{ type: "block", id: "bb.block.world.leaves" },
			{ type: "block", id: "bb.block.world.snow" },
		],
	},
};

const _BarrenDesertWorldTemplate: WorldTemplate = {
	title: "Barren Desert",
	description: "...?",
	thumbnailSrc: constants.images.IMAGES.BARREN_DESERT_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: BARREN_DESERT_TERRAIN,
		defaultInventory: [...(MVRWorldTemplate.options.defaultInventory ?? [])],
		envPresetSettings: {
			sky: {
				cloudColor: "#f6e9d5",
				fogColor: "#fff4ad",
				skyColor: "#5ac8f6",
			},
			fog: {
				fogNear: 1,
				fogFar: 150,
			},
		},
	},
};

const _TropicalWorldTemplate: WorldTemplate = {
	title: `Tropical`,
	description: "...?",
	thumbnailSrc: constants.images.IMAGES.EMPTY_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: TROPICAL_TERRAIN,
		defaultInventory: [
			{ type: "block", id: "bb.block.tropical.jungle-mossy-log" },
			{ type: "block", id: "bb.block.tropical.canopy-tree-log" },
			{ type: "block", id: "bb.block.tropical.palm-log" },
			{ type: "block", id: "bb.block.tropical.palm-leaf" },
			{ type: "block", id: "bb.block.tropical.jungle-mud" },
			{ type: "block", id: "bb.block.tropical.jungle-moss" },
		],
		envPresetSettings: {},
	},
};

const _SnowyTundraWorldTemplate: WorldTemplate = {
	title: `Snowy Tundra`,
	description: "Watch out for squirrels! They're hungry!",
	thumbnailSrc: constants.images.IMAGES.SNOWY_TUNDRA_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: SNOW_TUNDRA_TERRAIN,
	},
};

const _DeepSpaceWorldTemplate: WorldTemplate = {
	title: `Deep Space`,
	description: "Where is taxi?",
	thumbnailSrc: constants.images.IMAGES.DEEP_SPACE_WORLD_THUMBNAIL,
	options: {
		terrainGeneration: DEEP_SPACE_TERRAIN,
		envPresetSettings: {
			sky: {
				skyColor: "#000000",
				cloudCoverage: 0,
				fogColor: "#000000",
			},
			general: {
				hour: 0,
				azimuth: 0,
			},
			light: {
				nightLuminosity: 0,
			},
			fog: {
				fogNear: 200,
				fogFar: 200,
			},
		},
	},
};

export const Templates: WorldTemplate[] = [
	BlankWorldTemplate,
	MVRWorldTemplate,
	// _BarrenDesertWorldTemplate,
	// _TropicalWorldTemplate,
	// _SnowyTundraWorldTemplate,
	// _DeepSpaceWorldTemplate,
];
