import React from "react";
import { ArrowLeft, ArrowRight, RadioButton } from "@components/icons";

/*
    children = CarouselItem that will render inside the carousel
    items = {
        img: "URL FOR IMAGE"
    }
*/

export const Carousel = ({ children, items, CustomBtn = null, width, max = 6 }) => {
	const [activeIndex, setActiveIndex] = React.useState(0);

	const updateIndex = (newIndex) => {
		if (newIndex < 0) {
			newIndex = max - 1;
		} else if (newIndex >= max) {
			newIndex = 0;
		} else if (newIndex >= items.length) {
			newIndex = items.length - 1;
		}
		setActiveIndex(newIndex);
	};

	return (
		<div className={`flex h-auto flex-col overflow-hidden ${width ?? "w-full"}`}>
			<div
				className="block gap-2 whitespace-nowrap transition-all"
				style={{ transform: `translate(-${activeIndex * 100}%)` }}
			>
				{children}
			</div>

			{false && (
				<div className="flex w-full flex-row items-center justify-evenly">
					<button
						className="mt-4 cursor-pointer border-0 bg-none"
						onClick={() => {
							updateIndex(activeIndex - 1);
						}}
					>
						<ArrowLeft className="h-5 w-5 hover:text-pink-700" />
					</button>
					<div className="mt-4 flex h-auto flex-row items-center justify-around gap-1">
						{items.slice(0, 6).map((item, index) => {
							return (
								<button
									key={index * Math.random()}
									className="cursor-pointer border-0"
									onClick={() => {
										updateIndex(index);
									}}
								>
									{CustomBtn ? (
										<CustomBtn isCurrent={index === activeIndex} imgSrc={item.img} />
									) : (
										<RadioButton
											className="h-4 w-4"
											weight={index === activeIndex ? "fill" : "regular"}
										/>
									)}
								</button>
							);
						})}
					</div>
					<button
						className="mt-4 block cursor-pointer border-0 bg-none"
						onClick={() => {
							updateIndex(activeIndex + 1);
						}}
					>
						<ArrowRight className="h-5 w-5 hover:text-pink-700" />
					</button>
				</div>
			)}
		</div>
	);
};
