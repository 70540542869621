import { create } from "zustand";

type PlayerContexts = {
	inCreatorContext: boolean;
	inSculptContext: boolean;
	inManipulatorContext: boolean;
	inSelectorMouseWheelContext: boolean;
	controllingPlayer: boolean;
	controllingCameraDrone: boolean;
};

type PlayerContextStore = PlayerContexts & {
	setContexts: (contexts: PlayerContexts) => void;
};

export const usePlayerContextStore = create<PlayerContextStore>((set) => ({
	inCreatorContext: false,
	inSculptContext: false,
	inManipulatorContext: false,
	inSelectorMouseWheelContext: false,
	controllingPlayer: false,
	controllingCameraDrone: false,

	setContexts: (contexts: PlayerContexts) => set(contexts),
}));

export default {
	usePlayerContext: usePlayerContextStore.getState,
};
