import {
	IAudioChangeset,
	IBackgroundMusicChangeset,
	IBlockChangeset,
	IBlockTextureChangeset,
	ICustomLoaderChangeset,
	IEnvironmentPresetChangeset,
	IGameMechanicsChangeset,
	IJacyContent,
	IPermissionsChangeset,
	ISettingsChangeset,
	ISkyboxChangeset,
	IThumbnailChangeset,
	IWorldDataChangeset,
	lib,
} from "jacy";
import { JacyActions } from "../JacyActions";

export class JacyChangesetActions {
	#actions: JacyActions;
	#content: IJacyContent;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#content = actions.content;
		this.#state = actions.content.state;
	}

	async init() {
		await this.#createChangesetForAll();
	}

	async #createChangesetForAll() {
		if (!this.#shouldCreateWorld()) {
			return;
		}

		this.#createChangesetForWorldData();

		if (!this.#shouldCloneWorld()) {
			return;
		}

		this.#createChangesetForTags();
		this.#createChangesetForSettings();
		this.#createChangesetForGameMechanics();
		this.#createChangesetForPermissions();
		this.#createChangesetForEnvironmentPreset();
		this.#createChangesetForBlocks();

		await Promise.all([
			this.#createChangesetForBlockTextures(),
			this.#createChangesetForThumbnail(),
			this.#createChangesetForCustomLoader(),
			this.#createChangesetForBackgroundMusic(),
			this.#createChangesetForAudio(),
			this.#createChangesetForSkybox(),
		]);
	}

	#createChangesetForWorldData() {
		const worldData = this.#state.worldData.export();

		this.#actions.updateChangeset<IWorldDataChangeset>(worldData.pk, "create", {
			name: worldData.name,
			mode: worldData.mode,
			experiments: worldData.experiments,
		});
	}

	#createChangesetForTags() {
		for (const tag of this.#state.worldTags.export()) {
			this.#actions.updateChangeset(tag.pk, "create", tag);
		}
	}

	#createChangesetForSettings() {
		const settings = this.#state.settings.export();
		this.#actions.updateChangeset<ISettingsChangeset>(settings.pk, "update", {
			dayCycle: settings.dayCycle,
		});
	}

	#createChangesetForGameMechanics() {
		const gameMechanics = this.#state.gameMechanics.export();
		const defaultGameMechanics = this.#state.gameMechanics.getDefault();

		const hasChanged = !lib.helpers.objects.deepEqual(
			gameMechanics,
			defaultGameMechanics,
		);
		if (!hasChanged) return;

		this.#actions.updateChangeset<IGameMechanicsChangeset>(
			gameMechanics.pk,
			"update",
			gameMechanics,
		);
	}

	#createChangesetForPermissions() {
		const permissions = this.#state.permissions.export();
		const defaultPermissions = this.#state.permissions.getDefault();

		const hasChanged = !lib.helpers.objects.deepEqual(
			permissions,
			defaultPermissions,
		);
		if (!hasChanged) return;

		this.#actions.updateChangeset<IPermissionsChangeset>(
			permissions.pk,
			"update",
			permissions,
		);
	}

	#createChangesetForEnvironmentPreset() {
		const envPreset = this.#state.environmentPreset.export();
		if (!envPreset) return;

		if (envPreset.isDefault) {
			const hasChanged = !lib.helpers.objects.deepEqual(
				envPreset.preset,
				envPreset.originalPreset,
			);

			if (!hasChanged) return;
		}

		this.#actions.updateChangeset<IEnvironmentPresetChangeset>(
			envPreset.pk,
			"create",
			{
				identifier: envPreset.identifier,
				name: envPreset.name,
				general: envPreset.preset.general,
				fog: envPreset.preset.fog,
				light: envPreset.preset.light,
				sky: envPreset.preset.sky,
			},
		);
	}

	#createChangesetForBlocks() {
		for (const block of this.#state.blocks.export()) {
			this.#actions.updateChangeset<IBlockChangeset>(
				block.pk,
				block.name.startsWith("editor.block.") ? "create" : "update",
				block,
			);
		}
	}

	async #createChangesetForBlockTextures() {
		for (const texture of this.#state.blockTextures.export()) {
			const file = await this.#content.getFile(texture.url);
			this.#actions.updateChangeset<IBlockTextureChangeset>(texture.pk, "create", {
				file,
			});
		}
	}

	async #createChangesetForThumbnail() {
		const thumbnail = this.#state.thumbnail.export();
		if (!thumbnail) return;

		const file = await this.#content.getFile(thumbnail.url);
		this.#actions.updateChangeset<IThumbnailChangeset>(thumbnail.pk, "update", {
			url: thumbnail.url,
			file,
		});
	}

	async #createChangesetForCustomLoader() {
		const settings = this.#state.settings.get();
		if (!settings.showCustomLoader) return;

		this.#actions.updateChangeset<ISettingsChangeset>(settings.pk, "update", {
			showCustomLoader: settings.showCustomLoader,
		});

		const customLoader = this.#state.customLoader.export();
		if (!customLoader) return;

		const banner = customLoader.bannerUrl.default
			? await this.#content.getFile(customLoader.bannerUrl.default)
			: undefined;

		this.#actions.updateChangeset<ICustomLoaderChangeset>(
			customLoader.pk,
			"update",
			{
				title: customLoader.title,
				description: customLoader.description,
				bannerUrl: customLoader.bannerUrl.default,
				banner: banner,
			},
		);
	}

	async #createChangesetForBackgroundMusic() {
		const backgroundMusic = this.#state.backgroundMusic.export();
		if (!backgroundMusic) return;

		const file = await this.#content.getFile(backgroundMusic.url);
		this.#actions.updateChangeset<IBackgroundMusicChangeset>(
			backgroundMusic.pk,
			"update",
			{
				url: backgroundMusic.url,
				file,
				volume: backgroundMusic.volume,
			},
		);
	}

	async #createChangesetForAudio() {
		for (const audio of this.#state.audios.export()) {
			const file = await this.#content.getFile(audio.url);
			this.#actions.updateChangeset<IAudioChangeset>(audio.pk, "create", {
				file,
			});
		}
	}

	async #createChangesetForSkybox() {
		const skybox = this.#state.skybox.export();
		if (!skybox) return;

		const [nxFile, nyFile, nzFile, pxFile, pyFile, pzFile] = await Promise.all([
			this.#content.getFile(skybox.nxUrl),
			this.#content.getFile(skybox.nyUrl),
			this.#content.getFile(skybox.nzUrl),
			this.#content.getFile(skybox.pxUrl),
			this.#content.getFile(skybox.pyUrl),
			this.#content.getFile(skybox.pzUrl),
		]);

		this.#actions.updateChangeset<ISkyboxChangeset>(skybox.pk, "update", {
			nxUrl: skybox.nxUrl,
			nxFile,
			nyUrl: skybox.nyUrl,
			nyFile,
			nzUrl: skybox.nzUrl,
			nzFile,
			pxUrl: skybox.pxUrl,
			pxFile,
			pyUrl: skybox.pyUrl,
			pyFile,
			pzUrl: skybox.pzUrl,
			pzFile,
		});
	}

	#shouldCreateWorld() {
		if (
			!(this.#content.options.isDefaultWorld || this.#content.options.isImported)
		) {
			return false;
		}
		if (!(this.#state.user.isCreator && this.#state.isServer)) return false;

		const world = this.#state.world.get();
		if (!world.isNew) return false;

		return true;
	}

	#shouldCloneWorld() {
		if (!this.#content.options.isImported) return false;
		if (!(this.#state.user.isCreator && this.#state.isServer)) return false;

		const world = this.#state.world.get();
		if (!world.isNew) return false;

		return true;
	}
}
