import { IThumbnailChangeset, IJacyContent } from "jacy";

import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyThumbnailActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	uploadThumbnail(initialFile: File) {
		try {
			const [thumbnail, file] = this.#state.thumbnail.uploadThumbnail(initialFile);
			useJacyRerenderStore.getState().forceRerenderThumbnail();

			this.#actions.setSuccess("Thumbnail has been uploaded successfully.");
			this.#actions.updateChangeset<IThumbnailChangeset>(thumbnail.pk, "update", {
				url: thumbnail.url,
				file,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	clearThumbnail() {
		try {
			const id = this.#state.thumbnail.clearThumbnail();
			useJacyRerenderStore.getState().forceRerenderThumbnail();

			this.#actions.setSuccess("Thumbnail has been cleared successfully.");
			this.#actions.updateChangeset<IThumbnailChangeset>(id, "delete", {
				url: undefined,
				file: undefined,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
