import React from "react";
import { cn } from "@lib/helpers/cn";
import { useGameHudPopupStore } from "@stores/hud/game-hud-popup";

export function HUDPopup() {
	const message = useGameHudPopupStore((state) => state.message);
	const imageUrl = useGameHudPopupStore((state) => state.imageUrl);
	const position = useGameHudPopupStore((state) => state.position ?? "top");
	const title = useGameHudPopupStore((state) => state.title);
	let positionalClass = "";

	if (!title && !message && !imageUrl) return null;

	let isVertical = ["top", "bottom"].includes(position);
	let isHorizontal = ["left", "right"].includes(position);
	if (isVertical) {
		positionalClass =
			"left-1/2 -translate-x-1/2 max-w-[874px] w-[85%] mobile:w-[60%] mobile:xs:w-[70%] mobile:sm:w-[80%]";
	} else if (isHorizontal) {
		positionalClass =
			"top-1/2 -translate-y-1/2 w-1/3 max-w-[450px] min-h-[50vh] sm:xs-h:min-h-[60vh] lg:md-h:min-h-[70vh]";
	} else {
		positionalClass = "inset-40 grid place-items-center content-center gap-8";
	}

	return (
		<div
			id="imagePopupContainer"
			className={cn(
				"absolute h-auto rounded bg-gray-900/50 p-2 text-sm font-semibold text-white md:p-8 md:text-xl",
				positionalClass,
				position === "top" && "top-[62px] xl:top-4 sm:xs-h:top-[72px]",
				position === "bottom" &&
					"bottom-20 xs:bottom-24 sm:bottom-28 md:bottom-32 lg:bottom-36",
				position === "left" && "left-4 mobile:left-[70px]",
				position === "right" && "right-4 mobile:max-sm:right-[70px]",
				!isVertical && !message && "grid place-items-center",
			)}
		>
			{imageUrl ? (
				<img
					id="imagePopup"
					className={cn(
						"mb-2 block h-full w-full object-contain",
						isVertical && "aspect-[16/4] h-28",
						isHorizontal && "mx-auto aspect-[9/16] max-h-[500px] max-w-[300px]",
						position === "center" && "object-contain",
					)}
					src={imageUrl}
					alt=""
				/>
			) : null}
			{title && (
				<h2 className="text-bold line-clamp-1 text-center text-2xl">{title}</h2>
			)}
			{message && (
				<p
					className={cn("font-normal", isVertical ? "line-clamp-2" : "line-clamp-4")}
				>
					{message}
				</p>
			)}
		</div>
	);
}
