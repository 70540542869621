import { create } from "zustand";

type ManipulatorStore = {
	toolState: string;
	setToolState: (value: string) => void;

	selectorSubGrid: undefined | { editable: boolean };
	setSelectorSubGrid: (value: undefined | { editable: boolean }) => void;

	snapMode: "world" | "subgrid" | "free";
	setSnapMode: (value: "world" | "subgrid" | "free") => void;

	transforming: boolean;
	setTransforming: (value: boolean) => void;

	transformSpace: "local" | "world";
	setTransformSpace: (value: "local" | "world") => void;

	transformMode: "translate" | "rotate" | "scale";
	setTransformMode: (value: "translate" | "rotate" | "scale") => void;
};

export const useManipulatorStore = create<ManipulatorStore>((set) => ({
	toolState: "START",
	setToolState: (value) => set({ toolState: value }),

	selectorSubGrid: undefined,
	setSelectorSubGrid: (value) => set({ selectorSubGrid: value }),

	snapMode: "world",
	setSnapMode: (value) => set({ snapMode: value }),

	transforming: false,
	setTransforming: (value) => set({ transforming: value }),

	transformSpace: "world",
	setTransformSpace: (value) => set({ transformSpace: value }),

	transformMode: "translate",
	setTransformMode: (value) => set({ transformMode: value }),
}));

export default {
	useManipulator: useManipulatorStore.getState,
};
