import { create } from "zustand";
import { GameStatus, GameType } from "jacy";
import { useControlsStore } from "./controls";
import { useGameFPSStore } from "./game/game-fps";

// Store for game mechanics
export const useGameStore = create((set, get) => ({
	gameEndUIOpen: false,
	settings: {},
	objectives: new Map(),
	mechanics: GameType.SANDBOX_MODE,
	status: GameStatus.LOBBY,
	hasEnded: false,
	willPlayAgain: null,
	round: 1,
	rounds: 1,
	nextRoundPlayers: {},

	// Handlers
	setGameEndUIOpen: (showUI) => {
		set({ gameEndUIOpen: showUI });
		useControlsStore.getState().exitPointerLock(showUI);
	},
	setObjectives: (objectives) => set({ objectives }),
	setMechanics: (mechanics) => set({ mechanics }),
	setStatus: (status) => set({ status }),
	setWillPlayAgain: (willPlayAgain) => set({ willPlayAgain }),
	setNextRoundPlayers: (nextRoundPlayers) => set({ nextRoundPlayers }),
	setCurrentState: ({ round, nextRoundPlayers }) => set({ round, nextRoundPlayers }),
	setHasEnded: (hasEnded) => set({ hasEnded }),

	// Actions
	init: (settings, { rounds }) => {
		set({
			settings,
			rounds,
			round: 1,
			status: GameStatus.LOBBY,
		});
	},
	reset: ({ round }) => {
		set({ round, willPlayAgain: null, nextRoundPlayers: {} });
		useGameFPSStore.getState().reset();
	},
	end: ({ showUI }) => {
		get().setStatus(GameStatus.done);
		get().setGameEndUIOpen(showUI);
	},
	onLoadWorld: () => {
		set({ objectives: new Map() });
	},
}));

export default {
	useGame: useGameStore.getState,
};
