import { IBlockTextureChangeset, IJacyContent } from "jacy";
import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyBlockTextureActions {
	#actions: JacyActions;
	#content: IJacyContent;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#content = actions.content;
		this.#state = actions.content.state;
	}

	async uploadTexture(initialFile: File) {
		try {
			const [texture, file] = await this.#state.blockTextures.create(initialFile);
			useJacyRerenderStore.getState().forceRerenderBlockTextures();

			this.#actions.updateChangeset<IBlockTextureChangeset>(texture.pk, "create", {
				file,
			});

			return texture.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
