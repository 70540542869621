import React from "react";
import { X as CloseIcon } from "@components/icons";
import { api, constants } from "rest-client";
import { useNavigate, useParams } from "react-router-dom";
import { GameMultiplayer } from "@jamango/client";

import { Dialog } from "@components/ui/Dialog";
import { Button } from "@components/ui/Button";
import { usePlayerStore } from "@stores/player";
import { useWorldPreviewStore } from "@stores/dialogs";
import { useJacyUserStore } from "@stores/jacy/user";
import { formatNumber } from "@lib/helpers/formatNumber";
import { truncateString } from "@lib/helpers/truncateString";

import { UserAvatar } from "@components/avatar/UserAvatar";
import { Carousel } from "./Carousel/Carousel";

const ImgButton = ({ isCurrent, imgSrc }) => (
	<img
		src={imgSrc}
		alt=""
		className={`block h-10 w-16 rounded-lg object-cover lg:h-12 ${
			isCurrent ? "brightness-100" : "brightness-50"
		}`}
	/>
);

export const WorldPreview = () => {
	const navigate = useNavigate();
	const { worldId } = useParams();
	const open = useWorldPreviewStore((state) => state.open);
	const togglePreview = useWorldPreviewStore((state) => state.toggle);
	const closePreview = useWorldPreviewStore((state) => state.close);
	const authUsername = useJacyUserStore((state) => state.username);
	const playerUsername = usePlayerStore((state) => state.username);
	const currentWorld = useWorldPreviewStore((state) => state.currentWorld);
	const setCurrentWorld = useWorldPreviewStore((state) => state.setCurrentWorld);
	const hasFullAccess = useJacyUserStore((state) => state.hasFullAccess);

	const handleJoinWorld = (id) => {
		const username = authUsername ?? playerUsername;

		if (username !== null || hasFullAccess) {
			GameMultiplayer.joinPublishedWorld(id);
		} else {
			navigate("/signin");
		}
		closePreview();
	};
	const handleClose = () => {
		navigate("/");
		closePreview();
	};

	const handleOpenChange = (isOpen) => {
		if (!isOpen) handleClose();
	};

	const handlePlay = () => {
		if (!authUsername && !hasFullAccess) {
			navigate("/signin");
		} else {
			handleJoinWorld(currentWorld.identifier);
		}
	};

	React.useEffect(() => {
		const getInitialPreview = async () => {
			if (worldId && !currentWorld) {
				const worlds = await api.world.getPublishedWorlds();
				const targetWorld = worlds?.find((world) => world.identifier === worldId);
				if (targetWorld) {
					togglePreview(true);
					setCurrentWorld(targetWorld);
				}
			}
		};
		getInitialPreview();
	}, [worldId]);

	if (!currentWorld) return;

	const items = [
		{
			img: currentWorld?.thumbnail?.url ?? constants.images.IMAGES.MAIN_BG,
		},
	];

	return (
		<Dialog.Root open={open} onOpenChange={handleOpenChange}>
			<Dialog.Content
				className="!max-h-full w-full max-w-xl overflow-hidden rounded-xl"
				overlayClassname="!bg-opacity-50 z-10"
			>
				<div className="relative grid content-start gap-8 overflow-auto bg-slate-100 px-8 pb-8 pt-6 lg:max-h-[90vh] lg:grid-cols-1 lg:rounded-xl">
					<Button
						square
						color="white-ghost"
						className="absolute right-6 top-6"
						onClick={handleClose}
					>
						<CloseIcon className="size-7" />
					</Button>
					<div className="grid gap-4">
						<Dialog.Title className="text-xl font-semibold lg:text-3xl">
							{truncateString(currentWorld?.name, 25)}
						</Dialog.Title>

						<Carousel items={items} CustomBtn={ImgButton}>
							{items.slice(0, 6).map((item, i) => {
								return (
									<div
										className="inline-flex h-64 w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-2xl"
										key={i * Math.random()}
									>
										<div></div>
										<div className="h-64 w-full">
											<img
												src={item?.img ?? constants.world.DEFAULT_WORLD_THUMBNAIL}
												alt=""
												className="block h-full w-full object-cover"
											/>
										</div>
									</div>
								);
							})}
						</Carousel>

						<div className="flex flex-col justify-between gap-2 rounded-xl bg-white px-6 py-3 2xs:gap-1">
							{currentWorld?.owner && (
								<div className="flex flex-wrap items-center gap-2">
									<h6 className="mr-auto text-gray-500">Creator</h6>
									<div className="flex items-center gap-2">
										<UserAvatar
											username={currentWorld.owner.username}
											displayPhoto={currentWorld.owner.avatar}
										/>
										<p className="truncate text-lg">{currentWorld.owner.username}</p>
									</div>
								</div>
							)}
							<div className="flex flex-wrap items-center justify-center gap-2">
								<h6 className="text-md mr-auto text-gray-500">Game visits</h6>
								<p className="text-bold text-lg">
									{formatNumber(currentWorld?.visits)}
								</p>
							</div>
							<div className="hidden flex-col xs:flex xs:flex-row xs:items-center">
								<h6 className="mr-auto text-gray-500">Created</h6>
								<p className="text-lg">{currentWorld?.created_at} ago</p>
							</div>
							<div className="flex-col text-center xs:flex xs:flex-row xs:items-center">
								<h6 className="mr-auto text-sm text-gray-500 xs:text-base">
									Last updated
								</h6>
								<p className="text-lg">{currentWorld?.updated_at} ago</p>
							</div>
						</div>
						<Button
							color="orange-gradient"
							className="rounded-2xl font-display"
							size="xl"
							fullWidth
							onClick={handlePlay}
						>
							Play
						</Button>
					</div>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
};
