import { create } from "zustand";

import { api } from "rest-client";

import { useSettingsStore } from "./settings";
import { useControlsStore } from "./controls";
import { Jacy } from "@jacy-client";

export const ONBOARDING_TAB = {
	welcome: "welcome",
	invite: "invite",
};

export const useOnboardingStore = create((set, get) => ({
	open: false,
	selectedTab: ONBOARDING_TAB.welcome,

	// Handlers
	setOpen: (open) => {
		set({ open });
		useControlsStore.getState().exitPointerLock(open);
	},
	setSelectedTab: (selectedTab) => set({ selectedTab }),

	// Actions
	start: () => {
		const { acceptedAlphaTOS } = useSettingsStore.getState();
		if (acceptedAlphaTOS) {
			return;
		}

		set({ selectedTab: ONBOARDING_TAB.welcome });
		get().setOpen(true);
	},
	next: () => {
		const { acceptedAlphaTOS } = useSettingsStore.getState();
		if (!acceptedAlphaTOS) return;

		if (!Jacy.state.user.isGuest) {
			api.auth.acceptTOS();
		}

		set({ selectedTab: ONBOARDING_TAB.invite });
	},
	play: () => {
		get().setOpen(false);
	},
}));

export default {
	useOnboarding: useOnboardingStore.getState,
};
