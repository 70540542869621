import { create } from "zustand";
import { Jacy } from "@jacy-client";
import { Atom, Cube, Lightning } from "@components/icons";
import {
	BlockCollision,
	BlockGravity,
	BlockTransparency,
	BlockTransparencyBehaviour,
	BlockUnsnapAnimation,
	lib,
	IBlockOptions,
	IAssetKey,
	IParsedBlock,
	BlockMaterialSide,
	IBlockTexture,
	AssetType,
	IIdentifier,
	IDateString,
} from "jacy";

import { useEventsEditorStore } from "@stores/events-editor";
import { IEvent } from "@lib/types/events";

export const TABS = [
	{ label: "Basic", icon: Cube },
	{ label: "Physics", icon: Atom },
	{ label: "Events", icon: Lightning },
];

type IJacyBlockEditorState = {
	open: boolean;
	selectedTab: string;
	isCreate?: boolean;

	pk: IAssetKey | null;
	id: IIdentifier;
	name: string;
	displayName: string;
	description: string;
	isMissing: boolean;
	isTexture: boolean;
	isDefaultFace: boolean;
	texture?: IBlockTexture | null;
	color?: string | null;
	material: IParsedBlock["material"];
	options: IBlockOptions;
	createdAt: IDateString;
	updatedAt: IDateString;

	// Handlers
	setSelectedTab: (selectedTab: string) => void;
	setDisplayName: (displayName: string) => void;
	setDescription: (description: string) => void;
	toggleMaterial: () => void;
	toggleDefaultFace: () => void;
	setTransparency: (transparency: BlockTransparency) => void;
	setTransparencyBehaviour: (
		transparencyBehaviour: BlockTransparencyBehaviour,
	) => void;
	toggleCollision: (enabled: boolean) => void;
	setSurfaceFriction: (surfaceFriction: number) => void;
	setGravity: (gravity: BlockGravity) => void;
	toggleGravitySnap: (enabled: boolean) => void;
	setMaxImpact: (maxImpact: number) => void;
	setUnsnapAnimation: (unsnapAnimation: BlockUnsnapAnimation) => void;
	setColor: (color: string) => void;
	setTextureSide: (side: BlockMaterialSide, textureId: IAssetKey) => void;

	// Actions
	close: () => void;
	getBlock: () => IParsedBlock;
	newBlock: (name?: string) => void;
	editBlock: (block: IParsedBlock) => void;
};

const DEFAULT_STATE = {
	pk: null,
	id: "",
	name: "",
	displayName: "",
	description: "",
	isMissing: false,
	isTexture: false,
	isDefaultFace: true,
	color: lib.constants.blocks.DEFAULT_COLOR,
	material: {
		default: null,
		nx: null,
		ny: null,
		nz: null,
		px: null,
		py: null,
		pz: null,
	},
	options: lib.helpers.objects.cloneObject(
		lib.constants.blocks.DEFAULT_BLOCK_OPTIONS,
	),
	createdAt: new Date().toISOString(),
	updatedAt: new Date().toISOString(),
};

export const useJacyBlockEditorStore = create<IJacyBlockEditorState>((set, get) => ({
	open: false,
	selectedTab: TABS[0].label,
	...DEFAULT_STATE,

	// Handlers
	setSelectedTab: (selectedTab) => {
		set({ selectedTab });

		if (selectedTab === "Events") {
			useEventsEditorStore.getState().setOpen(true);
		} else {
			useEventsEditorStore.getState().setOpen(false);
		}
	},
	setDisplayName: (displayName) => set({ displayName }),
	setDescription: (description) => set({ description }),
	toggleMaterial: () => {
		const isTexture = !get().isTexture;

		const material = { ...get().material };
		material.default = isTexture ? get().texture : get().color;

		set({ isTexture, material });
	},
	toggleDefaultFace: () => set({ isDefaultFace: !get().isDefaultFace }),
	setTransparency: (transparency) => {
		const options = lib.helpers.objects.cloneObject(get().options);

		if (transparency === BlockTransparency.OPAQUE) {
			options.transparency = transparency;
			options.transparencyBehaviour = undefined;
		} else {
			options.transparency = transparency;
			options.transparencyBehaviour =
				options.transparencyBehaviour ?? BlockTransparencyBehaviour.JOIN;
		}

		set({ options });
	},
	setTransparencyBehaviour: (transparencyBehaviour) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.transparencyBehaviour = transparencyBehaviour;
		set({ options });
	},
	toggleCollision: (enabled) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.collision = enabled ? BlockCollision.ENABLED : BlockCollision.DISABLED;

		if (!enabled) {
			options.gravity = BlockGravity.DISABLED;
			options.gravitySnap = undefined;
			options.unsnapAnimation = BlockUnsnapAnimation.DISABLED;
			options.maxImpact = undefined;
		}

		set({ options });
	},
	setSurfaceFriction: (surfaceFriction) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.surfaceFriction = surfaceFriction;
		set({ options });
	},
	setGravity: (gravity) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.gravity = gravity;
		set({ options });
	},
	toggleGravitySnap: (enabled) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.gravitySnap = enabled;
		set({ options });
	},
	setMaxImpact: (maxImpact) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.maxImpact = maxImpact;
		set({ options });
	},
	setUnsnapAnimation: (unsnapAnimation) => {
		const options = lib.helpers.objects.cloneObject(get().options);
		options.unsnapAnimation = unsnapAnimation;
		set({ options });
	},
	setColor: (color) => {
		set({
			color,
			material: {
				...get().material,
				default: color,
			},
		});
	},
	setTextureSide: (side, textureId) => {
		const blockTexture = Jacy.state.blockTextures.get(textureId);
		if (!blockTexture) {
			console.error(`Texture not found: ${textureId}`);
			return;
		}

		set({
			texture: side === BlockMaterialSide.DEFAULT ? blockTexture : get().texture,
			material: {
				...get().material,
				[side]: blockTexture,
			},
		});
	},

	// Actions
	close: () => set({ open: false }),
	getBlock: () => {
		const client = useEventsEditorStore.getState().client;

		if (!client) {
			throw new Error("Events editor client not found");
		}

		const pk = get().pk;

		if (!get().isCreate && !pk) {
			throw new Error("Block identifier not found");
		}

		const events = client.getEvents();

		const colorMaterial = get().isTexture ? undefined : get().color;

		return {
			pk,
			type: AssetType.BLOCK,
			id: get().id,
			name: get().name,
			displayName: get().displayName,
			description: get().description,
			isMissing: get().isMissing,
			material: {
				default: colorMaterial ?? get().material.default,
				px: colorMaterial ?? get().material.px,
				nx: colorMaterial ?? get().material.nx,
				py: colorMaterial ?? get().material.py,
				ny: colorMaterial ?? get().material.ny,
				pz: colorMaterial ?? get().material.pz,
				nz: colorMaterial ?? get().material.nz,
			},
			options: get().options,
			events,
			createdAt: get().createdAt,
			updatedAt: get().updatedAt,
		} as IParsedBlock;
	},
	newBlock: (name) => {
		useEventsEditorStore.getState().setEvents(null);
		useEventsEditorStore.getState().setModified(false);

		const texture = Jacy.state.blockTextures.query("dirt");
		const today = new Date().toISOString();

		set({
			...lib.helpers.objects.cloneObject(DEFAULT_STATE),
			open: true,
			isCreate: true,
			selectedTab: TABS[0].label,
			name: name ?? "",
			isMissing: !!name,
			texture,
			createdAt: today,
			updatedAt: today,
		});
	},
	editBlock: (block) => {
		if (block.isMissing) return get().newBlock(block.name);

		let isTexture = false;
		let color = lib.constants.blocks.DEFAULT_COLOR;
		let texture;

		if (
			typeof block.material.default === "object" &&
			!lib.helpers.general.isNullish(block.material.default)
		) {
			isTexture = true;
			texture = block.material.default;
		} else if (
			lib.helpers.general.isNullish(block.material.default) &&
			!(
				lib.helpers.general.isNullish(block.material.px) ||
				lib.helpers.general.isNullish(block.material.nx) ||
				lib.helpers.general.isNullish(block.material.py) ||
				lib.helpers.general.isNullish(block.material.ny) ||
				lib.helpers.general.isNullish(block.material.pz) ||
				lib.helpers.general.isNullish(block.material.nz)
			)
		) {
			isTexture = true;
		} else {
			isTexture = false;

			if (
				!lib.helpers.general.isNullish(block.material.default) &&
				lib.helpers.strings.isColor(block.material.default)
			) {
				color = block.material.default;
			}
		}

		set({
			open: true,
			isCreate: false,
			selectedTab: TABS[0].label,
			pk: block.pk,
			id: block.id,
			name: block.name,
			displayName: block.displayName,
			description: block.description ?? "",
			isTexture,
			color,
			texture,
			isDefaultFace: true,
			isMissing: block.isMissing,
			material: block.material,
			options: {
				...lib.constants.blocks.DEFAULT_BLOCK_OPTIONS,
				...(block.options || {}),
			},
			createdAt: block.createdAt,
			updatedAt: block.updatedAt,
		});

		useEventsEditorStore.getState().setEvents(block.events as IEvent);
	},
}));
