import { create } from "zustand";
import { lib } from "jacy";
import { GameClient, GameMultiplayer } from "@jamango/client";
import { closeAllModal } from "@lib/helpers/closeAllModal";
import { useControlsStore } from "./controls";
import { useBlockWrenchStore } from "./wrench";
import { useCustomUIStore } from "./custom-ui";
import type { IPromise } from "node_modules/jacy/src/lib/helpers/requests";
import { VALIDATOR_TYPE } from "@lib/helpers/validators";
import {
	BlankWorldTemplate,
	WorldTemplateOptions,
} from "@components/world/templates";

type BaseModalState = {
	open: boolean;
	close: () => void;
	toggle: () => void;
	setOpen: (open: boolean) => void;
};

export const useHubStore = create<
	Omit<BaseModalState, "toggle"> & {
		toggle: (open: boolean, togglePointerLock?: boolean) => void;
	}
>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	setOpen: (open) => set({ open }),
	toggle: (open, togglePointerLock = true) => {
		const isWrenchOpen = useBlockWrenchStore.getState().open;
		if (isWrenchOpen) return;

		set((state) => {
			const newState = open ?? !state.open;

			if (newState) {
				closeAllModal();
				GameMultiplayer.refetchOnlineServers();
			}

			if (togglePointerLock) {
				useControlsStore.getState().exitPointerLock(newState);
			}

			return { open: newState };
		});
	},
}));

export const useWorldPreviewStore = create<
	Pick<BaseModalState, "open" | "close"> & {
		currentWorld: any;
		setCurrentWorld: (world: any) => void;
		toggle: (open: boolean) => void;
	}
>()((set) => ({
	currentWorld: null,
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: (open) => {
		set((state) => {
			const newState = open ?? !state.open;

			return { open: newState };
		});
	},
	setCurrentWorld: (world) => set({ currentWorld: world }),
}));

export const useConfirmPromptStore = create<{
	promise: null | IPromise<any>;
	open: boolean;
	title: string;
	description: string;
	confirmText: string;
	cancelText: string;
	promptText: null | string;
	exitPointerLock: boolean;
	setOpen: (open: boolean) => void;
	setExitPointerLock: (exitPointerLock: boolean) => void;
	confirm: () => void;
	cancel: () => void;
	closeDialog: () => void;
	prompt: (opts: {
		title?: string;
		description?: string;
		confirmText?: string;
		cancelText?: string;
		prompt?: null | string;
	}) => Promise<unknown>;
	confirmAsync: (description: string) => Promise<unknown>;
}>()((set, get) => ({
	promise: null,

	open: false,
	title: "Are you sure?",
	description: "This action cannot be undone.",
	confirmText: "Yes, I am sure",
	cancelText: "Cancel",
	promptText: null,
	exitPointerLock: true,

	setOpen: (open) => {
		set({ open });
		if (!open) get().promise?.resolve(false);

		useControlsStore.getState().exitPointerLock(open);
	},
	setExitPointerLock: (exitPointerLock) => set({ exitPointerLock }),
	confirm: () => {
		get().promise?.resolve(true);
		set({ open: false });
	},
	cancel: () => {
		get().promise?.resolve(false);
		set({ open: false });
	},
	closeDialog: () => {
		set({ open: false });
	},
	prompt: async ({
		title = "Are you sure?",
		description = "This action cannot be undone.",
		confirmText = "Yes, I am sure",
		cancelText = "Cancel",
		prompt = null,
	}) => {
		const promise = lib.helpers.requests.createRequestPromise();

		set({
			open: true,
			title,
			description,
			promptText: prompt,
			confirmText,
			cancelText,
			promise,
		});

		useControlsStore.getState().exitPointerLock(true);
		return await promise;
	},
	confirmAsync: async (description) => {
		return await get().prompt({
			title: document.title,
			description,
			confirmText: "Yes",
			cancelText: "No",
		});
	},
}));

const DEFAULT_GENERAL_PROMPT_STATE = {
	confirmText: "Submit",
	cancelText: "Cancel",
};

export const useGeneralPromptStore = create<{
	promise?: null | IPromise<string | false>;
	confirmText: string;
	cancelText: string;
	open: boolean;
	promptText: null | string;
	validator?: null | keyof typeof VALIDATOR_TYPE;
	setOpen: (open: boolean) => void;
	confirm: (answer: string) => void;
	cancel: () => void;
	prompt: (
		promptText: string,
		opts: typeof DEFAULT_GENERAL_PROMPT_STATE & {
			validator?: keyof typeof VALIDATOR_TYPE;
		},
	) => Promise<string | false>;
}>()((set, get) => ({
	...DEFAULT_GENERAL_PROMPT_STATE,
	promise: null,
	open: false,
	validator: null,
	promptText: null,

	setOpen: (open) => {
		set({ open });
		if (!open) get().promise?.resolve(false);
	},
	confirm: (answer) => {
		get().promise?.resolve(answer);
		set({ open: false });
	},
	cancel: () => {
		get().promise?.resolve(false);
		set({ open: false });
	},
	prompt: async (
		promptText,
		{
			confirmText = "Submit",
			cancelText = "Cancel",
			validator,
		} = DEFAULT_GENERAL_PROMPT_STATE,
	) => {
		const promise = lib.helpers.requests.createRequestPromise<string | false>();

		set({ open: true, promptText, confirmText, cancelText, validator, promise });

		return await promise;
	},
}));

const DEFAULT_ERROR_STATE: {
	open: boolean;
	error: null | Error;
	onClose: null | (() => void);
} = {
	open: false,
	error: null,
	onClose: null,
};

export const usePromptStore = create<
	typeof DEFAULT_ERROR_STATE & {
		reason?: "create-world" | null;
		close: () => void;
		toggle: (open: boolean, onClose?: null | (() => void)) => void;
		setError: (error: any) => void;
		openCreateWorld: (onClose?: null | (() => void)) => void;
	}
>()((set, get) => ({
	...DEFAULT_ERROR_STATE,
	reason: null,
	close: () => {
		const onClose = get().onClose;
		if (typeof onClose === "function") onClose();
		set({ open: false, onClose: null, error: null, reason: null });
	},
	toggle: (open, onClose = null) => {
		set({ open, onClose, error: null });
	},
	setError: (error) => {
		set({ error });
	},
	openCreateWorld: (onClose) => {
		set({
			open: true,
			onClose,
			error: null,
			reason: "create-world",
		});
	},
}));

export const useErrorStore = create<
	typeof DEFAULT_ERROR_STATE & {
		close: () => void;
		toggle: (open: boolean, onClose?: null | (() => void)) => void;
		setError: (error: Error | string) => void;
	}
>()((set, get) => ({
	...DEFAULT_ERROR_STATE,
	close: () => {
		set({ ...DEFAULT_ERROR_STATE });
	},
	toggle: (open, onClose = null) => {
		set({ open, onClose, error: null });
	},
	setError: (error) => {
		if (!(error instanceof Error)) {
			error = Error(error);
		}

		localStorage.setItem("worldId", "");
		localStorage.setItem("serverId", "");
		window.history.pushState({}, document.title, "/play");

		// eslint-disable-next-line no-console
		console.error(error);
		if (!get().open) {
			set({ error, open: true });
		}
	},
}));

export const useHubWorldModal = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open: boolean) => {
		set({ open });

		if (open) {
			GameMultiplayer.refetchOnlineServers();
		}
	},
}));

export const useCreateWorldModal = create<
	BaseModalState & {
		worldTemplate: WorldTemplateOptions;
		blockPacks: Set<string>;

		setWorldTemplate: (worldTemplate: WorldTemplateOptions) => void;
		toggleBlockPack: (name: string) => void;
		startNewWorld: (force: boolean) => Promise<any>;
		resetBlockPack: () => void;
	}
>()((set, get) => ({
	open: false,
	worldTemplate: BlankWorldTemplate.options,
	blockPacks: new Set<string>(),
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open) => set({ open }),
	setWorldTemplate: (worldTemplate) => set({ worldTemplate }),
	toggleBlockPack: (name) =>
		set((state) => {
			if (state.blockPacks.has(name)) {
				state.blockPacks.delete(name);
			} else {
				state.blockPacks.add(name);
			}
			return { blockPacks: new Set(state.blockPacks) };
		}),
	startNewWorld: (force) => {
		const state = get();
		const uniqueBlockPacks = new Set([
			...state.blockPacks,
			...(state.worldTemplate.terrainGeneration.blockPacks ?? []),
		]);

		return GameClient.startNewWorld({
			worldTemplateOptions: {
				...state.worldTemplate,
				terrainGeneration: {
					...state.worldTemplate.terrainGeneration,
					blockPacks: [...uniqueBlockPacks],
				},
			},
			force,
		});
	},
	resetBlockPack: () => set({ blockPacks: new Set<string>() }),
}));

export const useImportBlockPackModal = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open) => set({ open }),
}));

export const useEditorTutorialModal = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open: boolean) => set({ open }),
}));

export const useCustomUIModalStore = create<BaseModalState>()((set) => ({
	open: false,
	close: () => {
		useCustomUIStore.getState().setCurrentComponent(null);
		set({ open: false });
	},
	toggle: () => {
		set((state) => ({ open: !state.open }));
	},
	setOpen: (open) => set({ open }),
}));

export default {
	useHub: useHubStore.getState,
	useConfirmPrompt: useConfirmPromptStore.getState,
	usePrompt: usePromptStore.getState,
	useGeneralPrompt: useGeneralPromptStore.getState,
	useError: useErrorStore.getState,
	useHubWorldModal: useHubWorldModal.getState,
	useEditorTutorialModal: useEditorTutorialModal.getState,
};
