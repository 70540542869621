import { create } from "zustand";
import {
	BodyType,
	AvatarMainTabs,
	AvatarBaseTabs,
	AvatarHeadTabs,
	AvatarBodyTabs,
	lib,
	IAvatarComponent,
	IAvatarConfig,
	IAvatarMonitoringConfig,
	BodyTypeValue,
	IAvatar,
	IAssetKey,
	AvatarType,
} from "jacy";
import { Jacy } from "@jacy-client";
import { constants, helpers } from "rest-client";
import { PersonSimple, Smiley, TShirt } from "@components/icons";

import { useEngineStore } from "@stores/bb";
import { useControlsStore } from "@stores/controls";
import { useConfirmPromptStore, useHubStore } from "@stores/dialogs";
import { useInventoryStore } from "@stores/dialogs/inventory";

const DEFAULT_BODY_PARTS = constants.avatar.DEFAULT_BODY_PARTS;

export const MAIN_TABS = [
	{
		label: "Base",
		value: AvatarMainTabs.BASE,
		icon: PersonSimple,
	},
	{
		label: "Head",
		value: AvatarMainTabs.HEAD,
		icon: Smiley,
	},
	{
		label: "Body",
		value: AvatarMainTabs.BODY,
		icon: TShirt,
	},
];

export const SUB_TABS = {
	[AvatarMainTabs.BASE]: AvatarBaseTabs,
	[AvatarMainTabs.HEAD]: AvatarHeadTabs,
	[AvatarMainTabs.BODY]: AvatarBodyTabs,
};

export type AvatarSubTab = AvatarBaseTabs | AvatarHeadTabs | AvatarBodyTabs;

type IJacyAvatarEditorState = {
	open: boolean;
	isInitialized: boolean;
	isCreate: boolean;
	loading: boolean;
	isCharacterEditor: boolean;
	selectedMainTab: AvatarMainTabs;
	selectedSubTab: AvatarSubTab;
	cameraMode: string;

	pk?: IAssetKey | null;
	name?: string;
	avatarType: AvatarType;
	bodyType: BodyType;
	skinColor: (typeof lib.constants.avatarComponents.SKIN_COLORS)[0];
	hairColor?: (typeof lib.constants.avatarComponents.HAIR_COLORS)[0] | null;
	face: IAvatarComponent | null;
	hair: IAvatarComponent | null | "EMPTY";
	hat: IAvatarComponent | null | "EMPTY";
	mask: IAvatarComponent | null | "EMPTY";
	backpack: IAvatarComponent | null | "EMPTY";
	torso: IAvatarComponent | null;
	arms: IAvatarComponent | null;
	legs: IAvatarComponent | null;

	// Handlers
	setOpen: (open: boolean, togglePointerLock?: boolean) => void;
	close: (runSideEffects?: boolean) => void;
	setLoading: (loading: boolean) => void;
	setSelectedMainTab: (selectedMainTab: AvatarMainTabs) => void;
	setSelectedSubTab: (
		selectedSubTab: AvatarBaseTabs | AvatarHeadTabs | AvatarBodyTabs,
	) => void;
	setCameraMode: (cameraMode: string) => Promise<null | File>;
	setBodyType: (bodyType: BodyType) => Promise<void>;
	setSkinColor: (
		skinColor: (typeof lib.constants.avatarComponents.SKIN_COLORS)[0],
	) => Promise<void>;
	setHairColor: (
		hairColor: (typeof lib.constants.avatarComponents.HAIR_COLORS)[0],
	) => Promise<void>;
	getAvatarEditor: () => Promise<any>;
	applyItem: (component: IAvatarComponent) => Promise<void>;
	removeItem: (isDefault?: boolean) => void;
	reloadAvatar: () => Promise<void>;
	getMissingBodyPart: () => string[];
	getBodyTypeValue: () => BodyTypeValue;
	setDefaultConfig: (bodyType?: BodyType) => Promise<void>;

	// Actions
	getAvatar: () => Promise<{
		pk?: IAssetKey | null;
		thumbnail: File | null;
		displayPhoto: File | null;
		config: IAvatarMonitoringConfig;
	}>;
	getConfig: () => IAvatarConfig;
	getMonitoringConfig: () => IAvatarMonitoringConfig;
	newAvatar: (isCharacterEditor?: boolean) => void;
	editAvatar: (avatar: IAvatar, isCharacterEditor?: boolean) => void;
};

const DEFAULT_CONFIG = {
	bodyType: BodyType.BODY_TYPE_1,
	skinColor: lib.constants.avatarComponents.DEFAULT_SKIN_COLOR,
	hairColor: lib.constants.avatarComponents.DEFAULT_HAIR_COLOR,
	face: null,
	hair: null,
	hat: null,
	mask: null,
	torso: null,
	arms: null,
	legs: null,
	backpack: null,
};

const DEFAULT_STATE = {
	selectedMainTab: AvatarMainTabs.BASE,
	selectedSubTab: AvatarBaseTabs.BODY_TYPE,
	cameraMode: constants.avatar.CAMERA_MODES.FREE,

	pk: null,
	name: "",
	avatarType: AvatarType.USER_AVATAR,
};

export const useJacyAvatarEditorStore = create<IJacyAvatarEditorState>(
	(set, get) => ({
		isInitialized: false,
		loading: true,
		open: false,
		isCreate: false,
		isCharacterEditor: false,
		...DEFAULT_STATE,
		...DEFAULT_CONFIG,

		// Handlers
		setLoading: (loading) => set({ loading }),
		close: async (runSideEffects = true) => {
			if (!runSideEffects) {
				set({ open: false });
				return;
			}

			get().setOpen(false);

			const { BB, client } = await useEngineStore.getState().promise;
			BB[client].avatarEditor?.setPaused(true);
		},
		setOpen: (open) => {
			if (open) {
				if (get().isCharacterEditor) {
					useInventoryStore.getState().toggle(false);
				} else {
					useHubStore.getState().close();
				}

				useControlsStore.getState().exitPointerLock(true);

				// On open, initialize the avatar editor canvas
				useEngineStore.getState().promise.then(async ({ BB, client }) => {
					BB[client].loadAvatarCustomizer();

					await get().reloadAvatar();
					await get().setCameraMode(constants.avatar.CAMERA_MODES.FULL);

					set({ isInitialized: true });
				});
			} else {
				if (get().isCharacterEditor) {
					useInventoryStore.getState().toggle(true);
				} else {
					useHubStore.getState().setOpen(true);
				}
			}

			set({ open });
		},
		setSelectedMainTab: (selectedMainTab) => {
			let selectedSubTab: AvatarSubTab = AvatarBaseTabs.BODY_TYPE;

			if (selectedMainTab === AvatarMainTabs.HEAD) {
				selectedSubTab = AvatarHeadTabs.HAIR;
			} else if (selectedMainTab === AvatarMainTabs.BODY) {
				selectedSubTab = AvatarBodyTabs.TORSO;
			}

			set({ selectedMainTab, selectedSubTab });
			if (selectedMainTab === AvatarMainTabs.BASE) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.FULL);
			} else if (selectedMainTab === AvatarMainTabs.HEAD) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.HEAD);
			} else if (selectedMainTab === AvatarMainTabs.BODY) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.TOP);
			}
		},
		setSelectedSubTab: (selectedSubTab) => {
			set({ selectedSubTab });

			if (selectedSubTab in AvatarHeadTabs) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.HEAD);
			} else if (
				[AvatarBodyTabs.ARMS, AvatarBodyTabs.TORSO].includes(
					selectedSubTab as AvatarBodyTabs,
				)
			) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.TOP);
			} else if (selectedSubTab === AvatarBodyTabs.LEGS) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.BOTTOM);
			} else if (
				[AvatarBodyTabs.BACKPACK].includes(selectedSubTab as AvatarBodyTabs)
			) {
				get().setCameraMode(constants.avatar.CAMERA_MODES.BACK);
			}
		},
		setCameraMode: async (cameraMode) => {
			const avatarEditor = await get().getAvatarEditor();
			if (!avatarEditor) return null;

			try {
				const screenshot = await avatarEditor.transitionCamera(cameraMode);
				set({ cameraMode });
				return screenshot;
			} catch (err) {
				console.error("Something went wrong while moving the camera:", err);
				return null;
			}
		},
		setBodyType: async (bodyType) => {
			const previousBodyType = get().bodyType;

			set({ bodyType });
			const missingBodyParts = get().getMissingBodyPart();

			if (missingBodyParts.length) {
				const confirmPrompt = useConfirmPromptStore.getState().prompt;
				const confirmed = await confirmPrompt({
					title: "Component counterpart Missing !",
					description: `Are you sure you want to swap even if there are missing avatar component counterparts?
						\nThe missing body parts are the following: \n${constants.avatar.formatMissingBodyParts(missingBodyParts)}`,
					confirmText: "Continue Body Type Change",
				});

				if (!confirmed) {
					set({ bodyType: previousBodyType });
					return;
				}

				const defaultState: Record<string, null> = {};
				missingBodyParts.forEach((missingBodyPart) => {
					defaultState[missingBodyPart] = null;
				});

				set(defaultState);
			}

			await get().reloadAvatar();
		},
		setSkinColor: async (skinColor) => {
			set({ skinColor });

			const avatarEditor = await get().getAvatarEditor();
			if (!avatarEditor) return;

			avatarEditor.currentAvatar.changeSkinColor(skinColor.color);
		},
		setHairColor: async (hairColor) => {
			set({ hairColor });

			const avatarEditor = await get().getAvatarEditor();
			if (!avatarEditor) return;

			avatarEditor.currentAvatar.changeHairColor(hairColor.color);
		},
		getAvatarEditor: async () => {
			const { BB, client } = await useEngineStore.getState().promise;
			const avatarEditor = BB[client].avatarEditor;

			if (!avatarEditor) {
				console.error("Avatar Editor not initialized");
				return;
			}

			return avatarEditor;
		},
		removeItem: async (isDefault = false) => {
			await Jacy.actions.avatar.editorLoadedPromise;

			const category = get().selectedSubTab;

			set({
				[category]: !isDefault && category === AvatarHeadTabs.HAIR ? "EMPTY" : null,
				loading: true,
			});

			const avatarEditor = await get().getAvatarEditor();
			if (!avatarEditor) return null;

			if (category === AvatarHeadTabs.HAIR) {
				set({ hairColor: lib.constants.avatarComponents.DEFAULT_HAIR_COLOR });
			} else if (category === AvatarBaseTabs.SKIN) {
				set({ skinColor: lib.constants.avatarComponents.DEFAULT_SKIN_COLOR });
			}

			if (
				!isDefault &&
				[
					AvatarHeadTabs.FACE,
					AvatarHeadTabs.HAIR,
					AvatarHeadTabs.MASK,
					AvatarHeadTabs.HAT,
					AvatarBodyTabs.BACKPACK,
				].includes(category as AvatarHeadTabs | AvatarBodyTabs)
			) {
				await avatarEditor.currentAvatar.removeAccessory(category);
			} else if (
				isDefault ||
				[AvatarBodyTabs.TORSO, AvatarBodyTabs.ARMS, AvatarBodyTabs.LEGS].includes(
					category as AvatarBodyTabs,
				)
			) {
				await avatarEditor.currentAvatar.returnBodyPartToDefault(
					category,
					get().getBodyTypeValue(),
					get().getConfig(),
				);
			} else if (category === AvatarBaseTabs.AVATAR_SETS) {
				await get().setDefaultConfig(get().bodyType);
			}

			set({ loading: false });
		},
		applyItem: async (component) => {
			await Jacy.actions.avatar.editorLoadedPromise;

			set({ [component.category]: component, loading: true });

			const avatarEditor = await get().getAvatarEditor();
			if (!avatarEditor) return;

			const { bodyType } = get();

			if (component.category === AvatarHeadTabs.FACE) {
				const facePath = component.thumbnail[bodyType];

				if (!facePath) {
					console.error(
						"Thumbnail not found for the selected body type",
						component.name,
					);
					return;
				}

				await avatarEditor.currentAvatar.applyAccessory(
					component.category,
					facePath,
					bodyType,
				);
			} else if (
				[
					AvatarHeadTabs.HAIR,
					AvatarHeadTabs.HAT,
					AvatarHeadTabs.MASK,
					AvatarBodyTabs.ARMS,
					AvatarBodyTabs.TORSO,
					AvatarBodyTabs.LEGS,
					AvatarBodyTabs.BACKPACK,
				].includes(component.category as AvatarHeadTabs | AvatarBodyTabs)
			) {
				const glbFile =
					component.glbFile[component.isUniversal ? BodyType.BODY_TYPE_1 : bodyType];

				if (!glbFile) {
					console.error(
						"Glb file not found for the selected body type",
						component.name,
					);
					return;
				}

				await avatarEditor.currentAvatar.applyAccessory(
					component.category,
					glbFile,
					bodyType,
				);

				const hairColor = get().hairColor;
				if (component.category === AvatarHeadTabs.HAIR && hairColor) {
					avatarEditor.currentAvatar.changeHairColor(hairColor.color);
				}
			} else {
				console.error("Unknown category", component.category);
			}

			set({ loading: false });
		},
		reloadAvatar: async () => {
			await Jacy.actions.avatar.editorLoadedPromise;
			const avatarEditor = await get().getAvatarEditor();
			if (!avatarEditor) return;

			await avatarEditor.loadAvatarFromConfig(get().getConfig());
		},
		getMissingBodyPart: () => {
			const config = get().getConfig();
			const bodyType = get().getBodyTypeValue();
			const missingBodyParts: string[] = [];

			for (const bodyPart in config) {
				if (["bodyType", "skinColor", "hairColor", "head"].includes(bodyPart)) {
					continue;
				}
				const bodyPartConfig = config[bodyPart as keyof IAvatarConfig];

				if (typeof bodyPartConfig === "string") {
					if (bodyPartConfig === "EMPTY") {
						continue;
					} else {
						console.error("Invalid body part config", bodyPart, bodyPartConfig);
						missingBodyParts.push(bodyPart);
						continue;
					}
				}

				const bodyPartURL = helpers.avatars.getAvatarBodyPart(
					bodyType,
					bodyPartConfig,
				);
				if (bodyPartURL) continue;

				missingBodyParts.push(bodyPart);
			}

			return missingBodyParts;
		},
		getBodyTypeValue: () => {
			let bodyType = lib.helpers.strings.camelCaseToSnakeCase(get().bodyType);
			bodyType = bodyType.slice(0, -1) + "_" + bodyType.at(-1);
			return bodyType as BodyTypeValue;
		},
		setDefaultConfig: async (bodyType) => {
			set({ ...DEFAULT_CONFIG, bodyType: bodyType ?? DEFAULT_CONFIG.bodyType });
			await get().reloadAvatar();
		},

		// Actions
		getAvatar: async () => {
			const config = get().getMonitoringConfig();
			const thumbnail = await get().setCameraMode(
				constants.avatar.CAMERA_MODES.FULL,
			);
			const displayPhoto = await get().setCameraMode(
				constants.avatar.CAMERA_MODES.AVATAR,
			);

			return {
				pk: get().pk,
				thumbnail,
				displayPhoto,
				config,
			};
		},
		getConfig: () => {
			const { arms, backpack, hair, hat, mask, face, legs, torso } = get();

			return {
				bodyType: get().getBodyTypeValue(),
				skinColor: get().skinColor.color,
				hairColor: get().hairColor?.color, // Note: hairColor can be null when hair is empty.
				arms: arms
					? {
							isUniversal: !!arms.isUniversal,
							body_type_1: arms.glbFile[BodyType.BODY_TYPE_1],
							body_type_2: arms.glbFile[BodyType.BODY_TYPE_2],
						}
					: {
							isUniversal: false,
							body_type_1: DEFAULT_BODY_PARTS.arms.body_type_1,
							body_type_2: DEFAULT_BODY_PARTS.arms.body_type_2,
						},
				shoes: "EMPTY",
				backpack:
					backpack === "EMPTY" || !backpack
						? "EMPTY"
						: {
								isUniversal: !!backpack.isUniversal,
								body_type_1: backpack.glbFile[BodyType.BODY_TYPE_1],
								body_type_2: backpack.glbFile[BodyType.BODY_TYPE_2],
							},
				hat:
					hat === "EMPTY" || !hat
						? "EMPTY"
						: {
								isUniversal: !!hat.isUniversal,
								body_type_1: hat.glbFile[BodyType.BODY_TYPE_1],
								body_type_2: hat.glbFile[BodyType.BODY_TYPE_2],
							},
				mask:
					mask === "EMPTY" || !mask
						? "EMPTY"
						: {
								isUniversal: !!mask.isUniversal,
								body_type_1: mask.glbFile[BodyType.BODY_TYPE_1],
								body_type_2: mask.glbFile[BodyType.BODY_TYPE_2],
							},
				face: face
					? {
							isUniversal: !!face.isUniversal,
							body_type_1: face.thumbnail[BodyType.BODY_TYPE_1],
							body_type_2: face.thumbnail[BodyType.BODY_TYPE_2],
						}
					: {
							isUniversal: false,
							body_type_1: DEFAULT_BODY_PARTS.face.body_type_1,
							body_type_2: DEFAULT_BODY_PARTS.face.body_type_2,
						},
				hair:
					// eslint-disable-next-line no-nested-ternary
					hair === "EMPTY"
						? "EMPTY"
						: hair
							? {
									isUniversal: hair.isUniversal,
									body_type_1: hair.glbFile[BodyType.BODY_TYPE_1],
									body_type_2: hair.glbFile[BodyType.BODY_TYPE_2],
								}
							: {
									isUniversal: false,
									body_type_1: DEFAULT_BODY_PARTS.hair.body_type_1,
									body_type_2: DEFAULT_BODY_PARTS.hair.body_type_2,
								},
				head: {
					isUniversal: false,
					body_type_1: DEFAULT_BODY_PARTS.head.body_type_1,
					body_type_2: DEFAULT_BODY_PARTS.head.body_type_2,
				},
				head_type: "EMPTY",
				legs: legs
					? {
							isUniversal: !!legs.isUniversal,
							body_type_1: legs.glbFile[BodyType.BODY_TYPE_1],
							body_type_2: legs.glbFile[BodyType.BODY_TYPE_2],
						}
					: {
							isUniversal: false,
							body_type_1: DEFAULT_BODY_PARTS.legs.body_type_1,
							body_type_2: DEFAULT_BODY_PARTS.legs.body_type_2,
						},
				torso: torso
					? {
							isUniversal: !!get().torso?.isUniversal,
							body_type_1: get().torso?.glbFile[BodyType.BODY_TYPE_1],
							body_type_2: get().torso?.glbFile[BodyType.BODY_TYPE_2],
						}
					: {
							isUniversal: false,
							body_type_1: DEFAULT_BODY_PARTS.torso.body_type_1,
							body_type_2: DEFAULT_BODY_PARTS.torso.body_type_2,
						},
			};
		},
		getMonitoringConfig: () => {
			const { arms, backpack, hair, hat, mask, face, legs, torso } = get();

			return {
				bodyType: get().getBodyTypeValue(),
				skinColor: get().skinColor.color,
				hairColor: get().hairColor?.color,
				arms: arms?.pk,
				head: "EMPTY",
				head_type: "EMPTY",
				shoes: "EMPTY",
				backpack: typeof backpack === "string" ? backpack : backpack?.pk,
				hat: typeof hat === "string" ? hat : hat?.pk,
				mask: typeof mask === "string" ? mask : mask?.pk,
				face: typeof face === "string" ? face : face?.pk,
				hair: typeof hair === "string" ? hair : hair?.pk,
				legs: legs?.pk,
				torso: torso?.pk,
			};
		},
		newAvatar: (isCharacterEditor) => {
			set({
				...DEFAULT_STATE,
				...DEFAULT_CONFIG,
				isCharacterEditor,
				avatarType: isCharacterEditor
					? AvatarType.CHARACTER
					: AvatarType.USER_AVATAR,
				isCreate: true,
			});
			get().setOpen(true);
		},
		editAvatar: (avatar, isCharacterEditor) => {
			set({
				...DEFAULT_STATE,
				...DEFAULT_CONFIG,
				isCharacterEditor,
				open: true,
				isCreate: false,
				pk: avatar.pk,
				name: avatar.name,
				avatarType: avatar.avatarType,
				bodyType: lib.helpers.strings.snakeCaseToCamelCase(
					avatar.config.bodyType,
				) as BodyType,
				skinColor: lib.constants.avatarComponents.SKIN_COLORS.find(
					(color) => color.color === avatar.config.skinColor,
				),
				hairColor: lib.constants.avatarComponents.HAIR_COLORS.find(
					(color) => color.color === avatar.config.hairColor,
				),
				face: Jacy.state.avatarComponents.get(avatar.config.face) ?? null,
				hair:
					avatar.config.hair === "EMPTY"
						? ("EMPTY" as const)
						: Jacy.state.avatarComponents.get(avatar.config.hair) ?? null,
				hat:
					avatar.config.hat === "EMPTY"
						? ("EMPTY" as const)
						: Jacy.state.avatarComponents.get(avatar.config.hat) ?? null,
				mask:
					avatar.config.mask === "EMPTY"
						? ("EMPTY" as const)
						: Jacy.state.avatarComponents.get(avatar.config.mask) ?? null,
				backpack:
					avatar.config.backpack === "EMPTY"
						? ("EMPTY" as const)
						: Jacy.state.avatarComponents.get(avatar.config.backpack) ?? null,
				torso: Jacy.state.avatarComponents.get(avatar.config.torso) ?? null,
				arms: Jacy.state.avatarComponents.get(avatar.config.arms) ?? null,
				legs: Jacy.state.avatarComponents.get(avatar.config.legs) ?? null,
			});
			get().setOpen(true);
		},
	}),
);
