import { IBackgroundMusicChangeset, lib, IJacyContent } from "jacy";

import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyBackgroundMusicActions {
	#actions: JacyActions;
	#content: IJacyContent;
	#state: IJacyContent["state"];
	#audio: HTMLAudioElement;

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#content = actions.content;
		this.#state = actions.content.state;
		this.#audio = new Audio();
	}

	init() {
		this.reset();
	}

	reset() {
		this.#audio.pause();
		this.#audio = new Audio();
	}

	uploadBackgroundMusic(initialFile: File) {
		try {
			const [music, file] = this.#state.backgroundMusic.uploadFile(initialFile);

			useJacyRerenderStore.getState().forceRerenderBackgroundMusic();
			this.playMusic();

			this.#actions.setSuccess("Background music has been successfully updated.");
			this.#actions.updateChangeset<IBackgroundMusicChangeset>(music.pk, "update", {
				url: music.url,
				file,
				volume: music.volume,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	clearBackgroundMusic() {
		try {
			const id = this.#state.backgroundMusic.delete();
			useJacyRerenderStore.getState().forceRerenderBackgroundMusic();
			this.playMusic();

			this.#actions.setSuccess("Background music has been successfully cleared.");
			this.#actions.updateChangeset<IBackgroundMusicChangeset>(id, "delete", {
				url: undefined,
				file: undefined,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	adjustVolume(volume: number) {
		try {
			const music = this.#state.backgroundMusic.adjustVolume(volume);
			this.#audio.volume = volume;

			useJacyRerenderStore.getState().forceRerenderBackgroundMusic();

			this.#actions.updateChangeset<IBackgroundMusicChangeset>(music.pk, "update", {
				volume,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	// TODO: Relocate to engine
	playMusic() {
		const music = this.#state.backgroundMusic.get();
		if (!music?.url) {
			this.#audio.pause();
			return;
		}

		this.#audio.src = this.#actions.client.getFilePath(music.url);
		this.#audio.crossOrigin = "anonymous";
		this.#audio.currentTime = 0;
		this.#audio.loop = true;
		this.#audio.autoplay = true;
		this.#audio.volume = music.volume ?? lib.constants.audio.DEFAULT_AUDIO_VOLUME;
		this.#audio.play().catch((er) => console.error(er));
	}
}
