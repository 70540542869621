import { constants } from "rest-client";
import { useCustomUIStore } from "@stores/custom-ui";
import { BasicIndicator } from "@components/custom-ui/prebuilt/BasicIndicator";
import { useGameHudStore } from "@stores/game-hud";
import { HealthBar } from "@components/custom-ui/prebuilt/Healthbar";
import { Leaderboard } from "@components/custom-ui/prebuilt/Leaderboard";
import { ProgressBar } from "@components/custom-ui/prebuilt/ProgressBar";

const getPrebuiltCustomUI = (type) => {
	switch (type) {
		case "basic-indicator":
			return BasicIndicator;
		case "health-bar":
			return HealthBar;
		case "leaderboard-local":
			return Leaderboard;
		case "progress-bar":
			return ProgressBar;
		default:
			return <div></div>;
	}
};

const displayCustomUI = (customUIList, position) => {
	if (!Array.isArray(customUIList)) return;
	const getPosition = constants.customUI.getPosition;

	return customUIList
		.filter((ui) => ui.position.value === position && ui.show)
		.map((ui) => {
			const UI = getPrebuiltCustomUI(ui.type);

			return (
				<UI
					key={ui.id}
					styles={ui.styles}
					config={ui.inputs}
					position={getPosition(ui.position.value)}
				/>
			);
		});
};

export function CustomUIHUD() {
	const customUIList = useCustomUIStore((state) => state.components);
	const zenMode = useGameHudStore((state) => state.zenMode);

	if (zenMode) return null;

	const topLeftUI = displayCustomUI(customUIList, "top-left");
	const topRightUI = displayCustomUI(customUIList, "top-right");
	const topUI = displayCustomUI(customUIList, "top");
	const bottomLeftUI = displayCustomUI(customUIList, "bottom-left");
	const bottomRightUI = displayCustomUI(customUIList, "bottom-right");
	const bottomUI = displayCustomUI(customUIList, "bottom");
	const leftUI = displayCustomUI(customUIList, "left");
	const rightUI = displayCustomUI(customUIList, "right");
	const centerUI = displayCustomUI(customUIList, "center");

	return (
		<>
			{/* TOP */}
			<div className="absolute left-1/2 w-auto -translate-x-1/2  p-4">
				<div className="flex flex-col items-center gap-2" id="custom-ui-top">
					{topUI}
				</div>
			</div>
			{/* TOP LEFT */}
			<div className="absolute left-4 top-4 w-auto max-w-md p-4">
				<div className="flex flex-col justify-start gap-2" id="custom-ui-top-left">
					{topLeftUI}
				</div>
			</div>
			{/* TOP RIGHT */}
			<div className="absolute right-4 top-20 w-auto max-w-md p-4">
				<div className="flex flex-col justify-end gap-2" id="custom-ui-top-right">
					{topRightUI}
				</div>
			</div>
			{/* LEFT */}
			<div className="absolute left-4 top-1/2 w-auto max-w-md -translate-y-1/2  p-4">
				<div className="flex flex-col justify-start gap-2" id="custom-ui-left">
					{leftUI}
				</div>
			</div>
			{/* MIDDLE */}
			<div className="absolute left-1/2 top-1/2 w-auto max-w-2xl -translate-x-1/2 -translate-y-1/2 p-4">
				<div
					className="flex flex-col items-center justify-center gap-2"
					id="custom-ui-middle"
				>
					{centerUI}
				</div>
			</div>
			{/* RIGHT */}
			<div className="absolute right-4 top-1/2 w-auto max-w-md -translate-y-1/2  p-4">
				<div className="flex flex-col justify-end gap-2" id="custom-ui-right">
					{rightUI}
				</div>
			</div>

			{/* BOTTOM LEFT */}
			<div className="absolute bottom-20 left-4 w-auto max-w-md p-4">
				<div
					className="flex flex-col justify-start gap-2"
					id="custom-ui-bottom-left"
				>
					{bottomLeftUI}
				</div>
			</div>
			{/* BOTTOM RIGHT */}
			<div className="absolute bottom-20 right-4 w-auto max-w-md  p-4">
				<div className="flex flex-col justify-end gap-2" id="custom-ui-bottom-right">
					{bottomRightUI}
				</div>
			</div>
			{/* BOTTOM  */}
			<div className="absolute bottom-36 left-1/2 w-auto max-w-md -translate-x-1/2  p-4">
				<div className="flex flex-col justify-center gap-2" id="custom-ui-bottom">
					{bottomUI}
				</div>
			</div>
		</>
	);
}
