import { create } from "zustand";
import { useControlsStore } from "@stores/controls";

type CameraConfig = {
	fov: number;
	zoom: number;
	smoothing: number;
	acceleration: number;
	maxSpeed: number;
	velocityDamping: number;
};

type CameraToolStore = {
	cameras: number;
	setCameras: (cameras: number) => void;

	currentCameraName: string;
	setCurrentCameraName: (name: string) => void;

	open: boolean;
	setOpen: (open: boolean) => void;

	saveCallback: null | ((cameraConfig: CameraConfig) => void);

	editCameraConfig: (
		name: string,
		config: CameraConfig,
		defaultConfig: CameraConfig,
		saveCallback: (cameraConfig: CameraConfig) => void,
	) => void;

	saveCameraConfig: () => void;

	revertCameraConfig: () => void;

	resetToDefaults: () => void;

	cameraConfig: null | CameraConfig;
	originalCameraConfig: null | CameraConfig;
	defaultCameraConfig: null | CameraConfig;

	updateCameraConfig: (config: Partial<CameraConfig>) => void;
};

export const useCameraToolStore = create<CameraToolStore>((set, get) => ({
	cameras: 0,
	setCameras: (cameras: number) => set({ cameras }),

	currentCameraName: "",
	setCurrentCameraName: (name: string) => set({ currentCameraName: name }),

	saveCallback: null,

	editCameraConfig: (name, config, defaultConfig, saveCallback) => {
		set({
			open: true,
			currentCameraName: name,
			cameraConfig: { ...config },
			originalCameraConfig: { ...config },
			defaultCameraConfig: { ...defaultConfig },
			saveCallback,
		});

		useControlsStore.getState().exitPointerLock();
	},

	saveCameraConfig: () => {
		const { cameraConfig, saveCallback } = get();

		if (cameraConfig && saveCallback) {
			saveCallback(cameraConfig);
		}
	},

	revertCameraConfig: () => {
		const { originalCameraConfig, saveCallback } = get();

		if (originalCameraConfig && saveCallback) {
			saveCallback(originalCameraConfig);

			set({ cameraConfig: { ...(originalCameraConfig as CameraConfig) } });
		}
	},

	resetToDefaults: () => {
		const { updateCameraConfig, defaultCameraConfig } = get();

		if (defaultCameraConfig) {
			updateCameraConfig(defaultCameraConfig);
		}
	},

	open: false,
	setOpen: (open: boolean) => set({ open }),

	cameraConfig: null,
	originalCameraConfig: null,
	defaultCameraConfig: null,

	updateCameraConfig: (partialConfig) => {
		const config = { ...(get().cameraConfig as CameraConfig), ...partialConfig };

		set({ cameraConfig: config });

		const { saveCallback } = get();

		if (saveCallback) {
			saveCallback(config);
		}
	},
}));

export default {
	useCameraTool: useCameraToolStore.getState,
};
