import React from "react";
import * as DialogPrimitive from "@radix-ui/react-alert-dialog";
import { cn } from "@lib/helpers/cn";

const portalElement = document.getElementById("portal");

type Props = React.PropsWithChildren<
	{
		overlayClassname?: string;
		className?: string;
	} & DialogPrimitive.AlertDialogContentProps
>;

function AlertDialogContent({
	children,
	overlayClassname,
	className,
	...props
}: Props) {
	const classNames = cn(
		"fixed left-1/2 top-1/2 z-[1000] max-h-[85vh] w-full max-w-md -translate-x-1/2 -translate-y-1/2 animate-show text-slate-900 xl:max-w-lg 2xl:max-w-xl",
		className,
	);
	const overlayClassNames = cn(
		"fixed inset-0 z-[1000] bg-slate-900 bg-opacity-80",
		overlayClassname,
	);

	return (
		<DialogPrimitive.Portal container={portalElement}>
			<DialogPrimitive.Overlay className={overlayClassNames} />
			<DialogPrimitive.Content className={classNames} {...props}>
				{children}
			</DialogPrimitive.Content>
		</DialogPrimitive.Portal>
	);
}

export const AlertDialog = {
	Root: DialogPrimitive.Root,
	Trigger: DialogPrimitive.Trigger,
	Content: AlertDialogContent,
	Title: DialogPrimitive.Title,
	Description: DialogPrimitive.Description,
	Action: DialogPrimitive.Action,
	Cancel: DialogPrimitive.Cancel,
};
