export const baseConst = {
	rete: {
		VERSIONS: [
			"editor@0.1.0", //0 - initial version
			"editor@0.2.0", //1 - will return scripts as array allowing for async scripts
			// - updated naming scheme - now uses IDs instead of names
			"editor@1.0.0", //2 - uses rete@v2 in npm
			// - has a different structure of JSON - { id, nodes, connections }
			"editor@2.0.0", //3 - ability to differentiate between character/player/npc
			"editor@2.0.1", //4 - fix broken entity trigger outputs caused by renaming in 4f38e6c2bcc6259627333e7daaa26f59d7c9b226
			// - also fixed NPCMoveTo was placed in the wrong category
			"editor@2.1.0", //5 - merge display notification + display image = display hud pop-up
			"editor@2.2.0", //6 - remove block group state nodes in favor of global states
			"editor@2.3.0", //7 - convert events.comments type frames to event.frameNodes
			"editor@2.3.1", //8 - rename character animations (locomotion project)
			//"editor@2.4.0", //9 - add whoCanTrigger to entity trigger radius nodes
		],
	},

	objective: {
		//these must be strings because the node's input type is set to string
		TYPE_SIMPLE: "simple",
		TYPE_QUEST: "quest",
	},
};

export const AA_SETTING = Object.freeze({
	NO: "no",
	MSAA: "msaa",
	FXAA: "fxaa",
});

baseConst.rete.VERSION = baseConst.rete.VERSIONS.at(-1);
