import { lib } from "jacy";
import { create } from "zustand";

export type INotification = {
	type?: "info" | "success" | "error";
	message: string | string[];
};

type IGeneralNotificationState = {
	loading: boolean | null;
	notification: null | INotification;

	setLoading: (loading: boolean | null) => void;
	setNotification: (notification: INotification | null, autoclear?: boolean) => void;
};

let timeoutID: NodeJS.Timeout | null = null;

export const useGeneralNotificationStore = create<IGeneralNotificationState>(
	(set) => ({
		loading: null,
		notification: null,
		setLoading: (loading) => set({ loading }),

		setNotification: (notification, autoclear = true) => {
			if (timeoutID) {
				clearTimeout(timeoutID);
			}

			if (!lib.helpers.general.isNullish(notification) && autoclear) {
				timeoutID = setTimeout(() => set({ notification: null }), 5000);
			}

			set({ notification });
		},
	}),
);
