/* eslint-disable react-refresh/only-export-components */
import React from "react";

import { createBrowserRouter } from "react-router-dom";
import { Root } from "./Root";
import { WorldPreview } from "@components/landing/WorldPreview";

const LandingPage = React.lazy(() => import("@pages/LandingPage"));
const DemoPage = React.lazy(() => import("@pages/DemoPage"));
const GamePage = React.lazy(() => import("@pages/GamePage"));
const LoginPage = React.lazy(() => import("@pages/LoginPage"));
const RegisterPage = React.lazy(() => import("@pages/RegisterPage"));
const ProfilePage = React.lazy(() => import("@pages/AccountProfilePage"));
const AboutPage = React.lazy(() => import("@pages/AboutPage"));
const ErrorPage = React.lazy(() => import("@pages/ErrorPage"));
const ForgotPasswordPage = React.lazy(
	() => import("@pages/ForgotPassword/ForgotPasswordPage"),
);
const StyleguidePage = React.lazy(() => import("@pages/StyleguidePage"));

export const router = createBrowserRouter([
	{
		element: <Root />,
		children: [
			{
				path: "/",
				element: <LandingPage />,
				children: [
					{
						path: "/game/:worldId",
						element: <WorldPreview />,
					},
				],
			},
			{
				path: "/about",
				element: <AboutPage />,
			},
			{
				path: "/demo",
				element: <DemoPage />,
			},
			{
				path: "/play",
				element: <GamePage />,
			},
			{
				path: "/signin",
				element: <LoginPage />,
			},
			{
				path: "/signup",
				element: <RegisterPage />,
			},
			{
				path: "/profile",
				element: <ProfilePage />,
			},
			{
				path: "/forgot-password",
				element: <ForgotPasswordPage />,
			},
			{
				path: "/forgot-password/:token",
				element: <ForgotPasswordPage />,
			},
		],
	},
	{
		path: "/styleguide",
		element: <StyleguidePage />,
	},
	{
		path: "/*",
		element: <ErrorPage />,
	},
]);
