import { create } from "zustand";
import { lib } from "jacy";
import { constants } from "rest-client";
import { Desktop, DeviceMobile } from "@components/icons";

import { Engine } from "./bb";
import { useCustomUIModalStore } from "./dialogs";

const DEFAULT_CUSTOM_UI_COMPONENTS = constants.customUI.DEFAULT_CUSTOM_UI_COMPONENTS;

export const VIEW_MODE_OPTIONS = [
	{ name: "Mobile View", value: "sm", icon: DeviceMobile },
	{ name: "Desktop View", value: "lg", icon: Desktop },
];

export const getViewMode = (mode: ViewModeTypes) => {
	return VIEW_MODE_OPTIONS.find((viewMode) => viewMode.value === mode)?.name;
};

const CSS_STYLES = {
	fontFamily: "",
	fontSize: "",
	color: "",
	backgroundColor: "",
	padding: "",
	borderRadius: "",
	margin: "",
	width: "",
	height: "",
};

const INPUT_DATA = {
	displayToAll: "",
	value: "",
	text: "",
	stateData: "",
};

const CSS_SIDES = {
	top: "",
	bottom: "",
	left: "",
	right: "",
};

export type InputIndexes = keyof typeof INPUT_DATA;
export type CssStylesIndexes = keyof typeof CSS_STYLES;
export type PrebuiltUIComponentIndexes = keyof typeof DEFAULT_CUSTOM_UI_COMPONENTS;
export type QuadValueSides = keyof typeof CSS_SIDES;

export type ViewModeTypes = "lg" | "sm";
export type OptionType = {
	id: string;
	name: string;
	value: string;
};

export type QuadInputType = {
	top: number;
	bottom: number;
	left: number;
	right: number;
};

export type PrebuiltCustomUI = {
	name: string;
	value: string;
	id: number;
};

export type DefaultValueType = {
	value: string | number | boolean | null | undefined;
}[];

export type InputType = {
	name: string;
	type: string;
	defaultValue: string | number | boolean | Array<object>;
	value: string | number | boolean | null | undefined;
	inputKey: string;
	hidden?: boolean;
};

export type UIData = {
	displayToAll: InputDataType;
	value: InputDataType;
	text?: InputDataType;
	stateData?: InputDataType;
};

export type InputDataType = {
	value: string;
};

export type ValueType = string | number | QuadInputType;

export type StyleValueType = {
	lg: ValueType;
	sm: ValueType;
};

export type StyleType = {
	name: string;
	defaultValue: ValueType;
	value: StyleValueType;
	type: string;
	styleName: string;
	removable?: boolean;
	quadValue?: boolean;
	options?: OptionType[];
};

export type PositionType = {
	value: string;
	options: OptionType[];
	type: string;
};

export type GeneralStyles = {
	fontFamily?: StyleType;
	fontSize?: StyleType;
	color?: StyleType;
	backgroundColor?: StyleType;
	padding?: StyleType;
	borderRadius?: StyleType;
	margin?: StyleType;
	width?: StyleType;
	height?: StyleType;
};

export type GeneralInputs = {
	displayToAll: InputType;
	value?: InputType;
	text?: InputType;
	stateData?: InputType;
};

export type CustomUIComponent = {
	id?: string;
	name: string;
	type: string;
	show: boolean;
	position: PositionType;
	styles: GeneralStyles;
	inputs: GeneralInputs;
};

interface ICustomUIState {
	components: CustomUIComponent[];
	currentComponent: null | CustomUIComponent | undefined;
	selected: string;
	mode: "create" | "edit";
	viewMode: "lg" | "sm";
	clearCustomUI: () => void;
	setSelected: (selected: string) => void;
	setCurrentComponent: (currentComponent: CustomUIComponent | null) => void;
	setInitialComponent: (component: PrebuiltUIComponentIndexes) => void;
	setViewMode: (viewMode: ViewModeTypes) => void;
	setComponentStyle: (style: string, value: ValueType) => void;
	setInputs: (input: string, value: unknown) => void;
	setPosition: (position: string) => void;
	setComponents: (components: CustomUIComponent[]) => void;
	setComponentName: (name: string) => void;
	resetUI: () => void;
	handleSelectUI: (component: { name: string; value: string }) => void;
	showComponent: (id: string, inputs: UIData) => void;
	hideComponent: (id: string, inputs: UIData) => void;
	setMode: (mode: "create" | "edit") => void;
	saveComponent: (component: CustomUIComponent) => void;
	editComponent: (component: CustomUIComponent) => void;
	deleteComponent: (id?: string) => void;
	addComponent: (component: CustomUIComponent) => void;
	updateComponent: (component: CustomUIComponent) => void;
	removeComponent: (id: string) => void;
}

export type CustomCSSProps = React.CSSProperties &
	Record<`--${string}`, number | string>;

export const useCustomUIStore = create<ICustomUIState>((set, get) => ({
	components: [],
	currentComponent: null,
	selected: "",
	mode: "create",
	viewMode: "lg",
	setSelected: (selected) => set({ selected }),
	setCurrentComponent: (currentComponent) => set({ currentComponent }),
	setInitialComponent: (component: PrebuiltUIComponentIndexes) => {
		const componentCopy = JSON.deepCopy(
			DEFAULT_CUSTOM_UI_COMPONENTS[component],
		) as CustomUIComponent;
		set({ currentComponent: componentCopy });
	},
	setViewMode: (viewMode) => {
		set({ viewMode });
	},
	setComponentStyle: (style, value) => {
		set((state) => {
			const clonedState = JSON.deepCopy(state) as ICustomUIState;
			const clonedComponent = clonedState.currentComponent;
			if (clonedComponent) {
				const clonedStyles = clonedComponent.styles[style as CssStylesIndexes];
				if (clonedStyles) {
					clonedStyles.value[get().viewMode] = value;
				}
			}
			return clonedState;
		});
	},
	setInputs: (input, value) => {
		set((state) => {
			const clonedState = JSON.deepCopy(state) as ICustomUIState;
			const clonedComponent = clonedState.currentComponent;
			if (clonedComponent) {
				const clonedInputs = clonedComponent.inputs[input as InputIndexes];
				if (clonedInputs) {
					clonedInputs.value = value as any;
				}
			}
			return clonedState;
		});
	},
	setPosition: (position) => {
		set((state) => {
			const clonedState = JSON.deepCopy(state) as ICustomUIState;
			if (clonedState.currentComponent) {
				clonedState.currentComponent.position.value = position;
			}
			return clonedState;
		});
	},
	setComponents: (components) => set({ components }),
	setComponentName: (name) => {
		set((state) => {
			const clonedState = JSON.deepCopy(state) as ICustomUIState;
			if (clonedState.currentComponent) {
				clonedState.currentComponent.name = name;
			}
			return clonedState;
		});
	},
	resetUI: () => {
		set({
			components: get().components.map((component) => {
				component.show = false;
				return component;
			}),
		});
	},
	clearCustomUI: () => {
		set({ components: [] });
	},
	showComponent: (id, inputs) => {
		const uiComponents = get().components;

		set({
			components: uiComponents.map((currentComponent) => {
				if (currentComponent) {
					if (currentComponent.id === id) {
						currentComponent.show = true;

						for (const property in inputs) {
							const inputValue = inputs[property as InputIndexes];
							const currentInput = currentComponent.inputs[property as InputIndexes];
							if (currentInput && inputValue) {
								currentInput.value = inputValue.value;
							}
						}
					}
				}
				return currentComponent;
			}),
		});
	},
	hideComponent: (id, inputs) => {
		const uiComponents = get().components;

		set({
			components: uiComponents.map((currentComponent) => {
				if (currentComponent.id === id) {
					currentComponent.show = false;
					for (const property in inputs) {
						const inputValue = inputs[property as InputIndexes];
						const currentInput = currentComponent.inputs[property as InputIndexes];
						if (currentInput && inputValue) {
							currentInput.value = inputValue.value;
						}
					}
				}
				return currentComponent;
			}),
		});
	},

	setMode: (mode) => set({ mode }),

	// EVENTS
	handleSelectUI: (component) => {
		get().setMode("create");
		get().setSelected(component.name);
		get().setCurrentComponent(null);
		get().setInitialComponent(component.value as PrebuiltUIComponentIndexes);
		useCustomUIModalStore.getState().setOpen(true);
	},
	saveComponent: (component) => {
		// use the router
		const { router, BB } = new Engine();

		BB.world.customUI[router].addComponent(component);
	},

	addComponent: (component) => {
		set({
			components: [...get().components.map((component) => component), component],
		});
	},

	updateComponent: (component) => {
		set({
			components: get().components.map((currentComponent) => {
				if (currentComponent.id === component.id) {
					return component;
				}
				return currentComponent;
			}),
		});
	},

	removeComponent: (id) => {
		set({
			components: get().components.filter(
				(currentComponent) => currentComponent.id !== id,
			),
		});
	},

	editComponent: (component) => {
		if (lib.helpers.general.isNullish(component.id)) return;

		// use the router

		const { router, BB } = new Engine();

		BB.world.customUI[router].editComponent(component);
	},

	deleteComponent: (id) => {
		const { router, BB } = new Engine();

		BB.world.customUI[router].removeComponent(id);
	},
}));

export default {
	useCustomUI: useCustomUIStore.getState,
};
