import { create } from "zustand";
import { JacyCore } from "jacy";

import { Engine } from "./bb";
import { useControlsStore } from "./controls";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";

export const CHAT_HISTORY_LIMIT = 100;
export const LATEST_CHAT_MESSAGES_COUNT = 3;

export const useChatStore = create((set, get) => ({
	open: false,
	latestMessages: [],
	messages: [],
	MESSAGE_LIMIT: 200,

	// Handlers
	openChat: () => {
		if (isAnyModalOpen()) return;

		get().setOpen(true);
	},
	close: () => set({ open: false }),
	setOpen: (open, delay = false) => {
		if (open && isAnyModalOpen()) return;

		set({ open });

		setTimeout(
			() => {
				useControlsStore.getState().exitPointerLock(open);
			},
			delay ? 200 : 0,
		);
	},

	// Events
	init: ({ MESSAGE_LIMIT = 200 }) => {
		set({ MESSAGE_LIMIT });
	},
	sendChat: (message) => {
		const { gbi } = new Engine();
		gbi.router.ChatRouter.clientSendChat(message);
		set({ open: false });
		useControlsStore.getState().exitPointerLock(false);
	},
	receiveChat: (time, username, message, color) => {
		set((state) => {
			const messages = [
				...state.messages,
				{
					id: JacyCore.generateUUID(),
					time,
					username:
						username?.length > 0 ? username.slice(0, username.length - 1) : "System",
					message,
					timestamp: Date.now(),
					color,
				},
			];

			return {
				messages,
				latestMessages: messages.slice(-LATEST_CHAT_MESSAGES_COUNT),
			};
		});
	},
}));

export default {
	useChat: useChatStore.getState,
};
