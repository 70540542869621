import { constants } from "rest-client";

export default `
    @font-face {
        font-family: 'Righteous';
        src: url(${constants.getStaticFile("assets/frontend/fonts/Righteous/Righteous-Regular.woff2")}) format('woff2'); 
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;
