import type { IExpose } from "@lib/types/expose";
import { useSelectorStore } from "@stores/hud/selector";
import type { ISelectorSlots } from "jacy";

export type IGameState = {
	position: { x: number; y: number; z: number } | null;
	selector: ISelectorSlots;
};

export function getCurrentState(engine: IExpose): IGameState | null | undefined {
	if (!engine.BB.world?.[engine.client]) return;

	const gamePosition = engine.BB.world[engine.client].camera.target.position as {
		x: number;
		y: number;
		z: number;
	} | null;

	const position = gamePosition ? { ...gamePosition } : null;
	const selector = useSelectorStore.getState().selector;

	return {
		position,
		selector,
	};
}

export function setCurrentState(engine: IExpose, state?: IGameState | null) {
	if (!state) return;
	if (!engine.BB.world?.[engine.client]) return;

	if (state.position) {
		engine.BB.world[engine.client].camera.target.setPosition(
			state.position.x,
			state.position.y,
			state.position.z,
		);
	}

	useSelectorStore.getState().setSelector(state.selector);
}
