import {
	IJacyApi,
	IJacyContent,
	IJacyEventsEmitter,
	JacyCore,
	IBundle,
	lib,
	IIdentifier,
	IAuthUser,
	IPlayerAccess,
	UserWorldAccess,
} from "jacy";
import { api } from "rest-client";

import { useEngineStore } from "@stores/bb";
import { useJacyUserStore } from "@stores/jacy/user";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { useAuthUserStore } from "@stores/auth-user";
import { usePlayerStore } from "@stores/player";
import { useSettingsStore } from "@stores/settings";

import { initializeAnalytics } from "@lib/helpers/analytics/analyzeMe";
import { IExpose } from "@lib/types/expose";

import { JacyActions } from "./content/JacyActions";
import { JacySyncer } from "./syncer/JacySyncer";
import { JacyWebsockets } from "./websockets/JacyWebsockets";
import { GameClient, Router } from "@jamango/client";

export class JacyClient {
	initializePromise: ReturnType<
		typeof lib.helpers.requests.createRequestPromise<boolean>
	>;
	defaultBundle: IBundle | null;
	access: IPlayerAccess;
	tutorialWorldId?: IIdentifier | null;
	dom: {
		avatarEditor: HTMLCanvasElement | null;
	};

	api: IJacyApi;
	content: IJacyContent;
	state: IJacyContent["state"];
	events: IJacyEventsEmitter;
	actions: JacyActions;
	syncer: JacySyncer;
	websockets: JacyWebsockets;

	getFilePath: IJacyContent["state"]["getFilePath"];

	constructor() {
		this.initializePromise = lib.helpers.requests.createRequestPromise<boolean>();
		this.defaultBundle = null;
		this.tutorialWorldId = null;
		this.access = UserWorldAccess.OWNER;
		this.dom = {
			avatarEditor: null,
		};

		this.api = new JacyCore.Api();
		this.events = new JacyCore.EventEmitter();
		this.content = new JacyCore.Content(this.events);
		this.state = this.content.state;
		this.actions = new JacyActions(this);
		this.syncer = new JacySyncer(this);
		this.websockets = new JacyWebsockets();

		this.getFilePath = this.state.getFilePath.bind(this.content.state);
	}

	async init() {
		api.setBaseURL(globalEnv.API_URL);
		this.api.setBaseURL(globalEnv.API_URL);

		const { token, username } = Router.getParams();

		if (token && username) {
			this.api.setAuth({ token, username });
		}

		const [bundle, authUser, location, tutorialWorldId] = await Promise.all([
			this.api.getDefaultWorld(),
			api.auth.auth().catch(() => null),
			api.auth.getUserLocation().catch(() => null),
			api.world.getDefaultCreatorTutorial().catch(() => null),
		]);

		this.tutorialWorldId = tutorialWorldId?.world_id;

		if (globalEnv.NODE_ENV !== "development") {
			if (
				location &&
				(location.region === "Utah" || location.country === "Sweden")
			) {
				this.state.user.hasFullAccess = true;
			}
		}

		this.defaultBundle = bundle;

		if (authUser) {
			this.setUser(authUser);
		}

		initializeAnalytics({ userId: authUser?.id, playerId: authUser?.playerId });

		this.initializePromise.resolve(true);
	}

	async initDefaultAssets() {
		const bundle = await this.api.getDefaultAssets();
		this.state.environmentPreset.importAssets(bundle.envPresets);
		this.state.blockTextures.import(bundle.blockTextures);
		this.state.avatarComponents.import(bundle.avatarComponents);
		this.state.avatars.import(bundle.avatars, true);
		this.state.worldTags.import(bundle.allTags);
	}

	async setUser(user?: IAuthUser | null) {
		this.content.state.user.setUser(user);

		if (user) {
			useJacyUserStore.setState({
				playerId: user.playerId,
				username: user.username,
				displayPhoto: this.content.state.user.displayPhoto,
				hasFullAccess: user.hasFullAccess,
				featureFlags: user.featureFlags,
			});
		} else {
			useJacyUserStore.getState().clear();
		}

		usePlayerStore.getState().setUsername(user ? user.username : null);
		useAuthUserStore.setState({ hasPlayedTutorial: user?.hasPlayedTutorial });
		useAuthUserStore.getState().syncFeatureFlags();

		if (user) {
			useSettingsStore
				.getState()
				.setSettings({ acceptedAlphaTOS: user.acceptedAlphaTOS });
		}

		this.refreshUserState();
		await this.initDefaultAssets();

		this.actions.avatar.loadAvatarInGame();
	}

	setIsServer(isServer: boolean) {
		this.content.state.isServer = isServer;
		this.refreshUserState();
	}

	setIsPublicServer(isPublicServer: boolean) {
		this.content.state.isPublicServer = isPublicServer;
		this.refreshUserState();
	}

	setIsTesting(isTesting: boolean) {
		this.content.state.world.isTesting = isTesting;
		this.refreshUserState();
	}

	setAvatarEditorCanvas(canvas: HTMLCanvasElement) {
		this.dom.avatarEditor = canvas;
		useJacyRerenderStore.getState().forceRerenderAvatarCanvas();
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	callbacks(...callbacks: Function[]) {
		return () => callbacks.forEach((callback) => callback());
	}

	hasChanges() {
		return this.state.changesets.export().length > 0;
	}

	async getWorldData(includeBuiltIns?: boolean) {
		const bundle = this.content.export();

		if (!bundle) {
			throw new Error("Failed to start a new world, missing bundle data.");
		}

		const engine = await this.getEngine();
		return engine.converter.getWorldData(bundle, includeBuiltIns);
	}

	async getEngine() {
		const expose = await useEngineStore.getState().promise;

		const jacyEngine = new JacyCore.Engine(expose as IExpose);

		return jacyEngine;
	}

	/**
	 * @param {boolean} shouldClone - defaults to true. When this is true, it will rename all the files (except the audio files).
	 */
	async import(shouldClone?: boolean) {
		await GameClient.importWorld(shouldClone);

		lib.helpers.console.log("World has been imported!");
		lib.helpers.console.log(
			"WARNING: Persisting the imported world with characters/avatars isn't supported yet for this feature. Tho it is planned to include that feature in the near future.",
		);
	}

	async export() {
		if (!Router.isPlayPage()) {
			throw new Error("Exporting is only available in the play page.");
		}

		const engine = await this.getEngine();
		const mapBlob = await engine.getMapBuffer();
		this.state.map.update(mapBlob);

		const bundle = await this.content.serializer.export();

		if (!bundle) {
			throw new Error("Failed to export the world.");
		}

		const linkEl = document.createElement("a");
		linkEl.download = `world-${this.state.world.bundleId}.jam`;
		linkEl.href = URL.createObjectURL(bundle);
		linkEl.click();
		URL.revokeObjectURL(linkEl.href);

		lib.helpers.console.log("World has been exported!");
		lib.helpers.console.log(
			"WARNING: Persisting the imported world with characters/avatars isn't supported yet for this feature. Tho it is planned to include that feature in the near future.",
		);
	}

	refreshContentState() {
		useJacyRerenderStore.getState().forceRerenderWorld();
		useJacyRerenderStore.getState().forceRerenderThumbnail();
		useJacyRerenderStore.getState().forceRerenderBackgroundMusic();
		useJacyRerenderStore.getState().forceRerenderCustomLoader();
		useJacyRerenderStore.getState().forceRerenderSettings();
		useJacyRerenderStore.getState().forceRerenderPermissions();
		useJacyRerenderStore.getState().forceRerenderBlocks();
		useJacyRerenderStore.getState().forceRerenderBlockTextures();
		useJacyRerenderStore.getState().forceRerenderWorldTags();
		useJacyRerenderStore.getState().forceRerenderAvatars();
		useJacyRerenderStore.getState().forceRerenderCharacters();
		useJacyRerenderStore.getState().forceRerenderChangeset();
		useJacyRerenderStore.getState().forceRerenderAudios();

		this.refreshUserState();
	}

	refreshUserState() {
		const state = Jacy.content.state;

		useJacyUserStore.setState({
			isGuest: state.user.isGuest,
			isOwner: state.user.isOwner,
			isCollaborator: state.user.isCollaborator,
			isCreator: state.user.isCreator,

			hasFullAccess: state.user.hasFullAccess,
			canEdit: state.user.canEdit,
			canSave: state.user.canSave,
			canDelete: state.user.canDelete,
			canSaveWorld: state.user.canSaveWorld,
		});
	}
}

export const Jacy = new JacyClient();
