import { X as CloseIcon } from "@components/icons";

import { useHubWorldModal } from "@stores/dialogs";
import { Dialog } from "@components/ui/Dialog";
import { HubUserWorlds } from "@components/hub/HubDialog/screens/HubUserWorlds";
import { Button } from "@components/ui/Button";

export function LoadWorldModal() {
	const showHubWorlds = useHubWorldModal((state) => state.open);
	const setShowHubWorlds = useHubWorldModal((state) => state.setOpen);

	return (
		<Dialog.Root open={showHubWorlds} onOpenChange={() => setShowHubWorlds(false)}>
			<Dialog.Content className="z-10 max-w-7xl rounded-lg bg-white py-4 shadow-lg">
				<Button
					className="absolute right-6 top-4 z-10"
					onClick={() => setShowHubWorlds(false)}
					square
					color="white-ghost"
				>
					<CloseIcon className="size-6" />
				</Button>
				<Dialog.Title className="sr-only">Load World</Dialog.Title>
				<HubUserWorlds selected={true} setScreen={() => {}} close={() => {}} />
			</Dialog.Content>
		</Dialog.Root>
	);
}
