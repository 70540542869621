import { IAssetKey } from "jacy";
import { create } from "zustand";
import {
	GlobeHemisphereWest,
	CubeTransparent,
	Play,
	SquaresFour,
	Cube,
	Robot,
	Waveform,
	Keyhole,
	CloudSun,
	GameController,
	Sliders,
	LockKey,
	Flask,
	Layout,
} from "@components/icons";

import { Engine } from "../bb";
import { useControlsStore } from "../controls";
import { useHubStore } from "../dialogs";
import { usePermissionStore } from "../player";
import { useBlockWrenchStore } from "../wrench";
import { useWorldStore } from "../world";
import { useHelpersStore } from "../helpers";
import { useSelectorStore } from "../hud/selector";

export enum MainTab {
	WORLD = "World",
	CREATE = "Create",
	PLAY = "Play",
}

export enum CreateTab {
	ALL = "All",
	BLOCKS = "Blocks",
	BLOCK_PACKS = "Block Packs",
	CHARACTERS = "Characters",
}

export enum WorldTab {
	BASIC = "Basic",
	GAME = "Game",
	ENVIRONMENT = "Environment",
	PERMISSIONS = "Permissions",
	SOUNDS = "Sounds",
	BLOCK_GROUPS = "Block Groups",
	EXPERIMENTS = "Experiments",
	CUSTOM_UI = "Custom UI",
}

export enum PlayTab {
	PRIVATE = "Private",
	PUBLIC = "Public",
}

export const TABS = [
	{ label: MainTab.WORLD, icon: GlobeHemisphereWest },
	{ label: MainTab.CREATE, icon: CubeTransparent },
	{ label: MainTab.PLAY, icon: Play },
];

export const CREATE_TABS = [
	{ label: CreateTab.ALL, icon: SquaresFour },
	{ label: CreateTab.BLOCKS, icon: Cube },
	{ label: CreateTab.CHARACTERS, icon: Robot },
];

export const WORLD_TABS = [
	{ label: WorldTab.BASIC, icon: Sliders },
	{ label: WorldTab.GAME, icon: GameController },
	{ label: WorldTab.ENVIRONMENT, icon: CloudSun },
	{ label: WorldTab.PERMISSIONS, icon: Keyhole },
	{ label: WorldTab.SOUNDS, icon: Waveform },
	{ label: WorldTab.BLOCK_GROUPS, icon: Cube },
	{ label: WorldTab.EXPERIMENTS, icon: Flask },
	{ label: WorldTab.CUSTOM_UI, icon: Layout },
];

export const PLAY_TABS = [
	{ label: PlayTab.PRIVATE, icon: LockKey },
	{ label: PlayTab.PUBLIC, icon: GlobeHemisphereWest },
];

const DEFAULT_STATE = {
	open: false,
	selectedTab: MainTab.CREATE,
	createTab: CreateTab.ALL,
	worldTab: WorldTab.BASIC,
	playTab: PlayTab.PRIVATE,
	selectedBlockId: null,
	selectedCharacterId: null,
};

type IInventoryState = {
	open: boolean;
	selectedTab: MainTab;
	createTab: CreateTab;
	worldTab: WorldTab;
	playTab: PlayTab;
	selectedBlockId?: IAssetKey | null;
	selectedCharacterId?: IAssetKey | null;

	// Handlers
	toggle: (state?: boolean, togglePointerLock?: boolean) => void;
	close: () => void;
	setSelectedTab: (selectedTab: MainTab) => void;
	setCreateTab: (createTab: CreateTab) => void;
	setWorldTab: (worldTab: WorldTab) => void;
	setPlayTab: (playTab: PlayTab) => void;
	setSelectedBlockId: (selectedBlockId: IAssetKey) => void;
	setSelectedCharacterId: (selectedCharacterId: IAssetKey) => void;

	// Actions
	clear: () => void;
};

export const useInventoryStore = create<IInventoryState>((set, get) => ({
	...DEFAULT_STATE,

	// Handlers
	close: () => set({ open: false, selectedTab: MainTab.CREATE }),
	toggle: (state, togglePointerLock = true) => {
		const canBuild = usePermissionStore.getState().permissions.canBuild;
		if (!canBuild) return;

		const isWrenchOpen = useBlockWrenchStore.getState().open;
		if (isWrenchOpen) return;

		const open = state ?? !get().open;
		set({ open });

		useHelpersStore.getState().markInventoryKnown();

		if (open) {
			useWorldStore.getState().refreshCpu();
		}

		const { BB } = new Engine();

		if (BB.world?.sfx) {
			BB.world.sfx.play({
				asset: open ? "snd-inventory-open" : "snd-inventory-close",
				loop: false,
				volume: 0.5,
			});

			if (togglePointerLock) {
				useControlsStore.getState().exitPointerLock(open);
			}
		}

		if (open) {
			useHubStore.getState().close();
		}
	},
	setSelectedTab: (selectedTab) => set({ selectedTab }),
	setCreateTab: (createTab) => set({ createTab }),
	setWorldTab: (worldTab) => set({ worldTab }),
	setPlayTab: (playTab) => set({ playTab }),
	setSelectedBlockId: (selectedBlockId: IAssetKey) => {
		set({ selectedBlockId, selectedCharacterId: null });
	},
	setSelectedCharacterId: (selectedCharacterId: IAssetKey) => {
		set({ selectedBlockId: null, selectedCharacterId });
	},

	// Actions
	clear: () => {
		set({ selectedBlockId: null, selectedCharacterId: null });
		useSelectorStore.getState().clear();
	},
}));

export default {
	useInventory: useInventoryStore.getState,
};
