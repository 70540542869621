import React from "react";
import { X } from "@components/icons";

import { useGeneralPromptStore } from "@stores/dialogs";
import { validate } from "@lib/helpers/validators";
import { AlertDialog } from "@components/ui/AlertDialog";
import { Input } from "@components/ui/Input";
import { ErrorNote } from "@components/ui/ErrorNote";

export function PromptDialog() {
	const state = useGeneralPromptStore((state) => ({
		open: state.open,
		setOpen: state.setOpen,
		promptText: state.promptText,
		confirmText: state.confirmText,
		cancelText: state.cancelText,
		confirm: state.confirm,
		cancel: state.cancel,
		validator: state.validator,
	}));

	const [answer, setAnswer] = React.useState("");
	const [error, setError] = React.useState("");

	const disabled = answer.length === 0;

	React.useEffect(() => {
		setAnswer("");
		setError(null);
	}, [state.open]);

	const toggleOpen = (open) => {
		state.setOpen(open);
		setAnswer("");

		if (!open) state.cancel();
	};

	const submit = (evt) => {
		evt.preventDefault();
		if (disabled) return;
		setError(null);

		if (state.validator) {
			try {
				validate(state.validator, answer);
			} catch (ex) {
				setError(ex.message);
				return;
			}
		}

		state.confirm(answer);
	};

	return (
		<AlertDialog.Root open={state.open} onOpenChange={(value) => toggleOpen(value)}>
			<AlertDialog.Content>
				<div className="overflow-y-auto rounded border-b border-gray-400 bg-white p-6 shadow-xl">
					<div className="flex justify-between gap-8">
						<AlertDialog.Title className="text-2xl">
							{document.title}
						</AlertDialog.Title>
						<button
							type="button"
							className="block rounded bg-transparent p-1 text-white hover:bg-gray-100 focus:bg-gray-100"
							onClick={() => toggleOpen(false)}
						>
							<X className="size-6 text-gray-900" aria-hidden="true" />
							<span className="sr-only">Close</span>
						</button>
					</div>
					<ErrorNote message={error} />
					<AlertDialog.Description className="my-2 text-base text-gray-700">
						{state.promptText}
					</AlertDialog.Description>
					<form onSubmit={submit}>
						<Input
							type="text"
							id="answer"
							name="answer"
							className="my-2 w-full"
							value={answer}
							onChange={(evt) => setAnswer(evt.target.value)}
							required
							autoFocus
						/>

						<div className="my-2 flex items-center justify-end gap-1">
							<button
								type="button"
								onClick={state.cancel}
								className="flex items-center justify-center rounded border-b-2 border-gray-400 bg-gray-200 px-4 py-3 text-base font-semibold text-gray-900 hover:bg-red-600 hover:text-white focus:bg-red-600 focus:text-white"
							>
								{state.cancelText || "Cancel"}
							</button>
							<button
								type="submit"
								className={
									"flex items-center justify-center rounded border-b-2  border-gray-400 px-4 py-3 text-base font-semibold " +
									(disabled
										? "bg-gray-400 text-gray-900"
										: "bg-red-600 text-white hover:bg-red-700 focus:bg-red-700")
								}
								disabled={disabled}
							>
								{state.confirmText || "Submit"}
							</button>
						</div>
					</form>
				</div>
			</AlertDialog.Content>
		</AlertDialog.Root>
	);
}
