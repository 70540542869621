import { Jacy } from "@jacy-client";
import { IBundle, IIdentifier, IPlayer, IPlayerAccess } from "jacy";
import { api } from "rest-client";
import { mutate } from "swr";

import { useMultiplayerStore } from "@stores/game-client/multiplayer";
import { usePlayerStore } from "@stores/player";
import { useConfirmPromptStore, useGeneralPromptStore } from "@stores/dialogs";
import { Engine, useEngineStore } from "@stores/bb";
import { Loading } from "@stores/game-client/game-client";
import { generateIdentifier } from "@lib/helpers/generateIdentifier";
import { IExpose } from "@lib/types/expose";

import * as GameClient from "./GameClient";
import * as GameLifeCycle from "./GameLifeCycle";
import * as Router from "./Router";

import { useLoadingScreenStore } from "@stores/loading-screen";

export type IServer = {
	serverID: string;
	username: string;
	dedicated: boolean;
	worldID?: string;
};

export type IMultiplayerState = {
	loading: null | Loading;
	isHosting: boolean;
	isServer: boolean;
	isPublicServer: boolean;
	serverID: string | null;
	peerID: string | null;
	hostname: string | null;
	players: IPlayer[];
	servers: IServer[];
};

export const state: IMultiplayerState = {
	loading: null,
	isHosting: false,
	isServer: true,
	isPublicServer: false,
	serverID: null,
	peerID: null,
	hostname: null,
	players: [],
	servers: [],
};

export function init() {
	Jacy.websockets.on("live-now", () => {
		refetchOnlineServers();
	});
	Jacy.websockets.on("offline-now", () => {
		refetchOnlineServers();
	});
}

async function beginConnection(customName?: string) {
	const authUsername = Jacy.state.user.username;
	const isGuest = Jacy.state.user.isGuest;
	const { username: fallbackName, setUsername } = usePlayerStore.getState();

	const username = customName ?? authUsername ?? fallbackName;
	setUsername(username);

	let playerAuthToken;
	if (!isGuest) {
		playerAuthToken = (await api.ibs.registerPlayerToken({ username })).token;
	}

	return { username, playerAuthToken };
}

function getWorldBundle(access: IPlayerAccess) {
	return access ? Jacy.content.export() : Jacy.content.exportPlayerBundle();
}

async function onJoinLoadWorld(
	bundle: {
		content: IBundle;
		world: any;
		access: IPlayerAccess;
	},
	ibs: any,
) {
	await Jacy.initDefaultAssets();
	Jacy.content.import(bundle.content);
	await Jacy.actions.init();
	GameLifeCycle.onBeforeLoadWorld();
	updateState({ isServer: false });

	const engine = await Jacy.getEngine();
	const world = await engine.joinWorld(Jacy.content, bundle, ibs);

	return world;
}

export async function hostWorld(options = { restartUpdateLoop: false }) {
	updateState({
		loading: Loading.HOST_WORLD,
		isServer: true,
		isHosting: false,
		serverID: undefined,
		peerID: undefined,
		hostname: undefined,
	});

	try {
		const { username, playerAuthToken } = await beginConnection();

		const world = Jacy.state.world.get();
		const maxPeers = Jacy.state.gameMechanics.privateP2PMaxPeers;
		const serverID = generateIdentifier(5);
		const worldID = world.identifier;

		const engine = (await useEngineStore.getState().promise) as IExpose;
		await engine.gbi.base.Multiplayer.host({
			serverID,
			maxPeers,
			loginData: {
				username,
				playerAuthToken,
				worldID,
			},
			restartUpdateLoop: options.restartUpdateLoop,
			getWorldBundle,
			onJoinLoadWorld,
		});

		GameLifeCycle.onAfterHostOrJoinWorld(serverID, world.identifier);

		const isCreator = Jacy.state.user.isCreator;

		if (worldID && isCreator) {
			api.world
				.setWorldAsOnline({ identifier: worldID })
				.catch((error) => console.error(error));
		}

		updateState({
			loading: null,
			isHosting: true,
			serverID,
			peerID: serverID,
			hostname: username,
		});
	} catch (error) {
		console.error(error);
	}

	updateState({ loading: null });
}

export async function joinPublishedWorld(worldID: IIdentifier) {
	updateState({ loading: Loading.JOIN_WORLD });
	await GameLifeCycle.onDisposeWorld();

	const engine = (await useEngineStore.getState().promise) as IExpose;
	const result = await api.worldSettings.getWorldMaxPlayers({
		identifier: worldID,
	});
	const maxPlayers = result === "Infinity" ? Infinity : parseInt(result, 10);

	if (maxPlayers === 1) {
		updateState({ isServer: true, isPublicServer: true });
		await GameClient.loadWorld(worldID, false);
	} else {
		updateState({ isServer: false, isPublicServer: true });
		GameLifeCycle.onBeforeNavigatePageToLoadWorld();
		await engine.gbi.base.Multiplayer.matchmake(worldID);
	}

	updateState({ loading: null });
}

export async function joinWorld(
	serverID: string,
	options: { playerMatchmakingToken?: string; force?: boolean } = { force: true },
) {
	const worldID = Jacy.state.world.identifier;

	if (!options.force && worldID && state.isServer) {
		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Are you sure?",
			description:
				"You are about to join a different server. You will lose any unsaved progress if you leave this server. This action cannot be undone.",
			confirmText: "Yes, join server",
		});

		if (!confirmed) return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	useLoadingScreenStore.getState().setText("Joining world");
	useLoadingScreenStore.getState().show();
	updateState({ loading: Loading.JOIN_WORLD });
	await GameLifeCycle.onDisposeWorld();

	localStorage.setItem("serverId", serverID);

	let customGuestUsername: null | string | false = null;
	if (Jacy.state.user.isGuest && globalEnv.NODE_ENV !== "development") {
		customGuestUsername = await useGeneralPromptStore
			.getState()
			.prompt("Enter your player name to join", {
				confirmText: "Start Game",
				cancelText: `Cancel`,
			});
	}

	const { username, playerAuthToken } = await beginConnection(
		customGuestUsername || undefined,
	);

	const engine = (await useEngineStore.getState().promise) as IExpose;
	const ibs = await engine.gbi.base.Multiplayer.join({
		serverID,
		playerMatchmakingToken: options.playerMatchmakingToken,
		loginData: {
			username,
			playerAuthToken,
		},
		getWorldBundle,
		onJoinLoadWorld,
	});
	GameLifeCycle.onAfterLoadWorld(engine);
	GameLifeCycle.onAfterHostOrJoinWorld(serverID);

	updateState({
		loading: null,
		isHosting: false,
		serverID,
		peerID: ibs.peerID,
		hostname: null,
		isPublicServer: false,
	});
}

export async function stopHosting() {
	const confirmed = await useConfirmPromptStore.getState().prompt({
		title: "Are you sure?",
		description:
			"All players that are in this server will be kicked out. This action cannot be undone.",
		confirmText: "Yes, stop hosting",
	});

	if (!confirmed) return;

	updateState({ loading: Loading.STOP_HOSTING });

	await disconnect();

	updateState({ loading: null });
}

export async function disconnect() {
	const url = new URL(window.location.href);
	url.searchParams.delete("game");
	Router.replaceHistory(url.pathname + url.search);

	// No need to wait for the engine to load, only disconnect if engine exists.
	const engine = new Engine();

	if (engine && engine.gbi?.base.Multiplayer.isConnected()) {
		await engine.gbi.base.Multiplayer.disconnect();

		const worldID = Jacy.state.world.identifier;
		const isCreator = Jacy.state.user.isCreator;

		if (worldID && isCreator) {
			api.world
				.setWorldAsOffline({ identifier: worldID })
				.catch((error) => console.error(error));
		}
	}

	updateState({
		isHosting: false,
		hostname: null,
		serverID: null,
		peerID: null,
		isServer: true,
		isPublicServer: false,
	});
}

export function getJoinLink(serverID?: string, worldID?: string) {
	const url = new URL(window.location.href);

	url.searchParams.delete("game");
	url.searchParams.delete("world");

	if (serverID) {
		url.searchParams.append("game", serverID);
	}

	if (worldID) {
		url.searchParams.append("world", worldID);
	}

	return url.href;
}

export async function refetchOnlineServers(): Promise<IServer[]> {
	const { gbi } = new Engine();
	if (!gbi) return [];

	const servers = await gbi.base.Multiplayer.getServerList().catch(() => []);
	updateState({ servers });

	mutate("/worlds/published");

	return servers;
}

export function filterCollaboratorServers(servers: IServer[]) {
	const world = Jacy.state.world.get();
	const user = Jacy.state.user.getUser();

	const owner = world.owner;
	const collaborators = (world.collaborators ?? []).map(
		(collaborator) => collaborator.username,
	);

	if (!user || !owner) return [];
	if (!collaborators) return [];

	return servers.filter(
		(server) =>
			server.worldID === world.identifier &&
			server.serverID !== state.serverID &&
			server.username !== user.username &&
			(owner.username === server.username ||
				collaborators.includes(server.username)),
	);
}

export async function getCollaboratorServers(): Promise<IServer[]> {
	const servers = await refetchOnlineServers();
	return filterCollaboratorServers(servers);
}

export function setPlayers(players: IPlayer[]) {
	const host = players.find((player) => player.isHost)?.username;
	updateState({ players, hostname: host });
}

export function updateState(payload: Partial<IMultiplayerState>) {
	useMultiplayerStore.setState(payload);

	if (payload.isServer !== undefined) {
		Jacy.setIsServer(payload.isServer);
	}

	if (payload.isPublicServer !== undefined) {
		Jacy.setIsPublicServer(payload.isPublicServer);
	}

	Object.keys(payload).forEach((key) => {
		state[key as keyof typeof state] = payload[key as keyof typeof payload] as never;
	});
}
