import { InputPoll } from "@engine/client/input/Poll";

export function mobileMedia() {
	return null;
	// eslint-disable-next-line etc/no-commented-out-code
	// return window.matchMedia("(hover: none) and (pointer: coarse)");
}

// Note: Would be good to not use the InputPoll here, instead use the CSS media to get isMobile status to allow switching between mobile and desktop without a page reload.
export function isMobile() {
	return InputPoll.isMobileBrowser();
	// eslint-disable-next-line etc/no-commented-out-code
	// return mobileMedia().matches;
}
