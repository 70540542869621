import { create } from "zustand";

export const useBulkBloganStore = create((set) => ({
	// current mode of the pencil/bulk blogan tool. only valid when equippedItem === "ItemBulkBlogan"
	bloganContext: 0,

	clipboardHasContents: false,
	canUndo: false,
	canRedo: false,

	setBloganContext: (bloganContext) => {
		set({ bloganContext });
	},

	setClipboardHasContents: (clipboardHasContents) => {
		set({ clipboardHasContents });
	},

	setCanUndo: (canUndo) => {
		set({ canUndo });
	},

	setCanRedo: (canRedo) => {
		set({ canRedo });
	},
}));

export default {
	useBulkBlogan: useBulkBloganStore.getState,
};
