import { Jacy } from "@jacy-client";
import { UserWorldAccess, type IBundle } from "jacy";

import * as Device from "./Device";
import * as Router from "./Router";
import * as GameLifeCycle from "./GameLifeCycle";
import * as GameMultiplayer from "./GameMultiplayer";
import * as GameClientState from "./GameClientState";

import { useConfirmPromptStore, useErrorStore, useHubStore } from "@stores/dialogs";
import { useLoadingScreenStore } from "@stores/loading-screen";
import { useAuthDialogStore, useAuthUserStore } from "@stores/auth-user";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useEngineStore } from "@stores/bb";
import { Loading, useGameClientStore } from "@stores/game-client/game-client";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { trackEvent } from "@lib/helpers/analytics/analyzeMe";
import type { IExpose } from "@lib/types/expose";
import { formatErrorMessage } from "@lib/helpers/formatErrorMessage";
import { useSelectorStore } from "@stores/hud/selector";
import { useJacyUserStore } from "@stores/jacy/user";
import {
	BlankWorldTemplate,
	type WorldTemplateOptions,
} from "@components/world/templates";

export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export async function init() {
	Device.init();
	Router.init();
	GameMultiplayer.init();
	await Jacy.init();
}

type NewWorldOptions = {
	worldTemplateOptions: WorldTemplateOptions;
	force?: boolean;
};

export async function startNewWorld({
	worldTemplateOptions: { terrainGeneration, defaultInventory, envPresetSettings },
	force,
}: NewWorldOptions) {
	if (!force && Router.isPlayPage()) {
		const confirmPrompt = useConfirmPromptStore.getState().prompt;
		const confirmed = await confirmPrompt({
			title: "Are you sure?",
			description: Jacy.state.user.isCreator
				? "This will generate a new world. Don't forget to save any unsaved progress of your current world."
				: "Are you sure you want to leave this world to start your own world?",
			confirmText: "Yes, start new world",
		});

		if (!confirmed) return;
	}

	if (!localStorage.getItem("serverId") && !localStorage.getItem("worldId")) {
		trackEvent("event", "start_world_from_homepage");
	}

	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to start a new world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	useGameClientStore.getState().setLoading(Loading.LOAD_WORLD);
	useLoadingScreenStore.getState().show();
	await GameLifeCycle.onDisposeWorld();
	await Jacy.initializePromise;

	try {
		if (!Jacy.defaultBundle) {
			throw new Error("Default bundle is missing. Please initialize Jacy first.");
		}

		await Jacy.initDefaultAssets();

		GameMultiplayer.updateState({ isPublicServer: false });
		const bundle = Jacy.content.generateNewWorld(Jacy.defaultBundle);
		Jacy.content.import(bundle, { isDefaultWorld: true });
		Jacy.content.state.map.setOptions({
			terrainGenerationOptions: terrainGeneration,
		});

		if (envPresetSettings) {
			const envPresetState = Jacy.content.state.environmentPreset;
			const current = envPresetState.current;
			if (current) {
				envPresetState.update(current.pk, current.name, envPresetSettings);
			}
		}

		Jacy.actions.init();
		GameLifeCycle.onBeforeLoadWorld();

		const engine = await Jacy.getEngine();
		await engine.startNewWorld(Jacy.content);

		const inventory = defaultInventory
			? useSelectorStore
					.getState()
					.convertDefaultInventoryToIventorySlots(defaultInventory)
			: undefined;

		GameLifeCycle.onAfterLoadWorld(engine.expose, inventory);
	} catch (error) {
		if (error instanceof Error) {
			useErrorStore.getState().setError(error);
		}
	}

	useGameClientStore.getState().setLoading(null);
}

export async function loadWorld(identifier: string, isPrivateServer = true) {
	const loading = useGameClientStore.getState().loading;
	const isGuest = useJacyUserStore.getState().isGuest;
	const hasFullAccess = useJacyUserStore.getState().hasFullAccess;

	if (isGuest && !hasFullAccess) {
		Router.navigate("/signin");
		return;
	}

	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to load a world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	if (
		Router.isPlayPage() &&
		Jacy.hasChanges() &&
		!(Jacy.state.world.identifier && identifier === Jacy.state.world.identifier)
	) {
		const confirmPrompt = useConfirmPromptStore.getState().prompt;
		const confirmed = await confirmPrompt({
			title: "Are you sure?",
			description:
				"Are you sure you want to leave this world to load another one? Any unsaved progress will be lost.",
			confirmText: "Yes, load the other world",
		});

		if (!confirmed) return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	useGameClientStore.getState().setLoading(Loading.LOAD_WORLD);
	useLoadingScreenStore.getState().show();
	await GameLifeCycle.onDisposeWorld();
	await Jacy.initializePromise;

	// TODO: Relocate this logic to the backend.
	if (identifier === Jacy.tutorialWorldId) {
		if (!useAuthUserStore.getState().hasPlayedTutorial) {
			useAuthUserStore.getState().setHasPlayedTutorial(true);
		}
	}

	try {
		useLoadingScreenStore.getState().setText("Loading map");
		useLoadingScreenStore.getState().show();

		const [bundle, authUserData]: [IBundle, any, any] = await Promise.all([
			Jacy.api.getWorld(identifier),
			Jacy.api.getAuthUserData(identifier),
			Jacy.initDefaultAssets(),
		]);

		const access = authUserData.access;
		const inventory = authUserData.inventory.selector;

		GameMultiplayer.updateState({ isPublicServer: !isPrivateServer });
		Jacy.content.import(bundle);
		await Jacy.actions.init();
		GameLifeCycle.onBeforeLoadWorld();

		trackEvent("event", "load_world", { world_id: identifier });

		const engine = await Jacy.getEngine();
		await engine.loadWorld(Jacy.content, isPrivateServer ? access : false);
		GameLifeCycle.onAfterLoadWorld(engine.expose, inventory);

		const { Metrics } = engine.expose;
		Metrics.setContext("world", identifier);

		useGameClientStore.getState().setLoading(null);
		trackEvent("event", "visit_world", { world_id: identifier });

		if (isPrivateServer) {
			const servers = await GameMultiplayer.refetchOnlineServers();
			const otherHost = servers.find(
				(host) =>
					host.worldID === identifier &&
					host.username !== Jacy.state.user.username &&
					!host.dedicated,
			);

			if (!otherHost) return;

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: `Another collaborator is hosting this world`,
				description: `${otherHost.username} is hosting this world. Do you want to join them? If you continue, you will be hosting your own version of the world which may cause conflicts with the other host.`,
				confirmText: `Yes, join ${otherHost.username}`,
				cancelText: "Continue Anyway",
			});

			if (!confirmed) return;
			await GameMultiplayer.joinWorld(otherHost.serverID);
		}
	} catch (error: any) {
		if (error.isAxiosError) {
			if ([401, 404].includes(error.response?.status)) {
				Router.navigate("/404");
				return;
			}
		}

		useErrorStore.getState().setError(error);
		useGameClientStore.getState().setLoading(null);
	}
}

export async function importWorld(shouldClone?: boolean) {
	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to load a world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	GameLifeCycle.onBeforeNavigatePageToLoadWorld();
	await GameLifeCycle.onDisposeWorld();
	useGameClientStore.getState().setLoading(Loading.LOAD_WORLD);
	useLoadingScreenStore.getState().show();
	await Jacy.initializePromise;

	useLoadingScreenStore.getState().setText("Importing world");
	const [bundle] = await Promise.all([
		Jacy.content.serializer.import(shouldClone),
		Jacy.initDefaultAssets(),
	]);

	GameMultiplayer.updateState({ isPublicServer: false });
	Jacy.content.import(bundle as IBundle, { isImported: true });
	await Jacy.actions.init();
	GameLifeCycle.onBeforeLoadWorld();

	const engine = await Jacy.getEngine();
	await engine.loadWorld(Jacy.content, UserWorldAccess.OWNER);
	GameLifeCycle.onAfterLoadWorld(engine.expose);

	useGameClientStore.getState().setLoading(null);
}

export async function saveWorld(force = false) {
	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to save the world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	const world = Jacy.state.world.get();
	const isNew = !world.identifier;

	if (!Jacy.state.user.canSave) {
		useGameClientStore.getState().setNotification({
			type: "error",
			message: "User is not allowed to save the world",
		});
		useInventoryStore.getState().toggle(true);
		return;
	}

	const collaboratorHosts = await GameMultiplayer.getCollaboratorServers();
	const confirmPrompt = useConfirmPromptStore.getState().prompt;

	if (collaboratorHosts.length && !force) {
		const names = collaboratorHosts
			.map((host: { username: string }) => host.username)
			.join(", ");
		const verb = names.length ? "are" : "is";

		const confirmed = await confirmPrompt({
			title: "Are you sure?",
			description: `${names} ${verb} currently hosting the game, saving this world will overwrite the current version and might cause conflicts if ${names} ${verb} also hosting and saving their version.`,
			confirmText: "Yes, save world",
		});

		if (!confirmed) return;
	} else if (!force) {
		const confirmed = await confirmPrompt({
			title: "Saving the world",
			description:
				"Are you sure you want to save this world? While saving the world, do not close the tab, refresh the page, navigate to another page or load / join another world until the save is complete.",
			confirmText: "Yes, save world",
		});

		if (!confirmed) return;
	}

	useHubStore.getState().close();
	useInventoryStore.getState().close();
	useGameClientStore.getState().setLoading(Loading.SAVE_WORLD);

	try {
		await Jacy.api.isOnline();

		const inventory = useSelectorStore.getState().selector;
		const id = await Jacy.syncer.saveWorld();

		Jacy.api.saveUserInventory({ selector: inventory }, id).catch((error) => {
			useAlertDialogStore.getState().setAlert({
				title: "Failed to save inventory",
				message: `There was an error saving the inventory.\n${formatErrorMessage(error)}`,
			});
		});

		if (isNew) {
			trackEvent("event", "save_new_world", { world_id: id });

			const { BB, router, gbi } = (await useEngineStore.getState()
				.promise) as IExpose;

			const ibs = BB.world[router].ibs;

			if (ibs?.mode === gbi.ibs.NET_SERVER && id !== ibs.loginData.worldID) {
				ibs.loginData.worldID = id;
				ibs.master.close(); //force ibs to re-log in + re-auth with the new id
			}
		} else {
			trackEvent("event", "save_world", { world_id: id });
		}

		localStorage.setItem("worldId", id);

		Jacy.refreshContentState();
	} catch (error: any) {
		if (error?.message === Jacy.syncer.SAVE_ERROR.UNAUTHORIZED) {
			useAuthDialogStore.getState().openLogin(false);
		} else {
			console.error(error);
			useAlertDialogStore.getState().setAlert({
				title: "Failed to save the world",
				message: `There was an error saving the world.\n${formatErrorMessage(error)}`,
			});
		}
	}

	useGameClientStore.getState().setLoading(null);
}

export async function saveAndReloadWorld() {
	const loading = useGameClientStore.getState().loading;
	if (loading && [Loading.LOAD_WORLD, Loading.RELOAD_WORLD].includes(loading)) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to save and reload the world",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	if (GameMultiplayer.state.isHosting) {
		const confirmPrompt = useConfirmPromptStore.getState().prompt;
		const confirmed = await confirmPrompt({
			title: "Save and reload the world",
			description:
				"Are you sure you want to save this world? While saving the world, do not close the tab, refresh the page, navigate to another page or load / join another world until the save is complete.\nReloading the world will also stop hosting and kick out any players that are currently in this server.",
			confirmText: "Yes, save and reload the world",
		});

		if (!confirmed) return;
	}

	try {
		const hasReloaded = await reloadWorld(true);
		if (!hasReloaded) return;
	} catch (error) {
		if (error instanceof Error) {
			useErrorStore.getState().setError(error);
		}
	}

	saveWorld(true);
}

export async function leaveWorld() {
	const confirmed = await useConfirmPromptStore.getState().prompt({
		title: "Are you sure?",
		description:
			"You will lose any unsaved progress if you leave this server. This action cannot be undone.",
		confirmText: "Yes, leave world",
	});

	if (!confirmed) return false;

	loadDefaultWorld();
	return true;
}

export async function leaveGame() {
	const loading = useGameClientStore.getState().loading;
	if (loading) {
		useAlertDialogStore.getState().setAlert({
			title: "Failed to leave the game",
			message: "Please wait for the current operation to complete.",
		});
		return;
	}

	const confirmPrompt = useConfirmPromptStore.getState().prompt;
	const confirmed = await confirmPrompt({
		title: "Leave game?",
		description:
			"Leave game and return to the home page. Any unsaved progress will be lost.",
	});

	if (!confirmed) return;

	Router.navigate("/");

	const worldID = Jacy.state.world.identifier;
	const serverID = GameMultiplayer.state.serverID;

	trackEvent("event", "leave_world", {
		world_id: worldID,
		server_id: serverID,
		is_dedicated: false,
	});

	try {
		await GameLifeCycle.onDisposeWorld();
	} catch (error) {
		if (error instanceof Error) {
			useErrorStore.getState().setError(error);
		}
	}
}

export async function reloadWorld(force = false) {
	if (GameMultiplayer.state.isHosting && !force) {
		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Are you sure?",
			description:
				"You are currently hosting. This will stop hosting and kick out any players that are currently in this server.",
			confirmText: "Yes, reload the world",
		});

		if (!confirmed) return false;
	}

	const bundle = Jacy.content.export();

	if (bundle) {
		const validationResult = Jacy.content.validate(bundle);

		if (validationResult !== true) {
			Jacy.actions.setValidationsErrors(validationResult);
			return false;
		}
	}

	const isSaving = useGameClientStore.getState().loading === Loading.SAVE_WORLD;

	try {
		useInventoryStore.getState().close();
		useHubStore.getState().close();

		useLoadingScreenStore.getState().setText("Reloading map");
		useLoadingScreenStore.getState().show();
		if (!isSaving) {
			useGameClientStore.getState().setLoading(Loading.RELOAD_WORLD);
		}
		try {
			await GameMultiplayer.disconnect();
			Jacy.actions.reset();

			const engine = await Jacy.getEngine();
			const state = GameClientState.getCurrentState(engine.expose);

			await engine.reloadWorld(Jacy.content, Jacy.access);

			GameLifeCycle.onAfterLoadWorld(engine.expose);
			GameClientState.setCurrentState(engine.expose, state);
			if (!isSaving) {
				useGameClientStore.getState().setLoading(null);
			}
			useLoadingScreenStore.getState().hide();

			return true;
		} catch (error) {
			if (error instanceof Error) {
				useErrorStore.getState().setError(error);
			}
			useGameClientStore.getState().setLoading(null);
			useLoadingScreenStore.getState().hide();

			return false;
		}
	} catch (error) {
		Jacy.actions.setError(error);
		return false;
	}
}

export async function loadDefaultWorld() {
	await startNewWorld({
		worldTemplateOptions: BlankWorldTemplate.options,
		force: true,
	});
}
