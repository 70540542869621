import { cn } from "@lib/helpers/cn";
import { CustomCSSProps, GeneralInputs, GeneralStyles } from "@stores/custom-ui";
import React from "react";
import { constants } from "rest-client";

interface IProps {
	styles: GeneralStyles;
	config: GeneralInputs;
	isPreview?: boolean;
}

export function Leaderboard({ styles, config }: IProps) {
	const stateData = config?.stateData?.value ?? config?.stateData?.defaultValue;
	const styleConfiguration = constants.customUI.formatStyles(
		styles,
		"leaderboard-local",
	) as CustomCSSProps;

	const textShadowConfig = {
		"--text-shadow-color": styleConfiguration["--leaderboard-local-textShadowColor"],
		"--text-stroke-color": styleConfiguration["--leaderboard-local-textStrokeColor"],
	} as React.CSSProperties;

	return (
		<div
			className={cn(
				"relative w-full max-w-xl",
				"m-[var(--leaderboard-local-margin)]",
				"lg:m-[var(--leaderboard-local-margin-lg)]",
				// position
				"!top-[var(--leaderboard-local-position-top)]",
				"!left-[var(--leaderboard-local-position-left)]",
				"!bottom-[var(--leaderboard-local-position-bottom)]",
				"!right-[var(--leaderboard-local-position-right)]",
			)}
			style={styleConfiguration}
			hidden={!open}
		>
			<div
				className={cn(
					"flex w-full max-w-2xl flex-col rounded-md bg-gray-900/75 p-4 opacity-75",
					"!font-[var(--leaderboard-local-fontWeight)]",
					"lg:!font-[var(--leaderboard-local-fontWeight-lg)]",
					"rounded-[var(--leaderboard-local-borderRadius)]",
					"lg:rounded-[var(--leaderboard-local-borderRadius-lg)]",
					"bg-[var(--leaderboard-local-backgroundColor)]",
					"lg:bg-[var(--leaderboard-local-backgroundColor-lg)]",
					"p-[var(--leaderboard-local-padding)]",
					"lg:p-[var(--leaderboard-local-padding-lg)]",
					"font-[family-name:var(--leaderboard-local-fontFamily)]",
					"lg:font-[family-name:var(--leaderboard-local-fontFamily-lg)]",
					"text-[length:var(--leaderboard-local-fontSize)]",
					"lg:text-[length:var(--leaderboard-local-fontSize-lg)]",
					"text-[var(--leaderboard-local-color)]",
					"lg:text-[var(--leaderboard-local-color-lg)]",
				)}
				style={styleConfiguration}
			>
				<div className="flex justify-center">
					<h2
						className={cn(
							"text-center text-2xl font-medium",
							"!font-stroke-[var(--leaderboard-local-textStroke)]",
							"!text-shadow-[var(--leaderboard-local-textShadow)]",
						)}
						style={textShadowConfig}
					>
						Leaderboard
					</h2>
				</div>

				<div className="flex flex-col gap-2">
					{stateData && Array.isArray(stateData) && (
						<table
							className={cn(
								"w-full",
								"!font-stroke-[var(--leaderboard-local-textStroke)]",
								"!text-shadow-[var(--leaderboard-local-textShadow)]",
							)}
							style={textShadowConfig}
						>
							<thead className="text-center text-sm ">
								<tr>
									<th
										scope="col"
										className={cn(
											"w-full whitespace-nowrap py-3.5 pl-0 pl-4 pr-3 text-left",
											"!font-stroke-[var(--leaderboard-local-textStroke)]",
											"!text-shadow-[var(--leaderboard-local-textShadow)]",
										)}
									>
										Rank
									</th>
									<th
										scope="col"
										className={cn(
											"w-full whitespace-nowrap py-3.5 pl-0 pl-4 pr-3 text-left",
											"!font-stroke-[var(--leaderboard-local-textStroke)]",
											"!text-shadow-[var(--leaderboard-local-textShadow)]",
										)}
									>
										Player
									</th>
									<th
										scope="col"
										className={cn(
											"whitespace-nowrap px-3 py-3.5",
											"!font-stroke-[var(--leaderboard-local-textStroke)]",
											"!text-shadow-[var(--leaderboard-local-textShadow)]",
										)}
									>
										Score
									</th>
								</tr>
							</thead>
							<tbody className="text-center ">
								{stateData.map((player, index) => {
									const defaultPlayer = player as {
										username: string;
										value: number;
									};

									return (
										<tr key={defaultPlayer.username}>
											<td className="whitespace-nowrap text-left">{index + 1}</td>
											<td className="whitespace-nowrap text-left">
												{defaultPlayer.username}
											</td>
											<td className="whitespace-nowrap">
												{defaultPlayer.value ?? 0}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</div>
	);
}
