import {
	useHubStore,
	useWorldPreviewStore,
	useConfirmPromptStore,
	useGeneralPromptStore,
	usePromptStore,
	useErrorStore,
	useHubWorldModal,
	useCreateWorldModal,
	useImportBlockPackModal,
} from "@stores/dialogs";
import { useAlertDialogStore } from "@stores/dialogs/alert-dialog";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { useChatStore } from "@stores/chat";
import { useControlsStore } from "@stores/controls";
import { useBlockWrenchStore } from "@stores/wrench";
import { useCameraToolStore } from "@stores/camera-tool";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { useJacyEnvironmentPresetEditorStore } from "@stores/jacy/environment-preset-editor";
import { useJacyBlockEditorStore } from "@stores/jacy/block-editor";
import { useJacyCharacterEditorStore } from "@stores/jacy/character-editor";

// Note: Prevent adding close method that has side effects like updating the pointer lock.
export function closeAllModal() {
	useHubStore.getState().close();
	useWorldPreviewStore.getState().close();
	useConfirmPromptStore.getState().closeDialog();
	useGeneralPromptStore.getState().cancel();
	usePromptStore.getState().close();
	useErrorStore.getState().close();
	useAlertDialogStore.getState().close(false);
	useHubWorldModal.getState().close();
	useCreateWorldModal.getState().close();
	useInventoryStore.getState().close();
	useChatStore.getState().close();
	useControlsStore.getState().close();
	useBlockWrenchStore.getState().close(false);
	useCameraToolStore.getState().setOpen(false);
	useJacyEnvironmentPresetEditorStore.getState().close();
	useJacyAvatarEditorStore.getState().close(false);
	useJacyBlockEditorStore.getState().close();
	useJacyCharacterEditorStore.getState().close();
	useImportBlockPackModal.getState().close();
}
