import { Engine } from "@stores/bb";

const DEFAULT_NAME = "Block Group";

export async function generateGroupName() {
	const { BB, gbi } = new Engine();
	const groups = (await BB?.world?.scene.getGroupList(gbi.base.GROUP_ALL)) ?? [];
	let generatedName;
	let counter = groups.length + 1;

	do {
		const testGeneratedName = `${DEFAULT_NAME} ${counter}`;
		const alreadyExists = groups.includes(testGeneratedName);

		if (alreadyExists) counter++;
		else generatedName = testGeneratedName;
	} while (generatedName === undefined);

	return generatedName;
}
