import { IAssetKey, IBlockChangeset, IJacyContent } from "jacy";
import { JacyActions } from "../JacyActions";

import { Engine } from "@stores/bb";
import { useJacyBlockEditorStore } from "@stores/jacy/block-editor";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useSelectorStore } from "@stores/hud/selector";
import { useEventsEditorStore } from "@stores/events-editor";
import { useInventoryStore } from "@stores/dialogs/inventory";

export class JacyBlockActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	newBlock() {
		try {
			if (!this.#state.user.canSave) {
				throw new Error("User is not allowed to create a new block.");
			}

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "InventoryNewBlock" });

			useJacyBlockEditorStore.getState().newBlock();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editBlock(pk: IAssetKey) {
		try {
			if (!this.#state.user.canEdit) {
				throw new Error("User is not allowed to edit the block.");
			}

			const block = this.#state.blocks.get(pk);

			if (!block) {
				this.#actions.setError("Block not found");
				return;
			}

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "InventoryEditBlock" });

			useJacyBlockEditorStore.getState().editBlock(block);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	createBlock() {
		try {
			const block = useJacyBlockEditorStore.getState().getBlock();
			const isTexture = useJacyBlockEditorStore.getState().isTexture;

			const createdBlock = this.#state.blocks.create(block, isTexture);

			useSelectorStore.getState().refresh();
			useJacyRerenderStore.getState().forceRerenderBlocks();
			useInventoryStore.getState().setSelectedBlockId(createdBlock.pk);
			useJacyBlockEditorStore.getState().close();
			useEventsEditorStore.getState().clear();

			this.#actions.updateChangeset<IBlockChangeset>(
				createdBlock.pk,
				"create",
				createdBlock,
			);

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: "InventoryCreateBlock",
				properties: {
					events: Object.entries(createdBlock.events?.nodes ?? {}).length > 0,
				},
			});

			this.#actions.setSuccess(
				`Block "${createdBlock.displayName}" created successfully.`,
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	updateBlock() {
		try {
			const block = useJacyBlockEditorStore.getState().getBlock();
			const isTexture = useJacyBlockEditorStore.getState().isTexture;

			const updatedBlock = this.#state.blocks.update(block.pk, block, isTexture);

			useJacyRerenderStore.getState().forceRerenderBlocks();
			useInventoryStore.getState().setSelectedBlockId(updatedBlock.pk);
			useJacyBlockEditorStore.getState().close();
			useEventsEditorStore.getState().clear();

			this.#actions.updateChangeset<IBlockChangeset>(
				updatedBlock.pk,
				"update",
				updatedBlock,
			);

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: "InventoryUpdateBlock",
				properties: {
					events: Object.entries(updatedBlock.events?.nodes ?? {}).length > 0,
				},
			});

			this.#actions.setSuccess(
				`Block "${updatedBlock.displayName}" updated successfully.`,
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteBlock() {
		try {
			const block = useJacyBlockEditorStore.getState().getBlock();
			const isCreate = useJacyBlockEditorStore.getState().isCreate;

			if (isCreate) {
				useJacyBlockEditorStore.getState().close();
				return;
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Block",
				description: `Are you sure you want to delete "${block.displayName}" block? You will lose all the data associated with this block.`,
				confirmText: "Yes, delete this block",
			});

			if (!confirmed) return;

			this.#state.blocks.delete(block.pk);

			this.#actions.updateChangeset<IBlockChangeset>(block.pk, "delete");

			useSelectorStore.getState().refresh();
			useJacyRerenderStore.getState().forceRerenderBlocks();
			useJacyBlockEditorStore.getState().close();
			useEventsEditorStore.getState().clear();

			this.#actions.setSuccess(`Block "${block.displayName}" deleted successfully.`);
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
