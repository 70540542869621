import React from "react";

import { useAuthDialogStore } from "@stores/auth-user";
import { LOADING_STATE, useAuthUserStore } from "@stores/auth-user";

import { Dialog } from "@components/ui/Dialog";
import { AlertDialog } from "@components/ui/AlertDialog";
import { ErrorNote } from "@components/ui/ErrorNote";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";

function DialogContainer({ children, isAlert = false }) {
	const open = useAuthDialogStore((state) => state.open === "login");
	const openLogin = useAuthDialogStore((state) => state.openLogin);
	const close = useAuthDialogStore((state) => state.close);

	if (isAlert) {
		return (
			<AlertDialog.Root
				open={open}
				onOpenChange={(value) => (value ? openLogin() : close())}
			>
				<AlertDialog.Content
					overlayClassname="!bg-opacity-80"
					className="z-40 w-full max-w-lg"
				>
					<div className="h-full overflow-y-auto rounded border-b border-gray-400 bg-white px-8 py-6 shadow-xl">
						<div className="flex justify-between gap-8">
							<AlertDialog.Title className="text-2xl font-semibold">
								Sign in
							</AlertDialog.Title>
						</div>

						{children}
					</div>
				</AlertDialog.Content>
			</AlertDialog.Root>
		);
	}

	return (
		<Dialog.Root
			open={open}
			onOpenChange={(value) => (value ? openLogin() : close())}
		>
			<Dialog.Content
				overlayClassname="!bg-opacity-80"
				className="z-50 w-full max-w-lg"
			>
				<div className="h-full overflow-y-auto rounded border-b border-gray-400 bg-white px-8 py-6 shadow-xl">
					<div className="flex justify-between gap-8">
						<Dialog.Title className="text-2xl font-semibold">Sign in</Dialog.Title>
					</div>

					{children}
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
}

export function SignInDialog() {
	const isAlert = useAuthDialogStore((state) => state.isAlert);
	const openRegister = useAuthDialogStore((state) => state.openRegister);
	const login = useAuthUserStore((state) => state.login);
	const loadingStatus = useAuthUserStore((state) => state.loading);
	const error = useAuthUserStore((state) => state.error);

	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");

	const loading = loadingStatus === LOADING_STATE.login;

	const submit = async (evt) => {
		evt.preventDefault();
		const data = await login({ email, password });

		if (data) {
			setEmail("");
			setPassword("");
		}
	};

	return (
		<DialogContainer isAlert={isAlert}>
			<form className="my-4" onSubmit={submit}>
				<ErrorNote message={error} />

				<div className="my-4">
					<label htmlFor="email" className="text-base text-gray-500">
						Email
					</label>
					<Input
						type="email"
						id="email"
						name="email"
						autoComplete="email"
						className="my-1 w-full"
						value={email}
						onChange={(evt) => setEmail(evt.target.value)}
						autoFocus
						required
					/>
				</div>
				<div className="my-4">
					<label htmlFor="password" className="text-base text-gray-500">
						Password
					</label>
					<Input
						type="password"
						id="password"
						name="password"
						autoComplete="current-password"
						className="my-1 w-full"
						value={password}
						onChange={(evt) => setPassword(evt.target.value)}
						required
					/>
				</div>

				<Button isSubmit fullWidth disabled={loading}>
					{loading === "sign-in" ? "Signing in..." : "Sign in"}
				</Button>
			</form>

			<hr className="my-4 h-[2.5px] w-full bg-gray-200" />

			<p className="mb-2 mt-8 text-center text-sm text-gray-500">
				Don't have an account?&nbsp;
				<button type="button" className="text-link" onClick={openRegister}>
					Create one
				</button>
				.
			</p>
		</DialogContainer>
	);
}
