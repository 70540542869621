import { constants } from "rest-client";
import { lib, type IPlayer, type ISelectorSlots } from "jacy";
import { Jacy } from "@jacy-client";

import { useAuthUserStore } from "@stores/auth-user";
import type { IExpose } from "@lib/types/expose";
import { closeAllModal } from "@lib/helpers/closeAllModal";
import { useHelpersStore } from "@stores/helpers";
import { useTestWorldStore } from "@stores/test-world";
import { useLoadingScreenStore } from "@stores/loading-screen";
import { useSelectorStore } from "@stores/hud/selector";
import { useGameStore } from "@stores/game";
import { usePermissionStore } from "@stores/player";
import { useOnboardingStore } from "@stores/onboarding";
import { useInventoryStore } from "@stores/dialogs/inventory";
import { initEngine } from "@lib/setup/setup-engine";
import { useCustomUIStore } from "@stores/custom-ui";
import { useGameHudStore } from "@stores/game-hud";
import { Engine } from "@stores/bb";
import { Loading, useGameClientStore } from "@stores/game-client/game-client";
import { useSettingsStore } from "@stores/settings";
import { useCreateWorldModal, useErrorStore } from "@stores/dialogs";

import * as GameClient from "./GameClient";
import * as GameMultiplayer from "./GameMultiplayer";
import * as Router from "./Router";

export const state = {
	isFirstLoaded: true,
};

export async function onChangePage() {
	Jacy.content.reset();
	Jacy.actions.reset();

	if (Router.isPlayPage()) return;

	const engine = await Jacy.getEngine();
	const { BB, router } = engine.expose;
	BB[router].endWorld();
}

export function onCanvasLoaded() {
	if (globalEnv.ALLOW_MOBILE === "false") return;

	initEngine();
}

export async function onEngineLoaded(engine: IExpose) {
	useAuthUserStore.getState().syncFeatureFlags();

	if (!Router.isPlayPage()) {
		if (GameClient.IS_PRODUCTION) {
			console.logChungusWelcome(
				"VERY VERY BIG WARNING: If a stranger tells you to copy and paste some dubious code here...DO NOT LISTEN!!!!",
			);
		}

		return;
	}
	const { worldID, serverID, matchmake } = Router.getParams();

	const prevWorldId = localStorage.getItem("worldId");
	const prevServerId = localStorage.getItem("serverId");

	const loadWorld = async () => {
		if (worldID) {
			try {
				if (matchmake) {
					await GameMultiplayer.joinPublishedWorld(worldID);
				} else {
					await GameClient.loadWorld(worldID);
				}
			} catch (ex) {
				console.error(ex);

				localStorage.setItem("worldId", "");
				localStorage.setItem("serverId", "");

				GameClient.loadDefaultWorld();
			}
		} else {
			GameClient.loadDefaultWorld();
		}
	};

	if (serverID) {
		const isNotPreviouslyHosted = serverID !== prevServerId || !prevWorldId;

		if (isNotPreviouslyHosted || engine.gbi.debug.getCompilerArg("debug"))
			await GameMultiplayer.joinWorld(serverID);
		else {
			await loadWorld();
		}
	} else if (worldID) {
		await loadWorld();
	} else {
		GameClient.loadDefaultWorld();
	}
}

export function onEngineLoadingFailed(error: unknown) {
	if (error instanceof Error) {
		useErrorStore.getState().setError(error);
	}
}

export async function onDisposeWorld() {
	closeAllModal();

	useSettingsStore.getState().setShowReloadPanel(false);
	useGameHudStore.getState().clearHudUI();
	useCustomUIStore.getState().clearCustomUI();
	useCreateWorldModal.getState().resetBlockPack();

	localStorage.setItem("worldId", "");
	localStorage.setItem("serverId", "");

	const url = new URL(window.location.href);
	url.searchParams.delete("game");
	url.searchParams.delete("world");
	Router.replaceHistory(url.pathname + url.search);

	// No need to wait for the engine to load, only clear context if engine exists.
	const { Metrics } = new Engine();

	if (Metrics) {
		Metrics.clearContext("world");
	}

	await GameMultiplayer.disconnect();

	Jacy.content.reset();
	Jacy.actions.reset();

	Jacy.refreshContentState();
	Jacy.refreshUserState();
}

export function onPageUnload(e: BeforeUnloadEvent) {
	const loading = useGameClientStore.getState().loading;

	if (loading !== Loading.SAVE_WORLD) return;

	e.preventDefault();
	// Included for legacy support, e.g. Chrome/Edge < 119
	e.returnValue = true;
}

export async function onBeforeNavigatePageToLoadWorld() {
	closeAllModal();

	// give time to close all modals before redirecting page.
	await lib.helpers.time.delay(10);

	if (!Router.isPlayPage()) {
		Router.navigate("/play");
	}
}

export function onBeforeLoadWorld() {
	if (Jacy.content.state.world.identifier) {
		localStorage.setItem("worldId", Jacy.content.state.world.identifier);
	}

	useHelpersStore.getState().stopMonitoring();
	useInventoryStore.getState().clear();
	useTestWorldStore.getState().reset();

	Jacy.refreshContentState();

	useLoadingScreenStore.getState().show();
}

export function onAfterLoadWorld(engine: IExpose, inventory?: ISelectorSlots) {
	Jacy.refreshContentState();

	useHelpersStore.getState().startMonitoring();
	useSelectorStore.getState().init(inventory);
	useGameStore.getState().onLoadWorld();

	unlockAudio(engine);

	const player = engine.BB.world[engine.client].camera.target;
	usePermissionStore.getState().setPermissions({
		canBuild: player.canBuild,
		canInteract: player.canInteract,
		canCrouch: player.canCrouch,
		canFly: player.canFly,
		canForceRespawn: player.canForceRespawn,
		fallDamage: player.fallDamage,
		healthRegenRate: player.healthRegenRate,
		jumpVelocity: player.jumpVelocity,
		pov: player.pov,
		pvpDamage: player.pvpDamage,
		speedSprintMultiplier: player.speedSprintMultiplier,
		speedWalk: player.speedWalk,
		canChangeCamera:
			player.cameraSettings.def.camera.minDst !==
			player.cameraSettings.def.camera.maxDst,
	});

	if (state.isFirstLoaded) {
		state.isFirstLoaded = false;
		useOnboardingStore.getState().start();
	}

	if (Jacy.state.isPrivateServer && Jacy.state.isServer) {
		const initialMode = Jacy.state.worldData.mode;
		Jacy.actions.worldData.toggleMode(
			initialMode ?? constants.world.WORLD_MODE.SANDBOX,
			true,
		);
	}

	GameMultiplayer.refetchOnlineServers();

	console.logChungusWelcome(
		"VERY VERY BIG WARNING: If a stranger tells you to copy and paste some dubious code here...DO NOT LISTEN!!!!",
	);
}

export function onAfterHostOrJoinWorld(serverID: string, worldID?: string | null) {
	const url = new URL(window.location.href);
	url.searchParams.set("game", serverID);

	if (worldID) {
		url.searchParams.set("world", worldID);
	}

	Router.pushHistory(url.pathname + url.search);

	localStorage.setItem("serverId", serverID);

	GameMultiplayer.refetchOnlineServers();
}

export function onPlayerJoined(player: IPlayer) {
	const players = GameMultiplayer.state.players;
	GameMultiplayer.setPlayers(players.concat(player));
	GameMultiplayer.refetchOnlineServers();
}

export function onPlayerLeave(peerID: string) {
	const players = GameMultiplayer.state.players.filter((p) => p.peerID !== peerID);
	GameMultiplayer.setPlayers(players);
	GameMultiplayer.refetchOnlineServers();
}

// Note: relocate to jacy audio actions
function unlockAudio(engine: IExpose) {
	const { BB, client } = engine;
	if (!BB.world[client]) return;

	const unlockAudio = () => {
		if (BB[client].input[client].poll.isMobileBrowser()) {
			BB.world[client].unlockAudio();
		}

		Jacy.actions.backgroundMusic.playMusic();
		document.removeEventListener("click", unlockAudio);
	};

	document.addEventListener("click", unlockAudio);
}
