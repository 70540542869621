import { SkyboxSide, ISkyboxChangeset, IJacyContent } from "jacy";
import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacySkyboxActions {
	#actions: JacyActions;
	#content: IJacyContent;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#content = actions.content;
		this.#state = actions.content.state;
	}

	async uploadSkybox(side: SkyboxSide, initialFile: File) {
		try {
			const [pk, url, file] = await this.#state.skybox.updateSide(side, initialFile);

			useJacyRerenderStore.getState().forceRerenderSkybox();
			this.#actions.setSuccess(`Skybox side ${side} has been updated successfully.`);
			this.#actions.updateChangeset<ISkyboxChangeset>(pk, "update", {
				[`${side}File`]: file,
				[`${side}Url`]: url,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
