import { create } from "zustand";

export const useReviveStore = create((set) => ({
	open: false,
	timeLeft: null,

	// Handlers
	toggle: (open) => set({ open }),
	setTimeLeft: (timeLeft) => set({ timeLeft }),
}));

export default {
	useRevive: useReviveStore.getState,
};
