import { IJacyContent, IAssetKey } from "jacy";

import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyWorldTagsActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	createWorldTag(tagName: string) {
		try {
			const newTag = this.#state.worldTags.create(tagName);
			useJacyRerenderStore.getState().forceRerenderWorldTags();
			this.#actions.setSuccess(`World Tag "${newTag.name}" created successfully.`);
			this.#actions.updateChangeset(newTag.pk, "create", newTag);

			return newTag.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	delete(pk: IAssetKey) {
		try {
			this.#state.worldTags.delete(pk);
			useJacyRerenderStore.getState().forceRerenderWorldTags();
			this.#actions.setSuccess(`World Tag deleted successfully.`);
			this.#actions.updateChangeset(pk, "delete");
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
