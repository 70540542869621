import { lib, IAssetKey, IJacyContent, JacyCore, IAudioChangeset } from "jacy";
import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyAudioActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	uploadAudio(file: File) {
		try {
			const renamedAudioFile = lib.helpers.files.renameFile(
				file,
				`${lib.constants.files.AUDIO_FILE_PREFIX}${lib.helpers.files.formatFileName(file.name)}-${JacyCore.generateUUID()}`,
			);
			const audio = this.#state.audios.create(renamedAudioFile);
			useJacyRerenderStore.getState().forceRerenderAudios();
			this.#actions.updateChangeset<IAudioChangeset>(audio.pk, "create", {
				file: renamedAudioFile,
			});
			this.#actions.setSuccess("Successfully created audio.");
			return audio.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	deleteAudio(pk: IAssetKey) {
		try {
			this.#state.audios.delete(pk);
			useJacyRerenderStore.getState().forceRerenderAudios();
			this.#actions.updateChangeset<IAudioChangeset>(pk, "delete");
			this.#actions.setSuccess("Successfully deleted audio.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
