import { create } from "zustand";
import { Engine } from "./bb";

export const WEAPON_TYPE = {
	melee: "melee",
	ranged: "ranged",
};

export const useWeaponStore = create((set, get) => ({
	type: null,
	needsReload: false,
	isReloading: false,
	ammoCount: 0,
	magazineSize: 0,

	init: (weapon) => {
		if (weapon == null) {
			set({ type: null });
			return;
		}

		if (weapon.type === WEAPON_TYPE.ranged) {
			set({
				type: WEAPON_TYPE.ranged,
				magazineSize: weapon.magazineSize,
			});
			get().setAmmoCount(weapon.ammoCount);
		} else if (weapon.type === WEAPON_TYPE.melee) {
			set({ type: WEAPON_TYPE.melee });
		}
	},
	setIsReloading: (isReloading) => {
		set({ isReloading });
	},
	setAmmoCount: (ammoCount) => {
		if (get().type !== WEAPON_TYPE.ranged) return;

		const needsReload = ammoCount / get().magazineSize <= 0.2;
		set({ needsReload, ammoCount });
	},
	reload: () => {
		const { BB, client } = new Engine();
		BB[client].input.playerDoReload = true;
	},
	toggleIronSights: () => {
		const { BB, client } = new Engine();
		BB[client].input.playerIsIronSights = !BB[client].input.playerIsIronSights;
	},
	pullTrigger: (enable) => {
		const { BB, client } = new Engine();

		BB[client].input.playerIsPullingTrigger = enable;
		if (enable) BB[client].input.playerDoItemPrimary = true;
	},
}));

export default {
	useWeapon: useWeaponStore.getState,
};
