import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { Engine } from "@stores/bb";
import { IEditorClient } from "@lib/types/nodes/node-editor";
import { INotification } from "@lib/types/general";
import { IEvent } from "@lib/types/events";

let timeoutID: NodeJS.Timeout;

type IEventsEditorState = {
	open: boolean;
	zoom: number;
	fullScreenEnabled: boolean;
	events: IEvent | null;
	modified: boolean;
	notification: INotification | null;
	client: IEditorClient | null;
	editorType?: "blocks" | "characters";

	setOpen: (open: boolean) => void;
	init: (client: IEditorClient) => void;
	setModified: (modified: boolean) => void;
	setEvents: (events?: IEvent | null) => void;
	setZoom: (zoom: number) => void;
	setFullScreenEnabled: (fullScreenEnabled: boolean) => void;
	setNotification: (notification: INotification, autoclear?: boolean) => void;
	toggleFullscreen: () => Promise<void>;
	refocus: () => void;
	clear: () => void;
};

/**
 * Usage Notes:
 * - Please call refocus when the Events Tab or the Editor is in view.
 */
export const useEventsEditorStore = create(
	subscribeWithSelector<IEventsEditorState>((set, get) => ({
		// Settings
		open: false,
		zoom: 1,
		fullScreenEnabled: false,

		events: null, // Will trigger an update to the editor when set
		modified: false,

		notification: null,
		client: null,

		// Handlers
		setOpen: (open) => {
			set({ open });

			if (open) {
				get().refocus();
			}
		},
		init: (client) => set({ client }),
		setModified: (modified) => set({ modified }),
		setEvents: (events) => {
			const { BB, gbi } = new Engine();
			if (!BB.world || !gbi) return;

			const defaultEvents = {
				id: gbi.base.baseConst.rete.VERSION,
				nodes: {},
				connections: [],
			};

			set({ events: JSON.deepCopy(events ?? defaultEvents) as IEvent });
		},
		setZoom: (zoom: number) => set({ zoom }),
		setFullScreenEnabled: (fullScreenEnabled: boolean) => set({ fullScreenEnabled }),
		setNotification: (notification: INotification, autoclear: boolean = true) => {
			if (timeoutID) {
				clearTimeout(timeoutID);
			}

			if (notification != null && autoclear) {
				timeoutID = setTimeout(() => set({ notification: null }), 5000);
			}

			set({ notification });
		},

		// Actions
		toggleFullscreen: async () => {
			const fullScreenEnabled =
				await get().client?.customPlugins.zoom.toggleFullscreen();
			set({ fullScreenEnabled: !!fullScreenEnabled });
		},
		refocus: () => {
			get().client?.focus();
		},
		clear: () => {
			set({ client: null, events: null, modified: false, open: false });
		},
		setEditorType: (editorType: "blocks" | "characters") => {
			set({ editorType });
		},
	})),
);

export default {
	useEventsEditor: useEventsEditorStore.getState,
};
