import { X, WarningCircle } from "@components/icons";
import { useAuthDialogStore } from "@stores/auth-user";

type IProps = {
	message: string;
	className?: string;
	dismiss?: () => void;
};

export function ErrorNote({ message, className = "", dismiss }: IProps) {
	const openLogin = useAuthDialogStore((state) => state.openLogin);

	if (message && message.length > 0) {
		return (
			<div
				className={
					"my-1 flex items-center gap-2 rounded-md bg-red-700 p-3 " + className
				}
			>
				<WarningCircle className="h-max w-auto text-white" aria-hidden="true" />
				<p className="my-0.5 flex-1 text-base text-white">{message}</p>
				{dismiss && (
					<button
						type="button"
						className="rounded bg-transparent px-1.5 py-1 text-white hover:bg-red-600 focus:bg-red-600"
						onClick={dismiss}
					>
						<X className="size-5" aria-hidden="true" />
						<span className="sr-only">Dismiss</span>
					</button>
				)}
				{message === "Not Authorized" && (
					<button
						onClick={() => openLogin(false)}
						className="rounded bg-white px-1.5 py-1 text-sm font-semibold text-red-600 hover:bg-gray-100 focus:bg-gray-100"
					>
						Sign In
					</button>
				)}
			</div>
		);
	}

	return null;
}
