/**
 * Checks whether all given values are not empty.
 *
 * @param state array of values
 * @returns boolean
 */
export function validateFilled(state) {
	return state.some((item) => {
		if (typeof item === "string") return item.length > 0;
		return item != null;
	});
}
