//DO NOT NAME THIS FILE ANALYTICS.JS - BLOCKER EXTENSIONS REFUSE TO LOAD IT
import ReactGA from "react-ga4";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";

const POSTHOG_KEY = "phc_WmjmrFOwWBsG1eQZLbmX9WZJYFeuJ3jLgA3gTCNtbnC";
const GA_MEASUREMENT_ID = "G-E0V8H6B5FF"; // tracks google analytics & google ads

/**
 * Initializes Analytics.
 * @param {id} obj.userId - User's unique id (note: to be deprecated)
 * @param {id} obj.playerId - User's player id
 */
export function initializeAnalytics(obj) {
	if (!isProductionEnvironment()) return;

	ReactGA.initialize(GA_MEASUREMENT_ID, obj);

	// api_host = https://posthog.com/docs/advanced/proxy/cloudfront
	posthog.init(POSTHOG_KEY, { api_host: "https://d1o661619yvab9.cloudfront.net" });
}

/**
 * Used for sending analytics events. See https://github.com/codler/react-ga4 for google analytics.
 * @param {string} type The type of event. Can be 'pageview' or 'event'
 * @param {string} action User defined name of event
 * @param {object} default_data A key, value pair containing the default action label and value. e.g. default: {"world_id": 25}.
 * @param {object} additional_data A JSON object containing additional data to send with the event
 */
export function trackEvent(type, action, default_data = {}, additional_data = {}) {
	if (!isProductionEnvironment()) return;

	if (type === "event") {
		sendEvent(action, default_data, additional_data);
	} else {
		console.error("Event type " + type + " is not recognised!");
	}
}

function sendEvent(action, default_data, additional_data) {
	const GA_OBJ = { category: action, action: action };
	let POSTHOG_OBJ = {};

	if (Object.keys(default_data).length) {
		const default_val = Object.values(default_data)[0];

		// add 'label' to GA with default value
		GA_OBJ.label = default_val;

		// create posthog json object
		POSTHOG_OBJ = {
			...default_data,
			...additional_data,
		};
	}

	ReactGA.event(GA_OBJ);
	posthog.capture(action, POSTHOG_OBJ);
}

/**
 * Identify the user for tracking events
 * @param {number} user_id -   User's uniqque id
 * @param {object} data    -   Onject containing additional user properties
 */
export function identifyUser(user_id, user_data = {}) {
	if (!isProductionEnvironment()) return;

	ReactGA.set({ userId: user_id });

	let USER_OBJ = {};
	if (Object.keys(user_data).length) {
		USER_OBJ = user_data;
	}

	Sentry.setUser({
		email: USER_OBJ.email,
		username: USER_OBJ.username,
		id: user_id,
	});

	posthog.identify(
		user_id, // User's unique identifier
		USER_OBJ, // Additional user properties
	);
}

function isProductionEnvironment() {
	return location.host === "jamango.io";
}

/**
 * @param {import("@engine/base/metrics/Metrics").MetricsData} metrics
 */
export function handleEngineMetric({ name, unit, value, dimensions, properties }) {
	sendEvent(name, { [`${name}-${unit}`]: value, ...dimensions }, properties);
}
