import React from "react";

const SIZES = {
	xs: "h-3",
	sm: "h-3.5",
	base: "h-4",
	lg: "h-5",
};

export function Skeleton({ size = "base", className = "", ...props }) {
	return (
		<div
			className={
				"my-1 animate-pulse rounded bg-gray-200 " + SIZES[size] + " " + className
			}
			{...props}
			aria-hidden="true"
		/>
	);
}
