import { IUser } from "jacy";
import { create } from "zustand";

type IJacyUserState = Partial<IUser> & {
	featureFlags: string[];
	isGuest: boolean;
	isOwner: boolean;
	isCollaborator: boolean;
	isCreator: boolean;

	hasFullAccess: boolean;
	canEdit: boolean;
	canDelete: boolean;
	canSave: boolean;
	canSaveWorld: boolean;

	clear: () => void;
};

const DEFAULT_STATE = {
	playerId: undefined,
	username: undefined,
	displayPhoto: undefined,
	featureFlags: [],

	isGuest: true,
	isOwner: true,
	isCollaborator: false,
	isCreator: true, // is owner or collaborator

	hasFullAccess: false,
	canEdit: false,
	canDelete: false,
	canSave: false,
	canSaveWorld: false,
};

export const useJacyUserStore = create<IJacyUserState>((set) => ({
	...DEFAULT_STATE,
	clear: () => set(DEFAULT_STATE),
}));
