import { create } from "zustand";
import { constants } from "rest-client";
import { Engine } from "./bb";

type State = {
	version: number;
	mode: string;
	error?: unknown;
	worldTags?: unknown;
	cpu?: { breakdown: { name: string; value: number }[]; total: number };
	experimental?: unknown;
};

type Actions = {
	setVersion: (version: State["version"]) => void;
	setWorldTags: (tags: State["worldTags"]) => void;
	setCpu: (tags: State["cpu"]) => void;
	refreshCpu: () => Promise<void>;
};

export const useWorldStore = create<State & Actions>()((set) => ({
	version: 0,
	mode: constants.world.WORLD_MODE.SANDBOX,

	// Handlers
	setVersion: (version) => set({ version }),
	setWorldTags: (worldTags) => set({ worldTags }),
	setCpu: (cpu) => set({ cpu }),
	refreshCpu: async () => {
		const { BB } = new Engine();
		set({ cpu: await BB.world.scene.getMapHeatScore() });
	},
}));

export default {
	useWorld: useWorldStore.getState,
};
