import { create } from "zustand";

import { useControlsStore, useKeyboardStore } from "./controls";
import { useAlertDialogStore } from "./dialogs/alert-dialog";
import { useItemStore } from "./hud/item";

interface IGameHudState {
	zenMode: boolean;
	playerPosition: { x: number; y: number; z: number; angle: number };
	showPlayerContextualInfo: boolean;
	hitBlock?: { x: number; y: number; z: number };
	hitBlockTypeName?: string;
	hitBlockTypeDisplay?: string;
	hitBlockShape?: number;
	subGrid?: {
		scale: number;
		position: { x: number; y: number; z: number };
		locked: boolean;
	};
	corner1?: { x: number; y: number; z: number };
	corner2?: { x: number; y: number; z: number };

	setGameHud: (state: IGameHudState) => void;
	setZenMode: (zenMode: boolean) => void;
	toggleZenMode: () => void;
	toggleShowPlayerContextureInfo: () => void;

	clearHudUI: () => void;
}

export const useGameHudStore = create<IGameHudState>()((set, get) => ({
	zenMode: false,
	showPlayerContextualInfo: false,
	playerPosition: { x: 0, y: 0, z: 0, angle: 0 },
	hitBlock: undefined,
	hitBlockTypeName: undefined,
	subGrid: undefined,
	corner1: undefined,
	corner2: undefined,

	// Handlers
	setGameHud: (state) => set(state),
	setZenMode: (zenMode) => {
		useItemStore.getState().unequipItem();

		set({ zenMode });

		if (zenMode) {
			useControlsStore.getState().exitPointerLock(true);
			useAlertDialogStore.getState().setAlert({
				title: "Zen mode activated",
				message: `Use "${useKeyboardStore.getState().getKey("screenshotCanvas")}" to take a screenshot of the canvas.\
        Press "${useKeyboardStore.getState().getKey("zenMode")}" to exit zen mode.`,
			});
		}
	},
	toggleZenMode: () => {
		get().setZenMode(!get().zenMode);
	},

	toggleShowPlayerContextureInfo: () => {
		const nextState = !get().showPlayerContextualInfo;
		set({ showPlayerContextualInfo: nextState });
	},

	// Actions
	clearHudUI: () => {
		set({ zenMode: false, showPlayerContextualInfo: false });
	},
}));

export default {
	useGameHud: useGameHudStore.getState,
};
