import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";

const IS_PRODUCTION = globalEnv.NODE_ENV !== "development";

if (IS_PRODUCTION) {
	Sentry.init({
		dsn: "https://497fe0916de6d37400f3108806be7b74@o4506990746206208.ingest.us.sentry.io/4506990803353600",
		integrations: [
			captureConsoleIntegration({ levels: ["error"] }),
			Sentry.browserTracingIntegration(), // this causes CORS errors
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			Sentry.replayCanvasIntegration(),
		],
		tracePropagationTargets: ["localhost", "novablocks.xyz", "jamango.io"],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
