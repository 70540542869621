import { create } from "zustand";

type IJacyRerenderState = {
	reRenderChangeset: object;
	reRenderWorld: object;
	reRenderWorldData: object;
	reRenderSettings: object;
	reRenderCustomLoader: object;
	reRenderThumbnail: object;
	reRenderGameMechanics: object;
	reRenderPermissions: object;
	reRenderBackgroundMusic: object;
	reRenderSkybox: object;
	reRenderEnvironmentPreset: object;
	reRenderBlocks: object;
	reRenderBlockTextures: object;
	reRenderAvatars: object;
	reRenderAvatarCanvas: object;
	reRenderCharacters: object;
	reRenderAudios: object;
	reRenderWorldTags: object;

	forceRerenderChangeset: () => void;
	forceRerenderWorld: () => void;
	forceRerenderWorldData: () => void;
	forceRerenderSettings: () => void;
	forceRerenderCustomLoader: () => void;
	forceRerenderThumbnail: () => void;
	forceRerenderGameMechanics: () => void;
	forceRerenderPermissions: () => void;
	forceRerenderBackgroundMusic: () => void;
	forceRerenderSkybox: () => void;
	forceRerenderEnvironmentPreset: () => void;
	forceRerenderBlocks: () => void;
	forceRerenderBlockTextures: () => void;
	forceRerenderAvatars: () => void;
	forceRerenderAvatarCanvas: () => void;
	forceRerenderCharacters: () => void;
	forceRerenderAudios: () => void;
	forceRerenderWorldTags: () => void;
};

// Triggers re-render when assets are updated.
export const useJacyRerenderStore = create<IJacyRerenderState>((set) => ({
	reRenderChangeset: {},
	reRenderWorld: {},
	reRenderWorldData: {},
	reRenderSettings: {},
	reRenderCustomLoader: {},
	reRenderThumbnail: {},
	reRenderGameMechanics: {},
	reRenderPermissions: {},
	reRenderBackgroundMusic: {},
	reRenderSkybox: {},
	reRenderEnvironmentPreset: {},
	reRenderBlocks: {},
	reRenderBlockTextures: {},
	reRenderAvatars: {},
	reRenderAvatarCanvas: {},
	reRenderCharacters: {},
	reRenderAudios: {},
	reRenderWorldTags: {},

	forceRerenderChangeset: () => set({ reRenderChangeset: {} }),
	forceRerenderWorld: () => set({ reRenderWorld: {} }),
	forceRerenderWorldData: () => set({ reRenderWorldData: {} }),
	forceRerenderSettings: () => set({ reRenderSettings: {} }),
	forceRerenderCustomLoader: () => set({ reRenderCustomLoader: {} }),
	forceRerenderThumbnail: () => set({ reRenderThumbnail: {} }),
	forceRerenderGameMechanics: () => set({ reRenderGameMechanics: {} }),
	forceRerenderPermissions: () => set({ reRenderPermissions: {} }),
	forceRerenderBackgroundMusic: () => set({ reRenderBackgroundMusic: {} }),
	forceRerenderSkybox: () => set({ reRenderSkybox: {} }),
	forceRerenderEnvironmentPreset: () => set({ reRenderEnvironmentPreset: {} }),
	forceRerenderBlocks: () => set({ reRenderBlocks: {} }),
	forceRerenderBlockTextures: () => set({ reRenderBlockTextures: {} }),
	forceRerenderAvatars: () => set({ reRenderAvatars: {} }),
	forceRerenderAvatarCanvas: () => set({ reRenderAvatarCanvas: {} }),
	forceRerenderCharacters: () => set({ reRenderCharacters: {} }),
	forceRerenderAudios: () => set({ reRenderAudios: {} }),
	forceRerenderWorldTags: () => set({ reRenderWorldTags: {} }),
}));
