export function formatErrorMessage(ex) {
	if (globalEnv.NODE_ENV !== "production") console.error(ex);

	if (ex.code === "ERR_NETWORK" || ex.code === "Network Error") {
		return "Network Error: Something went wrong. Can't connect to the server. Please check your internet connection or try again later.";
	}

	if (ex.response?.data) {
		if (Array.isArray(ex.response.data)) return ex.response.data.join(". ");
		if (ex.response.data.exception) return ex.response.data.exception;
		if (ex.response.data.error) {
			if (Array.isArray(ex.response.data.error))
				return ex.response.data.error.join(". ");
			return ex.response.data.error;
		}
	}

	if (ex instanceof Error) {
		return ex.message;
	}

	return "Something went wrong. Please try again later";
}
