import { constants } from "rest-client";
import { create } from "zustand";

import { Engine } from "./bb";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";
import { useHelpersStore } from "./helpers";

const CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY = "custom-keyboard-controls";

const customKeyboardControlsStorage = {
	get: () => {
		const keys = localStorage.getItem(CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY);

		if (!keys) return {};

		try {
			return JSON.parse(keys);
		} catch (e) {
			localStorage.removeItem(CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY);
			return {};
		}
	},
	set: (keys) => {
		localStorage.setItem(
			CUSTOM_KEYBOARD_CONTROLS_LOCALSTORAGE_KEY,
			JSON.stringify(keys),
		);
	},
};

export const useKeyboardStore = create((set, get) => ({
	keys: constants.controls.CONTROLS,
	init: () => {
		const keys = {
			...constants.controls.CONTROLS,
			...customKeyboardControlsStorage.get(),
		};

		set({ keys });

		return keys;
	},
	getKey: (key) => {
		const { BB, client } = new Engine();

		const keys = typeof key === "string" ? get().keys[key] : key;

		return BB?.[client].input?.[client].poll.getKeyComboString(
			keys || constants.controls.CONTROLS[key],
		);
	},
	setKey: (key, value) => {
		const keys = { ...get().keys, [key]: value };

		set({ keys });

		const { BB, client } = new Engine();
		BB?.[client].settings.bindKeys({ [key]: value });

		// only persist non-default controls so we can gracefully change defaults
		const nonDefaultKeys = Object.keys(keys).reduce((acc, key) => {
			if (
				JSON.stringify(keys[key]) !==
				JSON.stringify(constants.controls.CONTROLS[key])
			) {
				acc[key] = keys[key];
			}

			return acc;
		}, {});

		customKeyboardControlsStorage.set(nonDefaultKeys);
	},
	reset: () => {
		set({ keys: constants.controls.CONTROLS });

		for (const key in get().keys) {
			get().setKey(key, get().keys[key]);
		}
	},
}));

const dispatchEquippedItemControlEvent = (control) => {
	if (isAnyModalOpen()) return;

	const { BB, client, gbi } = new Engine();

	const item = BB.world?.[client].getEquippedItem();

	if (!item) return;

	item.modding.state.dispatcher.dispatchEvent(gbi.ibs.NET_CLIENT, {
		type: control,
	});
};

export const useControlsStore = create((set, get) => ({
	blockMode: constants.controls.MODE.break,
	sculptMode: 0,
	selectionMode: 0,
	emotesWheel: false,
	toolWheel: false,
	tapPointer: { id: null, x: 0, y: 0 },
	canvasTouchStart: (e) => {
		const touch = e.changedTouches[0];
		set({
			tapPointer: { id: touch.identifier, x: touch.clientX, y: touch.clientY },
		});
	},
	canvasTouchEnd: (e) => {
		const curTouch = get().tapPointer;
		if (curTouch.id === null) return;

		for (const touch of e.changedTouches) {
			if (touch.identifier !== curTouch.id) continue;

			const dx = touch.clientX - curTouch.x;
			const dy = touch.clientY - curTouch.y;
			if (Math.sqrt(dx * dx + dy * dy) < 10) get().fireCanvasPointerTap();

			set({ tapPointer: { id: null, x: 0, y: 0 } });

			break;
		}
	},
	fireCanvasPointerTap: () => {
		const { BB, client } = new Engine();
		const input = BB[client].input;

		input.playerDoMelee =
			input.playerDoInteract =
			input.playerDoVehicleMount =
			input.playerDoVehicleDismount =
				true;
	},
	doVehicleDismount: () => {
		const { BB, client } = new Engine();
		const input = BB[client].input;

		input.playerDoVehicleDismount = true;
	},
	toggleSculptMode: (mode) => {
		if (isAnyModalOpen()) return;

		const { BB, client, gbi } = new Engine();
		if (!BB?.world) return;

		let newSculptMode;
		if (typeof mode === "number") {
			newSculptMode = mode;
		} else {
			newSculptMode = get().sculptMode + 1;
			if (newSculptMode === gbi.client.clientConst.selector.sculptMode.COUNT) {
				newSculptMode = 0;
			}
		}

		set({ sculptMode: newSculptMode });
		BB.world[client].selector.sculptMode = newSculptMode;

		BB.world.sfx?.play({
			asset: "snd-inventory-open",
			loop: false,
			volume: 0.5,
		});
	},
	selectorSetSelectionMode: (mode) => {
		if (isAnyModalOpen()) return;

		const { BB, client } = new Engine();
		if (!BB?.world) return;

		set({ selectionMode: mode });
		BB.world[client].selector.selectionMode = mode;

		BB.world.sfx?.play({
			asset: "snd-inventory-open",
			loop: false,
			volume: 0.5,
		});
	},
	selectorToggleSelectionMode: () => {
		if (isAnyModalOpen()) return;

		const { BB, gbi } = new Engine();
		if (!BB?.world) return;

		let newSelectionMode = get().selectionMode + 1;
		if (newSelectionMode === gbi.client.clientConst.selector.selectionMode.COUNT) {
			newSelectionMode = 0;
		}

		get().selectorSetSelectionMode(newSelectionMode);

		dispatchEquippedItemControlEvent("selectorToggleSelectionMode");
	},
	toggleEmotesWheel: (emotesWheel) => {
		const newState =
			typeof emotesWheel === "boolean" ? emotesWheel : !get().emotesWheel;

		if (newState !== false && isAnyModalOpen()) return;

		get().toggleUI("emotesWheel", newState);
	},
	toggleToolWheel: (toolWheel) => {
		const newState = typeof toolWheel === "boolean" ? toolWheel : !get().toolWheel;

		if (newState !== false && isAnyModalOpen()) return;

		get().toggleUI("toolWheel", newState);
	},
	toggleCameraPerspective: () => {
		if (isAnyModalOpen()) return;

		const { BB, client } = new Engine();
		BB.world?.[client].camera.toggleCameraPerspective();

		useHelpersStore.getState().markCameraKnown();
	},
	playerAnimate: (name, loop) => {
		get().toggleEmotesWheel(false);

		const { BB, client } = new Engine();
		BB[client].input.playerDoEmote = { name, loop };
	},
	toggleUI: (currentUI, visible = true) => {
		get().exitPointerLock(visible);
		set({ [currentUI]: visible });

		if (!visible) return;

		const ui = {
			emotesWheel: get().emotesWheel,
			toolWheel: get().toolWheel,
			[currentUI]: visible,
		};

		const newState = {};
		for (const key in ui) {
			if (key === currentUI) continue;
			newState[key] = false;
		}

		set(newState);
	},
	close: () => {
		set({ emotesWheel: false, toolWheel: false });
	},
	exitPointerLock: (close = true) => {
		const { BB, client } = new Engine();
		BB?.[client].input[client].poll.forcePointerLock(!close);
	},
	copy: () => {
		dispatchEquippedItemControlEvent("copy");
	},
	cut: () => {
		dispatchEquippedItemControlEvent("cut");
	},
	paste: () => {
		dispatchEquippedItemControlEvent("paste");
	},
	pasteImmediately: () => {
		dispatchEquippedItemControlEvent("pasteImmediately");
	},
	undo: () => {
		dispatchEquippedItemControlEvent("undo");
	},
	redo: () => {
		dispatchEquippedItemControlEvent("redo");
	},
	pencilSolidFill: () => {
		dispatchEquippedItemControlEvent("pencilSolidFill");
	},
	pencilHollowFill: () => {
		dispatchEquippedItemControlEvent("pencilHollowFill");
	},
	pencilRemoveAllBlocks: () => {
		dispatchEquippedItemControlEvent("pencilRemoveAllBlocks");
	},
	pencilRotateClipboard: () => {
		dispatchEquippedItemControlEvent("pencilRotateClipboard");
	},
	pencilFlipClipboard: () => {
		dispatchEquippedItemControlEvent("pencilFlipClipboard");
	},
	pencilAddToSelection: () => {
		dispatchEquippedItemControlEvent("pencilAddToSelection");
	},
	selectorNudgeLeft: () => {
		dispatchEquippedItemControlEvent("selectorNudgeLeft");
	},
	selectorNudgeRight: () => {
		dispatchEquippedItemControlEvent("selectorNudgeRight");
	},
	selectorNudgeUp: () => {
		dispatchEquippedItemControlEvent("selectorNudgeUp");
	},
	selectorNudgeDown: () => {
		dispatchEquippedItemControlEvent("selectorNudgeDown");
	},
	selectorNudgeForward: () => {
		dispatchEquippedItemControlEvent("selectorNudgeForward");
	},
	selectorNudgeBack: () => {
		dispatchEquippedItemControlEvent("selectorNudgeBack");
	},
	selectorResetBulkSelection: () => {
		dispatchEquippedItemControlEvent("selectorResetBulkSelection");
	},
	wrenchRemoveBlockGroups: () => {
		dispatchEquippedItemControlEvent("wrenchRemoveBlockGroups");
	},
	cameraToolCreateNewCamera: () => {
		dispatchEquippedItemControlEvent("cameraToolCreateNewCamera");
	},
	cameraToolEditCamera: () => {
		dispatchEquippedItemControlEvent("cameraToolEditCamera");
	},
	cameraToolDestroyCamera: () => {
		dispatchEquippedItemControlEvent("cameraToolDestroyCamera");
	},
	cameraToolNextCamera: () => {
		dispatchEquippedItemControlEvent("cameraToolNextCamera");
	},
	cameraToolPreviousCamera: () => {
		dispatchEquippedItemControlEvent("cameraToolPreviousCamera");
	},
	manipulatorLockOn: () => {
		dispatchEquippedItemControlEvent("manipulatorLockOn");
	},
	manipulatorEnterSubGrid: () => {
		dispatchEquippedItemControlEvent("manipulatorEnterSubGrid");
	},
	manipulatorTargetSubGrid: () => {
		dispatchEquippedItemControlEvent("manipulatorTargetSubGrid");
	},
	manipulatorDelete: () => {
		dispatchEquippedItemControlEvent("manipulatorDelete");
	},
	manipulatorToggleEditable: () => {
		dispatchEquippedItemControlEvent("manipulatorToggleEditable");
	},
	manipulatorToggleTransformMode: () => {
		dispatchEquippedItemControlEvent("manipulatorToggleTransformMode");
	},
	manipulatorToggleSnapMode: () => {
		dispatchEquippedItemControlEvent("manipulatorToggleSnapMode");
	},
	manipulatorToggleTransformSpace: () => {
		dispatchEquippedItemControlEvent("manipulatorToggleTransformSpace");
	},
}));

const DOUBLE_PRESS_DELAY = 400;

export const useMovementStore = create((set, get) => ({
	lastPressedUp: 0,
	flying: false,
	crouching: false,
	nippleSessionHasSprinted: false,

	setFlying: (flying) => {
		set({ flying });
	},
	toggleFly: () => {
		const { BB, client } = new Engine();
		const target = BB.world?.[client].camera.target;

		if (!target.type.def.isPlayer) return;
		target.setFlying(!target.movement.state.isFlying);
	},
	setCrouching: (crouching) => {
		set({ crouching });
	},
	toggleCrouching: (crouching) => {
		const { BB, client } = new Engine();
		BB[client].input.playerIsCrouching = crouching;
	},
	up: (touchStart = false) => {
		const { BB, client } = new Engine();
		BB[client].input.playerIsJumping = BB[client].input.playerIsFlyingUp =
			touchStart;

		if (touchStart) {
			const time = performance.now();
			const delta = time - get().lastPressedUp;

			if (delta < DOUBLE_PRESS_DELAY) {
				BB[client].input.playerDoToggleFlying = true;
				set({ lastPressedUp: 0 });
			} else {
				set({ lastPressedUp: time });
			}
		}
	},
	down: (touchStart) => {
		const { BB, client } = new Engine();
		BB[client].input.playerIsFlyingDown = touchStart;
	},
	move: (x, y) => {
		const { BB, client } = new Engine();
		BB[client].input.nipple.set(x, y);

		const len = Math.sqrt(x ** 2 + y ** 2);
		const angle = (Math.atan2(y, x) * 180) / Math.PI;
		const inSprintPos = len > 0.99 && angle > 45 && angle < 135;

		if (inSprintPos && !get().nippleSessionHasSprinted) {
			BB[client].input.playerIsSprinting = true;
			set({ nippleSessionHasSprinted: true });
		} else if (!inSprintPos) {
			BB[client].input.playerIsSprinting = false;
			set({ nippleSessionHasSprinted: false });
		}
	},
	cancelDoubleTaps: () => {
		set({ lastPressedUp: 0 });
	},
}));

export default {
	useKeyboard: useKeyboardStore.getState,
	useControls: useControlsStore.getState,
	useMovement: useMovementStore.getState,
};
