import { create } from "zustand";
import { Jacy } from "@jacy-client";
import { constants } from "rest-client";
import { Engine } from "./bb";
import { useControlsStore } from "./controls";
import { useInventoryStore } from "./dialogs/inventory";
import { usePermissionStore } from "./player";
import { useItemStore } from "./hud/item";
import { useCustomUIStore } from "./custom-ui";

/**
 * Map Blob
 * {Promise<ArrayBuffer>} The map blob in its non-testing state that will be saved to the database
 */
let mapBlobBackup = null;
export function getMapBlob() {
	const { BB } = new Engine();
	return mapBlobBackup ?? BB.world.scene.saveMap();
}

export const useTestWorldStore = create((set, get) => ({
	type: constants.world.TEST_WORLD_TYPE.none,
	timestamp: null,
	loading: false,

	toggleTestMode: async () => {
		if (get().loading) return;
		if (get().type === constants.world.TEST_WORLD_TYPE.gaming) return;
		const { BB, client } = new Engine();

		set({ loading: true });
		const canvas = document.getElementById("gameCanvas");

		if (get().type === constants.world.TEST_WORLD_TYPE.testing) {
			usePermissionStore.getState().resetCreatorsCanBuild();
			await get().end();
			canvas?.classList.remove("border-stripes");
		} else if (get().type === constants.world.TEST_WORLD_TYPE.none) {
			get().begin(constants.world.TEST_WORLD_TYPE.testing);
			canvas?.classList.add("border-stripes");
		}

		useControlsStore.getState().exitPointerLock(false);
		set({ loading: false });
		setTimeout(() => {
			BB.world.hudPopup[client].clear();
		}, 2000);
	},
	toggleGameMode: async () => {
		if (get().loading) return;
		if (get().type === constants.world.TEST_WORLD_TYPE.testing) return;
		const { BB, client } = new Engine();

		set({ loading: true });

		if (get().type === constants.world.TEST_WORLD_TYPE.gaming) {
			usePermissionStore.getState().setPlayerMode(false);
			await get().end();
			useCustomUIStore.getState().resetUI();
		} else if (get().type === constants.world.TEST_WORLD_TYPE.none) {
			usePermissionStore.getState().setPlayerMode(true);
			get().begin(constants.world.TEST_WORLD_TYPE.gaming);
			await get().end(); //by ending the testing here it resets the ai default spawn points - hacky way for now.
			get().begin(constants.world.TEST_WORLD_TYPE.gaming);
		}

		useControlsStore.getState().exitPointerLock(false);
		useInventoryStore.getState().toggle(false);
		useItemStore.getState().setEquippedItem("ItemWrench");
		set({ loading: false });
		setTimeout(() => {
			BB.world.hudPopup[client].clear();
		}, 2000);
	},
	begin: (type) => {
		if (get().type !== constants.world.TEST_WORLD_TYPE.none) return;

		mapBlobBackup = getMapBlob();

		set({ type, timestamp: Date.now() });

		const testType =
			get().type === constants.world.TEST_WORLD_TYPE.gaming ? "Game" : "Test";
		const { BB } = new Engine();

		BB.testingMode = testType;

		Jacy.setIsTesting(true);

		console.logApp(testType + " Mode started");
	},
	end: async () => {
		if (get().type === constants.world.TEST_WORLD_TYPE.none) return;

		const { BB } = new Engine();
		await BB.world.scene.loadMapSameGenerators(mapBlobBackup, false);

		mapBlobBackup = null;

		set({ type: constants.world.TEST_WORLD_TYPE.none });

		const testType =
			get().type === constants.world.TEST_WORLD_TYPE.gaming ? "Game" : "Test";
		BB.testingMode = null;

		Jacy.setIsTesting(false);

		console.logApp(testType + " Mode ended");
	},
	reset: () => {
		mapBlobBackup = null;
		const canvas = document.getElementById("gameCanvas");
		canvas?.classList.remove("border-stripes");
		set({ type: constants.world.TEST_WORLD_TYPE.none });
		usePermissionStore.getState().setMode("DEFAULT");
	},
}));

export default {
	useTestWorld: useTestWorldStore.getState,
};
