import React from "react";
import { api } from "rest-client";
import { Jacy } from "@jacy-client";
import { DotsThree } from "@components/icons";
import { Button } from "@components/ui/Button";
import { GameClient } from "@jamango/client";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useVisible } from "@lib/hooks/useVisible";
import { BlankWorldTemplate } from "@components/world/templates";

type Props = {
	identifier: string;
	name: string;
	mutate: (mutator: (worlds?: { identifier: string }[]) => any) => void;
};

export function WorldOption({ identifier, name, mutate: mutateWorlds }: Props) {
	const confirmPrompt = useConfirmPromptStore((state) => state.prompt);
	const ref = React.useRef<HTMLDivElement>(null);

	const [visible, setVisible] = useVisible(ref);
	const [loading, setLoading] = React.useState<false | "delete">(false);

	const toggle = () => setVisible((state) => !state);

	const handleDeleteWorld = async () => {
		if (!identifier || loading) return;
		const confirmed = await confirmPrompt({
			title: "Are you sure?",
			description:
				"Deleting this world is irreversible. It will delete the associated blocks, skybox, and texture pack(s) that are not used in other worlds. You will be forced to leave this world and start a new one.",
			confirmText: "Yes, delete the world",
			prompt: `delete ${name}`,
		});

		if (!confirmed) return;

		setLoading("delete");

		try {
			await api.world.deleteWorld({ identifier });
			mutateWorlds((worlds) =>
				worlds?.filter((item) => item.identifier === identifier),
			);
			setVisible(false);

			if (Jacy.state.world.identifier === identifier) {
				GameClient.startNewWorld({
					worldTemplateOptions: BlankWorldTemplate.options,
				});
			}
		} catch (ex) {
			console.error(ex);
		}

		setLoading(false);
	};

	return (
		<div className="relative">
			<Button
				color="white"
				square
				id={`world-${identifier}-options`}
				onClick={toggle}
				aria-haspopup="true"
				aria-expanded={visible}
			>
				<DotsThree className="size-5" aria-hidden="true" weight="bold" />
				<span className="sr-only">Options</span>
			</Button>

			<div ref={ref}>
				{visible && (
					<div
						aria-labelledby={`world-${identifier}-options`}
						role="region"
						className="absolute right-0 top-12 z-10"
					>
						<div className="dropdown-container relative z-10">
							<span
								className="absolute -top-[9px] right-3 block size-0 border-x-8 border-b-8 border-solid border-x-transparent border-b-slate-300"
								aria-hidden="true"
							/>
							<ul>
								<li>
									<Button
										color="red-ghost"
										size="md"
										onClick={handleDeleteWorld}
										disabled={!!loading}
									>
										{loading === "delete" ? "Deleting..." : "Delete"}
									</Button>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
