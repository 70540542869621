import {
	validations,
	lib,
	GameType,
	IGameMechanics,
	MultiplayerType,
	WinCondition,
	IGameMechanicsChangeset,
	IJacyContent,
} from "jacy";
import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyGameMechanicsActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setMultiplayerType(multiplayerType: MultiplayerType) {
		const mechanics =
			multiplayerType === MultiplayerType.ALWAYS_ON_SERVER
				? lib.constants.gameMechanics.DEFAULT_GAME_MECHANICS_SANDBOX_MODE
				: lib.constants.gameMechanics.DEFAULT_GAME_MECHANICS_TEAM_DEATHMATCH;

		validations.gameMechanics.multiplayerType(multiplayerType);
		this.#updateGameMechanics(mechanics);
	}

	setGameType(gameType: GameType) {
		validations.gameMechanics.gameType(gameType);
		this.#updateGameMechanics({ gameType });
	}

	setMinPlayers(minPlayers: number) {
		const gameMechanics = this.#state.gameMechanics.get();

		validations.gameMechanics.minPlayers(minPlayers, gameMechanics.maxPlayers);
		this.#updateGameMechanics({ minPlayers });
	}

	setMaxPlayers(value: number) {
		const gameMechanics = this.#state.gameMechanics.get();

		const maxPlayers = value === Infinity ? Infinity : Math.trunc(value);
		validations.gameMechanics.maxPlayers(maxPlayers, gameMechanics.minPlayers);
		this.#updateGameMechanics({ maxPlayers });
	}

	setReviveEnabled(reviveEnabled: boolean) {
		validations.gameMechanics.reviveEnabled(reviveEnabled);
		this.#updateGameMechanics({ reviveEnabled });
	}

	setCharacterCollisions(characterCollisions: boolean) {
		validations.gameMechanics.characterCollisions(characterCollisions);
		this.#updateGameMechanics({ characterCollisions });
	}

	setWinCondition(winCondition: WinCondition) {
		validations.gameMechanics.winCondition(winCondition);
		this.#updateGameMechanics({ winCondition });
	}

	setMaxTeamKills(value: number) {
		const maxTeamKills = Math.trunc(value);
		validations.gameMechanics.maxTeamKills(maxTeamKills);
		this.#updateGameMechanics({ maxTeamKills });
	}

	setRounds(value: number) {
		const rounds = Math.trunc(value);
		validations.gameMechanics.rounds(rounds);
		this.#updateGameMechanics({ rounds });
	}

	setTimePerRound(minutes: number, seconds: number) {
		const gameMechanics = this.#state.gameMechanics.get();

		const minutesInt = Math.trunc(minutes);
		const secondsInt = Math.trunc(seconds);

		const timePerRound = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
		if (timePerRound === gameMechanics.timePerRound) {
			return;
		}

		validations.gameMechanics.timePerRound(minutesInt, secondsInt);
		this.#updateGameMechanics({ timePerRound });
	}

	setTeamName(id: string, name: string) {
		this.#setTeams(id, { name });
	}

	setTeamColor(
		id: string,
		color: keyof typeof lib.constants.gameMechanics.TEAM_COLOR,
	) {
		this.#setTeams(id, { color });
	}

	#setTeams(
		id: string,
		{
			name,
			color,
		}: {
			name?: string;
			color?: keyof typeof lib.constants.gameMechanics.TEAM_COLOR;
		},
	) {
		const gameMechanics = this.#state.gameMechanics.get();

		const teams = gameMechanics.teams.map((team) => {
			if (team.id === id) {
				return {
					...team,
					name: name ?? team.name,
					color: color ?? team.color,
				};
			}

			return team;
		});

		if (!lib.helpers.general.isNullish(color)) {
			validations.gameMechanics.teamColor(id, color!, gameMechanics.teams);
		}

		if (!lib.helpers.general.isNullish(name)) {
			validations.gameMechanics.teamName(id, name!, gameMechanics.teams);
		}

		this.#updateGameMechanics({ teams });
	}

	#updateGameMechanics(data: Partial<IGameMechanics>) {
		const id = this.#state.gameMechanics.update(data);
		useJacyRerenderStore.getState().forceRerenderGameMechanics();

		this.#actions.updateChangeset<IGameMechanicsChangeset>(id, "update", data);
	}
}
