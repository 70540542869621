import { GlobeHemisphereWest, PlayCircle, ArrowRight } from "@components/icons";
import { GameClient, GameMultiplayer } from "@jamango/client";

import { constants } from "rest-client";
import { useJacyUserStore } from "@stores/jacy/user";
import { LOADING_TEXT } from "@stores/game-client/game-client";
import { useGameLoading } from "@lib/hooks/game-client/useGameLoading";

import { Button } from "@components/ui/Button";
import { WorldOption } from "./HubDialog/screens/WorldOption";
import { Tooltip } from "@components/ui/Tooltip";

export const HubWorldCard = ({ world, mutateWorlds }) => {
	const loading = useGameLoading();
	const username = useJacyUserStore((state) => state.username);

	const authUserHosting = !!(world.host && world.host.username === username);
	const isCollaborator = world.collaborators.some(
		(collaborator) => collaborator.username === username,
	);

	if (!world) return;

	const handleLoadWorld = async (world) => {
		await GameClient.loadWorld(world.identifier);
	};

	return (
		<li
			key={world.identifier}
			className="flex flex-col justify-between gap-4 p-4 xs:flex-row sm:items-center"
		>
			<div
				aria-hidden="true"
				className="hidden rounded-md border-b border-gray-300 bg-gray-100 p-4 text-gray-900 sm:block"
			>
				<GlobeHemisphereWest className="size-8" />
			</div>
			<div className="flex-1">
				<div className="text-xl font-medium text-gray-900">
					{world.name}
					{(world.published || world.featured) && (
						<span className="ml-2 rounded bg-emerald-200 px-1 py-0.5 font-sans text-xs font-semibold text-emerald-900">
							{world.featured ? "Featured" : "Published"}
						</span>
					)}
					{isCollaborator && (
						<span className="ml-2 rounded bg-rose-200 px-1 py-0.5 font-sans text-xs font-semibold text-rose-900">
							Collaborator
						</span>
					)}
					{world.host && (
						<span className="ml-2 rounded bg-indigo-200 px-1 py-0.5 font-sans text-xs font-semibold text-indigo-900">
							{world.host.dedicated
								? "Dedicated Server Hosting"
								: authUserHosting
									? "Currently Hosting"
									: `Hosting by ${world.host.username}`}
						</span>
					)}
				</div>
				<p className="text-sm text-gray-600">
					{world.identifier == null && (
						<span className="text-red-600">Can&apos;t load world</span>
					)}
					{world.identifier}&nbsp;&middot; Updated {world.updated_at} ago
				</p>

				{world.identifier == null && (
					<p className="text-xs text-red-600">
						If you haven&apos;t reported this issue yet, please let us know in the{" "}
						<span className="font-medium">#support</span> channel on{" "}
						<a
							href={constants.general.DISCORD_INVITE_LINK}
							target="_blank"
							rel="noopener noreferrer"
							className="text-link"
						>
							Discord
						</a>
						.
					</p>
				)}
			</div>
			{world.identifier != null && (
				<div className="flex flex-col gap-2 sm:flex-row sm:items-center">
					{world.host && !authUserHosting && (
						<Button
							size="md"
							onClick={() => GameMultiplayer.joinWorld(world.host.serverID)}
						>
							<ArrowRight className="hidden size-4 xs:block" aria-hidden="true" />
							Join world
						</Button>
					)}
					<div className="flex gap-2">
						<Tooltip content={loading ? LOADING_TEXT[loading] : null}>
							<Button
								size="md"
								onClick={() => handleLoadWorld(world)}
								className="grow"
								disabled={!!loading}
							>
								<PlayCircle className="hidden size-5 xs:block" aria-hidden="true" />
								Load and play
							</Button>
						</Tooltip>
						<WorldOption
							identifier={world.identifier}
							name={world.name}
							mutate={mutateWorlds}
						/>
					</div>
				</div>
			)}
		</li>
	);
};
