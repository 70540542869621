import { create } from "zustand";
import { Jacy } from "@jacy-client";
import {
	AssetType,
	lib,
	IAssetKey,
	IBlock,
	ICharacter,
	IParsedBlock,
	IParsedCharacter,
	ISelectorSlots,
	ISelectorSlot,
} from "jacy";
import { constants } from "rest-client";

import { usePermissionStore } from "@stores/player";
import { useJacyAvatarEditorStore } from "@stores/jacy/avatar-editor";
import { useHelpersStore } from "@stores/helpers";
import {
	useItemStore,
	CREATOR_SELECTOR_SLOT,
	CREATOR_SELECTOR_SLOTS,
	EquippedItem,
} from "@stores/hud/item";
import { useTestWorldStore } from "@stores/test-world";
import { Engine } from "@stores/bb";
import { useBulkBloganStore } from "@stores/bulk-blogan";
import { isAnyModalOpen } from "@lib/helpers/isAnyModalOpen";
import { useWorldStore } from "@stores/world";
import type { WorldTemplateOptions } from "@components/world/templates";

type ISelectorState = {
	selector: ISelectorSlots;
	selectedSlot: number;
	selectedBlock: ISelectorSlot | null;
	selectedCharacter: ISelectorSlot | null;

	// Handlers
	setSelectorSlot: (
		type: AssetType,
		pk: IAssetKey,
		index?: number,
		active?: boolean,
	) => void;
	setSelector: (selector: ISelectorSlots) => void;
	setSelectedSlot: (slot?: number, isMouseScroll?: boolean) => void;
	setSelectedBlock: (block?: ISelectorSlot | null) => void;
	setSelectedCharacter: (character?: ISelectorSlot | null) => void;

	// Actions
	init: (inventory?: ISelectorSlots) => void;
	clear: () => void;
	refresh: () => void;
	pickBlock: () => void;

	// Helpers
	formatBlock: (block: IBlock | IParsedBlock) => ISelectorSlot;
	formatCharacter: (character: ICharacter | IParsedCharacter) => ISelectorSlot;
	convertDefaultInventoryToIventorySlots: (
		slot: Required<WorldTemplateOptions>["defaultInventory"],
	) => ISelectorSlots;
};

export const useSelectorStore = create<ISelectorState>((set, get) => ({
	selector: [],
	selectedSlot: 0,
	selectedBlock: null,
	selectedCharacter: null,

	// Handlers
	setSelectorSlot: (type, pk, index, active = false) => {
		const canBuild = usePermissionStore.getState().checkCanBuild();
		if (!canBuild) return;

		const selector = [...get().selector];
		const currentIndex = index ?? get().selectedSlot;

		let item;

		if (type === AssetType.BLOCK) {
			const block = Jacy.state.blocks.get(pk);
			if (!block) return;

			item = get().formatBlock(block);
		} else if (type === AssetType.CHARACTER) {
			const character = Jacy.state.characters.get(pk);
			if (!character) return;

			item = get().formatCharacter(character);
		}

		if (!item) return;

		selector[currentIndex] = item;

		set({ selector });

		const isCurrentSlot = active || currentIndex === get().selectedSlot;

		if (isCurrentSlot) {
			get().setSelectedSlot(currentIndex);
		}
	},
	setSelector: (selector) => {
		set({ selector });
		get().refresh();

		const isCreatorSlots = usePermissionStore.getState().isCreatorSlots();

		if (!isCreatorSlots) {
			get().setSelectedSlot(0);
		}
	},
	setSelectedSlot: (slot, isMouseScroll) => {
		if (typeof slot !== "number") return;

		const canBuild = usePermissionStore.getState().permissions.canBuild;
		const isCreatorSlots = usePermissionStore.getState().isCreatorSlots();
		const isAvatarEditorOpen = useJacyAvatarEditorStore.getState().open;

		if (isMouseScroll && isAvatarEditorOpen) return;
		if ((!canBuild && !isCreatorSlots) || (isCreatorSlots && isMouseScroll)) return;

		set({ selectedSlot: slot });

		useHelpersStore.getState().markChangeSlotKnown();

		if (isCreatorSlots) {
			if (CREATOR_SELECTOR_SLOTS[slot] === CREATOR_SELECTOR_SLOT.test)
				useTestWorldStore.getState().toggleTestMode();
			else if (CREATOR_SELECTOR_SLOTS[slot] === CREATOR_SELECTOR_SLOT.play)
				useTestWorldStore.getState().toggleGameMode();
		} else {
			const item = get().selector[slot];

			if (lib.helpers.general.isNullish(item)) return;
			if (item.type === AssetType.BLOCK) {
				get().setSelectedBlock(item);
			} else if (item.type === AssetType.CHARACTER) {
				get().setSelectedCharacter(item);
			}
		}
	},
	setSelectedBlock: (slot) => {
		const { BB, client } = new Engine();
		if (!BB?.world) return;
		if (!BB.world[client].camera?.target.type.def.isPlayer) return;

		const player = BB.world[client].camera.target;
		if (!player.type.def.isPlayer) return;

		const input = BB[client].input;

		const block = slot ? Jacy.state.blocks.get(slot.pk) : undefined;
		const equippedItem = player.getEquippedItem();

		set({ selectedBlock: slot });

		if (!block) {
			if (equippedItem?.def.startsWith(EquippedItem.BLOCK)) {
				input.playerDoEquip = null;
			}

			BB.world[client].selector.selectedSlot = undefined;
			return;
		}

		// todo: PlayerContext for tools that use blocks
		if (
			useItemStore.getState().equippedItem === EquippedItem.BULK_BLOGAN &&
			useBulkBloganStore.getState().bloganContext > 0
		) {
			BB.world[client].selector.mostRecentType = block.name;
		} else {
			if (equippedItem?.def === EquippedItem.BLOCK + block.name) return;
			input.playerDoEquip = EquippedItem.BLOCK + block.name;
		}

		BB.world[client].selector.selectedSlot = slot;
	},
	setSelectedCharacter: (slot) => {
		set({ selectedCharacter: slot });

		if (!slot) return;
		const character = Jacy.state.characters.get(slot.pk);
		if (!character) return;

		const { BB, client } = new Engine();
		if (!BB?.world) return;
		if (!BB.world[client].camera?.target.type.def.isPlayer) return;

		BB[client].input.playerDoEquip = EquippedItem.SPAWNER;
		BB.world[client].selector.selectedSlot = slot;
	},

	// Actions
	init: (inventory) => {
		if (inventory && inventory.length !== 0) {
			get().setSelector(inventory);
		} else {
			const blocks = Jacy.state.blocks.getAll();

			const selector: ISelectorSlots = blocks
				.slice(0, constants.selector.SLOT_SIZE)
				.map((block) => get().formatBlock(block));

			get().setSelector(selector);
		}

		// add a recent block if the world loaded on creator mode to avoid being stuck in pencil / wrench
		if (useWorldStore.getState().mode === constants.world.WORLD_MODE.CREATOR) {
			const { BB, client } = new Engine();
			if (!BB?.world || !client) return;

			BB.world[client].selector.mostRecentType = get().selectedBlock?.id;
		}
	},
	clear: () => {
		set({ selector: Array(constants.selector.SLOT_SIZE).fill(null) });
	},
	refresh: () => {
		const selector: ISelectorSlots = get().selector.map((slot) => {
			if (!slot) return null;

			if (slot.type === AssetType.BLOCK) {
				const block = Jacy.state.blocks.get(slot.pk);
				if (!block) return null;
				return get().formatBlock(block);
			} else if (slot.type === AssetType.CHARACTER) {
				const character = Jacy.state.characters.get(slot.pk);
				if (!character) return null;
				return get().formatCharacter(character);
			}

			return null;
		});

		set({ selector });
	},
	pickBlock: () => {
		if (isAnyModalOpen()) return;

		const { BB, client } = new Engine();

		const selector = BB.world[client].selector;
		const hitBlock = selector.hitBlock;
		const blockType = selector.getType(hitBlock.x, hitBlock.y, hitBlock.z);

		if (lib.helpers.general.isNullish(blockType)) return;

		const foundIndex = get().selector.findIndex((slot) => {
			if (!slot) return false;
			return slot.id === blockType;
		});

		if (foundIndex !== -1) {
			get().setSelectedSlot(foundIndex);
		} else {
			get().setSelectorSlot(AssetType.BLOCK, blockType);
		}
	},

	// Helpers
	formatBlock: (block) => {
		return {
			type: block.type,
			pk: block.pk,
			id: block.name,
			displayName: block.displayName,
		};
	},
	formatCharacter: (character) => {
		return {
			type: character.type,
			pk: character.pk,
			id: character.id,
			displayName: character.name,
		};
	},
	convertDefaultInventoryToIventorySlots: (defaultInventory) => {
		const blocks = Jacy.state.blocks.getAll();
		const characters = Jacy.state.characters.getAll();

		const selectorState = get();

		const defaultSelectorSlots: ISelectorSlots = [];

		for (const item of defaultInventory) {
			if (item.type === "block") {
				const block = blocks.find((block) => block.name === item.id);
				if (block) {
					defaultSelectorSlots.push(selectorState.formatBlock(block));
				}
			} else if (item.type === "character") {
				const character = characters.find((character) => character.id === item.id);
				if (character) {
					defaultSelectorSlots.push(selectorState.formatCharacter(character));
				}
			}
		}

		return defaultSelectorSlots;
	},
}));

export default {
	useSelector: useSelectorStore.getState,
};
