import { ICustomLoaderChangeset, IJacyContent, ISettingsChangeset } from "jacy";

import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyCustomLoaderActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setShow(show: boolean) {
		try {
			const pk = this.#state.customLoader.setShow(show);

			useJacyRerenderStore.getState().forceRerenderCustomLoader();
			useJacyRerenderStore.getState().forceRerenderSettings();

			this.#actions.setSuccess(
				`Custom loader has been ${show ? "enabled" : "disabled"} successfully.`,
			);

			this.#actions.updateChangeset<ISettingsChangeset>(pk, "update", {
				showCustomLoader: show,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	setTitle(title: string) {
		try {
			const customLoader = this.#state.customLoader.setTitle(title);

			if (!customLoader?.bannerUrl.default) {
				throw new Error("Custom loader failed to save.");
			}

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.setSuccess(
				"Custom loader's title has been updated successfully.",
			);

			this.#actions.updateChangeset<ICustomLoaderChangeset>(
				customLoader.pk,
				"update",
				{ title },
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	setDescription(description: string) {
		try {
			const customLoader = this.#state.customLoader.setDescription(description);

			if (!customLoader?.bannerUrl.default) {
				throw new Error("Custom loader failed to save.");
			}

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.setSuccess(
				"Custom loader's description has been updated successfully.",
			);

			this.#actions.updateChangeset<ICustomLoaderChangeset>(
				customLoader.pk,
				"update",
				{ description },
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	uploadBanner(file: File) {
		try {
			const [customLoader, banner] = this.#state.customLoader.uploadBanner(file);

			if (!customLoader?.bannerUrl.default) {
				throw new Error("Custom loader failed to upload.");
			}

			useJacyRerenderStore.getState().forceRerenderCustomLoader();

			this.#actions.updateChangeset<ICustomLoaderChangeset>(
				customLoader.pk,
				"update",
				{ banner, bannerUrl: customLoader.bannerUrl.default },
			);

			this.#actions.setSuccess("Banner uploaded successfully.");
		} catch (error) {
			this.#actions.setError(error);
		}
	}
}
