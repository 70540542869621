import { GameClient } from "@jamango/client";
import { constants } from "rest-client";

import { useErrorStore } from "@stores/dialogs";
import { useJacyUserStore } from "@stores/jacy/user";
import { useGameLoading } from "@lib/hooks/game-client/useGameLoading";
import { Loading } from "@stores/game-client/game-client";

import { Button } from "@components/ui/Button";
import { Dialog } from "./Dialog";

export function ErrorDialog() {
	const open = useErrorStore((state) => state.open);
	const toggle = useErrorStore((state) => state.toggle);
	const errorObj = useErrorStore((state) => state.error);
	const loading = useGameLoading();
	const canSaveWorld = useJacyUserStore((state) => state.canSaveWorld);

	const handleOpenChange = () => {
		window.location.reload();
		toggle();
	};

	const errorMessage = errorObj?.message ?? "";
	const errorLines = errorMessage.split("\n");

	return (
		<Dialog.Root open={open} onOpenChange={handleOpenChange}>
			<Dialog.Content className="fixed z-[1000] h-[80vh] max-w-[690px] rounded-3xl bg-slate-50 p-8 shadow-md outline-none">
				<div className="flex flex-col items-center gap-8 md:flex-row lg:items-start">
					<div className="flex flex-col justify-between md:items-start">
						<Dialog.Title className="mb-2 text-3xl font-medium">
							Oops! Something went wrong.
						</Dialog.Title>
						<h6 className="mb-2 text-center text-2xl font-semibold md:text-left"></h6>
						<p className="mb-4 text-2xl">
							Emmet Brown and his crew will take a look under the hood. 💪
						</p>
						{errorObj && (
							<>
								<p className="mb-4 line-clamp-4 text-justify md:text-left">
									<span className="font-medium">Error:</span>{" "}
									{errorLines.length > 0 && (
										<>
											<span>{errorLines[0]}</span>
											{errorLines.slice(1).map((line, index) => (
												<span key={index} style={{ display: "block" }}>
													{line || <>&nbsp;</>}{" "}
													{/* Render a non-breaking space if the line is empty */}
												</span>
											))}
										</>
									)}
								</p>
								{/* COMMENTED OUT FOR NOW, JUST USE THIS INCASE WE NEED THE INFO FOR FUTURE USE CASES */}
								{/* <details>
                                <summary className='mb-4 line-clamp-4 text-justify md:text-left text-red-600 font-semibold cursor-pointer underline hover:text-red-800'> Click here to see more</summary>
                                <p className="text-sm overflow-auto max-h-[50vh] whitespace-pre-wrap">
                                    {errorObj?.stack}
                                </p>
                            </details>  */}
							</>
						)}
						<div className="flex w-full flex-col justify-center gap-2 py-1 text-center xs:flex-row">
							{canSaveWorld && (
								<Button
									size="xl"
									className="text-xl"
									onClick={() => GameClient.saveWorld()}
									disabled={!!loading}
								>
									{loading === Loading.SAVE_WORLD
										? "Saving world..."
										: "Attempt to save world"}
								</Button>
							)}
							<Button size="xl" className="text-xl" onClick={handleOpenChange}>
								Reload game
							</Button>
						</div>
						<div className="mt-4">
							If this problem persists, please let us know in the{" "}
							<span className="font-medium">#support</span> channel on{" "}
							<a
								href={constants.general.DISCORD_INVITE_LINK}
								target="_blank"
								rel="noopener noreferrer"
								className="text-link"
							>
								Discord
							</a>
							.
						</div>
					</div>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
}
