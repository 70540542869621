import { cn } from "@lib/helpers/cn";
import { CustomCSSProps, GeneralInputs, GeneralStyles } from "@stores/custom-ui";
import React from "react";
import { constants } from "rest-client";

interface IProps {
	styles: GeneralStyles;
	config: GeneralInputs;
	isPreview?: boolean;
}

export function ProgressBar({ styles, config, isPreview = false }: IProps) {
	const stylesConfiguration = constants.customUI.formatStyles(
		styles,
		"progress-bar",
	) as CustomCSSProps;

	const bgColorInStyle = {
		width: `${isPreview && !config?.value?.value ? 50 : config?.value?.value}%`,
		"--progress-bar-backgroundColorIn":
			stylesConfiguration["--progress-bar-backgroundColorIn"],
		"--progress-bar-backgroundColorIn-lg":
			stylesConfiguration["--progress-bar-backgroundColorIn-lg"],
	} as React.CSSProperties;
	const bgColorOutStyle = {
		"--progress-bar-backgroundColorOut-lg":
			stylesConfiguration["--progress-bar-backgroundColorOut-lg"],
		"--progress-bar-backgroundColorOut":
			stylesConfiguration["--progress-bar-backgroundColorOut"],
	} as React.CSSProperties;
	const textShadowConfig = {
		"--text-shadow-color": stylesConfiguration["--progress-bar-textShadowColor"],
		"--text-stroke-color": stylesConfiguration["--progress-bar-textStrokeColor"],
	} as React.CSSProperties;

	return (
		<div
			className={cn(
				"relative flex select-none items-center gap-2",
				// font weight
				"!font-[var(--progress-bar-fontWeight)]",
				"lg:!font-[var(--progress-bar-fontWeight-lg)]",
				//margin
				"m-[var(--progress-bar-margin)]",
				"lg:m-[var(--progress-bar-margin-lg)]",
				// padding
				"p-[var(--progress-bar-padding)]",
				"lg:p-[var(--progress-bar-padding-lg)]",
				// font family
				"font-[family-name:var(--progress-bar-fontFamily)]",
				"lg:font-[family-name:var(--progress-bar-fontFamily-lg)]",
				// font size
				"text-[length:var(--progress-bar-fontSize)]",
				"lg:text-[length:var(--progress-bar-fontSize-lg)]",
				// color
				"text-[var(--progress-bar-color)]",
				"lg:text-[var(--progress-bar-color-lg)]",
				// position
				"!top-[var(--progress-bar-position-top)]",
				"!left-[var(--progress-bar-position-left)]",
				"!bottom-[var(--progress-bar-position-bottom)]",
				"!right-[var(--progress-bar-position-right)]",
				"gap-[var(--progress-bar-gap)]",
				"lg:gap-[var(--progress-bar-gap-lg)]",
			)}
			style={stylesConfiguration}
		>
			<p
				className={cn(
					"font-semibold text-inherit",
					"!font-stroke-[var(--progress-bar-textStroke)]",
					"!text-shadow-[var(--progress-bar-textShadow)]",
				)}
				style={textShadowConfig}
			>
				{config?.text?.value}
				<span className="sr-only">{config?.value?.value ?? 0}%</span>
			</p>

			<div
				className={cn(
					"overflow-hidden rounded",
					// Border radius
					"rounded-[var(--progress-bar-borderRadius)]",
					"lg:rounded-[var(--progress-bar-borderRadius-lg)]",
					// background color out
					"bg-[var(--progress-bar-backgroundColorOut)]",
					"lg:bg-[var(--progress-bar-backgroundColorOut-lg)]",
					// width
					"w-[var(--progress-bar-width)]",
					"lg:w-[var(--progress-bar-width-lg)]",
					// Height
					"h-[var(--progress-bar-height)]",
					"lg:h-[var(--progress-bar-height-lg)]",
				)}
				aria-hidden="true"
				style={bgColorOutStyle}
			>
				<div
					className={cn(
						"h-full",
						// Background Color In
						"bg-[var(--progress-bar-backgroundColorIn)]",
						"lg:bg-[var(--progress-bar-backgroundColorIn-lg)]",
					)}
					style={bgColorInStyle}
				/>
			</div>
		</div>
	);
}
