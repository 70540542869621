import { constants } from "rest-client";

export default `
    @font-face {
        font-family: 'New Amsterdam';
        src: url(${constants.getStaticFile("assets/frontend/fonts/New_Amsterdam/NewAmsterdam-Regular.woff2")}) format('woff2'); 
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;
