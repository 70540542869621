import { twMerge } from "tailwind-merge";

function toVal(mix) {
	let k,
		y,
		str = "";

	if (typeof mix === "string" || typeof mix === "number") {
		str += mix;
	} else if (typeof mix === "object") {
		if (Array.isArray(mix)) {
			for (k = 0; k < mix.length; k++) {
				if (mix[k]) {
					if ((y = toVal(mix[k]))) {
						str && (str += " ");
						str += y;
					}
				}
			}
		} else {
			for (k in mix) {
				if (mix[k]) {
					str && (str += " ");
					str += k;
				}
			}
		}
	}

	return str;
}

/*
  Clsx
  For merging classNames conditionally
  Source: https://github.com/lukeed/clsx
*/
function clsx() {
	let i = 0,
		tmp,
		x,
		str = "";
	while (i < arguments.length) {
		if ((tmp = arguments[i++])) {
			if ((x = toVal(tmp))) {
				str && (str += " ");
				str += x;
			}
		}
	}
	return str;
}

export function cn(...classNames) {
	return twMerge(clsx(classNames.filter((className) => className)));
}
