import { constants } from "rest-client";

export default `
    @font-face {
        font-family: 'Orbitron';
        src: url(${constants.getStaticFile("assets/frontend/fonts/Orbitron/Orbitron-Regular.woff2")}) format('woff2'); 
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
`;
