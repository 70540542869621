import { create } from "zustand";

export const useGameFPSStore = create((set) => ({
	open: false,
	teams: [],

	// Handlers
	setOpen: (open) => set({ open }),
	setTeams: (teams) => set({ teams }),

	// Actions
	init: ({ teams }) => set({ teams }),
	reset: () => set({ open: false }),
}));

export default {
	useGameFPS: useGameFPSStore.getState,
};
