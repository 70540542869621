import { validations, ISettings, ISettingsChangeset, IJacyContent } from "jacy";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { JacyActions } from "../JacyActions";

export class JacySettingsActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setDayCycle(dayCycle: boolean) {
		validations.settings.dayCycle(dayCycle);
		this.#updateSettings({ dayCycle });
	}

	#updateSettings(data: Partial<ISettings>) {
		const id = this.#state.settings.update(data);
		useJacyRerenderStore.getState().forceRerenderSettings();
		this.#actions.updateChangeset<ISettingsChangeset>(id, "update", data);
	}
}
