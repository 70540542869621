import controlStore from "./controls";
import dialogStore from "./dialogs";
import multiplayerStore from "./game-client/multiplayer";
import worldStore from "./world";
import wrenchStore from "./wrench";
import eventsEditorStore from "./events-editor";
import playerStore from "./player";
import playerContextStore from "./player-context";
import weaponStore from "./weapon";
import authUserStore from "./auth-user";
import testStore from "./test-world";
import gameStore from "./game";
import gameFPSStore from "./game/game-fps";
import settingsStore from "./settings";
import onboardingStore from "./onboarding";
import loadingScreenStore from "./loading-screen";
import chatStore from "./chat";
import debugStore from "./debug";
import engineStore from "./bb";
import inventoryStore from "./dialogs/inventory";
import selectorStore from "./hud/selector";
import itemStore from "./hud/item";
import bulkBloganStore from "./bulk-blogan";
import cameraToolStore from "./camera-tool";
import manipulatorStore from "./manipulator";
import reviveStore from "./notifications/revive";
import gameHud from "./game-hud";
import gameHudPopup from "./hud/game-hud-popup";
import dialogue from "./dialogue";
import helpersStore from "./helpers";
import alertDialogStore from "./dialogs/alert-dialog";
import customUIStore from "./custom-ui";

export const store = {
	...authUserStore,
	...controlStore,
	...dialogStore,
	...multiplayerStore,
	...worldStore,
	...wrenchStore,
	...eventsEditorStore,
	...playerStore,
	...playerContextStore,
	...weaponStore,
	...testStore,
	...gameStore,
	...gameFPSStore,
	...settingsStore,
	...onboardingStore,
	...loadingScreenStore,
	...chatStore,
	...debugStore,
	...engineStore,
	...dialogStore,
	...inventoryStore,
	...selectorStore,
	...itemStore,
	...bulkBloganStore,
	...cameraToolStore,
	...manipulatorStore,
	...reviveStore,
	...gameHud,
	...gameHudPopup,
	...dialogue,
	...helpersStore,
	...alertDialogStore,
	...customUIStore,
};
