import { useControlsStore } from "@stores/controls";
import { create } from "zustand";

const DEFAULT_ALERT_STATE = {
	open: false,
	alert: {
		title: null,
		message: null,
	},
	onClose: null,
};

type IAlertDialogState = {
	open: boolean;
	alert: {
		title: string | null;
		message: string | null;
	} | null;
	onClose?: (() => void) | null;
	close: (runSideEffects?: boolean) => void;
	toggle: (open: boolean, onClose?: (() => void) | null) => void;
	setAlert: (alert: { title: string; message: string }) => void;
};

export const useAlertDialogStore = create<IAlertDialogState>((set) => ({
	...DEFAULT_ALERT_STATE,
	close: (runSideEffects = true) => {
		if (runSideEffects) {
			useControlsStore.getState().exitPointerLock(false);
		}
		set({ ...DEFAULT_ALERT_STATE });
	},
	toggle: (open, onClose) => {
		set({ open, onClose, alert: null });
	},
	setAlert: (alert) => {
		set({ alert, open: true });
	},
}));

export default {
	useAlertDialog: useAlertDialogStore.getState,
};
