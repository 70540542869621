import { Jacy } from "@jacy-client";
import {
	AIType,
	AssetType,
	lib,
	IAssetKey,
	ICharacter,
	IDateString,
	IIdentifier,
	IParsedCharacter,
} from "jacy";
import { create } from "zustand";
import { Cube, Lightning, PersonSimple } from "@components/icons";

import { useEventsEditorStore } from "@stores/events-editor";
import { IEvent } from "@lib/types/events";

export const TABS = [
	{ label: "Basic", icon: Cube },
	{ label: "Avatar", icon: PersonSimple },
	{ label: "Events", icon: Lightning },
];

type IJacyCharacterEditorState = {
	open: boolean;
	selectedTab: string;
	isCreate?: boolean;

	pk: IAssetKey | null;
	id: IIdentifier;
	name: string;
	aiType: AIType;
	showNametag: boolean;
	dropItem: boolean;
	canKillNPCs: boolean;
	avatar?: IAssetKey | null;
	createdAt: IDateString;
	updatedAt: IDateString;

	// Handlers
	setSelectedTab: (selectedTab: string) => void;
	setName: (name: string) => void;
	setAIType: (aiType: AIType) => void;
	setShowNametag: (showNametag: boolean) => void;
	setDropItem: (dropItem: boolean) => void;
	setCanKillNPCs: (canKillNPCs: boolean) => void;
	setAvatar: (pk: IAssetKey | null) => void;

	// Actions
	close: () => void;
	randomizeName: () => void;
	getCharacter: () => ICharacter;
	newCharacter: () => void;
	editCharacter: (character: IParsedCharacter) => void;
};

const DEFAULT_STATE = {
	pk: null,
	id: "",
	name: "",
	aiType: AIType.NEUTRAL,
	showNametag: true,
	dropItem: true,
	canKillNPCs: false,
	avatar: null,
	events: null,
	createdAt: new Date().toISOString(),
	updatedAt: new Date().toISOString(),
};

export const useJacyCharacterEditorStore = create<IJacyCharacterEditorState>(
	(set, get) => ({
		...DEFAULT_STATE,

		open: false,
		selectedTab: TABS[0].label,

		// Handlers
		setSelectedTab: (selectedTab) => {
			set({ selectedTab });

			if (selectedTab === "Events") {
				useEventsEditorStore.getState().setOpen(true);
			} else {
				useEventsEditorStore.getState().setOpen(false);
			}
		},
		setName: (name) => set({ name }),
		setAIType: (aiType) => set({ aiType }),
		setShowNametag: (showNametag) => set({ showNametag }),
		setDropItem: (dropItem) => set({ dropItem }),
		setCanKillNPCs: (canKillNPCs) => set({ canKillNPCs }),
		setAvatar: (pk) => set({ avatar: pk }),

		// Actions
		close: () => set({ open: false }),
		randomizeName: () => set({ name: Jacy.state.characters.getRandomName() }),
		getCharacter: () => {
			const client = useEventsEditorStore.getState().client;

			if (!client) {
				throw new Error("Events editor client not found");
			}

			const pk = get().pk;

			if (!get().isCreate && !pk) {
				throw new Error("Character identifier not found");
			}

			const events = client.getEvents();

			return {
				pk,
				type: AssetType.CHARACTER,
				id: get().id,
				name: get().name,
				aiType: get().aiType,
				showNametag: get().showNametag,
				dropItem: get().dropItem,
				canKillNPCs: get().canKillNPCs,
				aiAvatar: get().avatar,
				events,
				createdAt: get().createdAt,
				updatedAt: get().updatedAt,
			} as ICharacter;
		},
		newCharacter: () => {
			const name = Jacy.state.characters.getRandomName();
			const today = new Date().toISOString();

			useEventsEditorStore.getState().setEvents(null);
			useEventsEditorStore.getState().setModified(false);

			const avatar = Jacy.state.avatars.getAll()[0];

			set({
				...lib.helpers.objects.cloneObject(DEFAULT_STATE),
				open: true,
				selectedTab: TABS[0].label,
				isCreate: true,
				name,
				avatar: avatar?.pk,
				createdAt: today,
				updatedAt: today,
			});
		},
		editCharacter: (character) => {
			set({
				...lib.helpers.objects.cloneObject(DEFAULT_STATE),
				open: true,
				isCreate: false,
				selectedTab: TABS[0].label,
				pk: character.pk,
				id: character.id,
				name: character.name,
				aiType: character.aiType,
				showNametag: character.showNametag,
				avatar: character.aiAvatar.pk,
				dropItem: character.dropItem,
				canKillNPCs: character.canKillNPCs,
				createdAt: character.createdAt,
				updatedAt: character.updatedAt,
			});

			useEventsEditorStore.getState().setEvents(character.events as IEvent);
		},
	}),
);
