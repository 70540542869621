import { create } from "zustand";
import { persist } from "zustand/middleware";
import { usePermissionStore } from "./player";

export const LOOK = "look";
export const MOVE = "move";
export const SPRINT = "sprint";
export const JUMP = "jump";
export const FLY = "fly";
export const STOP_FLYING = "stop-flying";
export const CAMERA = "camera";
export const BUILD = "build";
export const CHANGE_SLOT = "change-slot";
export const INVENTORY = "inventory";
export const CREATOR_TOOL = "creator-tool";

export const WRENCH_EVENTS_TAB = "wrench-events-tab";
export const EDITOR_TUTORIAL = "editor-tutorial";
export const STYLEGUIDE_DECORATIVE = "styleguide-decorative";
export const STYLEGUIDE_STANDALONE = "styleguide-standalone";

const DEFAULT_IN_GAME_HELPERS = {
	known: {
		[LOOK]: false,
		[MOVE]: false,
		[SPRINT]: false,
		[JUMP]: false,
		[FLY]: false,
		[STOP_FLYING]: false,
		[CAMERA]: false,
		[BUILD]: false,
		[CHANGE_SLOT]: false,
		[INVENTORY]: false,
		[CREATOR_TOOL]: false,
	},
	helpersQueue: [],
	isMonitoring: false,
};

const DEFAULT_BEACONS = {
	beacons: {
		[WRENCH_EVENTS_TAB]: false,
		[EDITOR_TUTORIAL]: false,
		[STYLEGUIDE_DECORATIVE]: false,
		[STYLEGUIDE_STANDALONE]: false,
	},
};

const monitoringTimeouts = {
	[LOOK]: null,
	[MOVE]: null,
	[SPRINT]: null,
	[JUMP]: null,
	[FLY]: null,
	[STOP_FLYING]: null,
	[CAMERA]: null,
	[BUILD]: null,
	[CHANGE_SLOT]: null,
	[INVENTORY]: null,
	[CREATOR_TOOL]: null,
};

export const useHelpersStore = create(
	persist(
		(set, get) => ({
			...DEFAULT_IN_GAME_HELPERS,
			...DEFAULT_BEACONS,
			resetHelpers: () => {
				set(DEFAULT_IN_GAME_HELPERS);
				get().stopMonitoring();
				get().startMonitoring();
			},
			resetBeacons: () => set(DEFAULT_BEACONS),
			queueHelper: (key) =>
				set((state) => ({ helpersQueue: [...state.helpersQueue, key] })),
			dequeueHelper: () =>
				set((state) => ({ helpersQueue: state.helpersQueue.slice(1) })),
			markHelperKnown: (key) => {
				if (monitoringTimeouts[key]) {
					clearTimeout(monitoringTimeouts[key]);
					monitoringTimeouts[key] = null;
				}
				if (!get().isMonitoring || get().known[key]) {
					return;
				}
				set((state) => ({
					helpersQueue: state.helpersQueue.filter((h) => h !== key),
					known: { ...state.known, [key]: true },
				}));
			},
			markBeaconKnown: (key) =>
				set((state) => ({ beacons: { ...state.beacons, [key]: true } })),
			markAllBeaconsKnown: () =>
				set((state) => ({
					beacons: Object.fromEntries(
						Object.keys(state.beacons).map((key) => [key, true]),
					),
				})),
			showHelper: (key) => {
				if (get().known[key] || get().helpersQueue.includes(key)) {
					return;
				}
				get().queueHelper(key);
			},
			markJumpKnown: () => get().markHelperKnown(JUMP),
			markFlyKnown: () => get().markHelperKnown(FLY),
			markStopFlyingKnown: () => get().markHelperKnown(STOP_FLYING),
			markSprintKnown: () => get().markHelperKnown(SPRINT),
			markMoveKnown: () => get().markHelperKnown(MOVE),
			markBuildKnown: () => get().markHelperKnown(BUILD),
			markChangeSlotKnown: () => get().markHelperKnown(CHANGE_SLOT),
			markLookKnown: () => get().markHelperKnown(LOOK),
			markInventoryKnown: () => get().markHelperKnown(INVENTORY),
			markCameraKnown: () => get().markHelperKnown(CAMERA),
			markCreatorToolKnown: () => get().markHelperKnown(CREATOR_TOOL),
			startMonitoring: () => {
				set({ isMonitoring: true });

				monitoringTimeouts[LOOK] = setTimeout(() => get().showHelper(LOOK), 5_000);
				monitoringTimeouts[MOVE] = setTimeout(() => get().showHelper(MOVE), 10_000);
				monitoringTimeouts[SPRINT] = setTimeout(
					() => get().showHelper(SPRINT),
					15_000,
				);
				monitoringTimeouts[JUMP] = setTimeout(() => get().showHelper(JUMP), 20_000);

				if (usePermissionStore.getState().permissions.canFly) {
					monitoringTimeouts[FLY] = setTimeout(() => get().showHelper(FLY), 25_000);
					monitoringTimeouts[STOP_FLYING] = setTimeout(
						() => get().showHelper(STOP_FLYING),
						28_000,
					);
				}

				if (usePermissionStore.getState().permissions.canChangeCamera) {
					monitoringTimeouts[CAMERA] = setTimeout(
						() => get().showHelper(CAMERA),
						35_000,
					);
				}

				if (usePermissionStore.getState().permissions.canBuild) {
					monitoringTimeouts[BUILD] = setTimeout(
						() => get().showHelper(BUILD),
						50_000,
					);
					monitoringTimeouts[CHANGE_SLOT] = setTimeout(
						() => get().showHelper(CHANGE_SLOT),
						70_000,
					);
					monitoringTimeouts[INVENTORY] = setTimeout(
						() => get().showHelper(INVENTORY),
						90_000,
					);
					monitoringTimeouts[CREATOR_TOOL] = setTimeout(
						() => get().showHelper(CREATOR_TOOL),
						110_000,
					);
				}
			},
			stopMonitoring: () => {
				set({ isMonitoring: false });

				Object.values(monitoringTimeouts).forEach((timeout) =>
					clearTimeout(timeout),
				);
			},
		}),
		{
			name: "helpers",
			partialize: (state) =>
				Object.fromEntries(
					Object.entries(state).filter(([key]) =>
						["known", "beacons"].includes(key),
					),
				),
		},
	),
);

export default {
	useHelpers: useHelpersStore.getState,
};
