import { create } from "zustand";
import { Jacy } from "@jacy-client";
import { lib } from "jacy";
import { IExpose } from "@lib/types/expose";
import * as JamangoClient from "@jamango/client";

export type IEngine = Partial<IExpose> & {
	init: (expose: IExpose) => void;
};

class _Engine {
	static instance: IEngine;

	constructor() {
		if (_Engine.instance) return _Engine.instance;
		_Engine.instance = this;
	}

	init(expose: IExpose) {
		Object.assign(this, expose);
	}
}

interface EngineConstructor {
	new (): IEngine;
}

export const Engine: EngineConstructor = _Engine;

type IEngineState = {
	promise: ReturnType<typeof lib.helpers.requests.createRequestPromise<IEngine>>;
	engine: IEngine;
	loading: boolean;
	init: (expose: IExpose) => [typeof Jacy, typeof JamangoClient];
};

export const useEngineStore = create<IEngineState>((set, get) => ({
	promise: lib.helpers.requests.createRequestPromise<IEngine>(),
	engine: null!,
	loading: true,
	setAsLoaded: () => {
		const engine = new Engine();
		get().promise.resolve(engine);
		set({ loading: false, engine });
	},
	init: (expose: IExpose) => {
		const engine = new Engine();
		engine.init(expose);
		return [Jacy, JamangoClient];
	},
}));

export default {
	useEngine: useEngineStore.getState,
};
