import useSWR from "swr";
import { api } from "rest-client";
import { lib } from "jacy";

import { GlobeHemisphereWest } from "@components/icons";
import { useLocation } from "react-router-dom";

import { useJacyUserStore } from "@stores/jacy/user";
import { useMultiplayerStore } from "@stores/game-client/multiplayer";

import { Skeleton } from "@components/ui/Skeleton";
import { HubReturnButton } from "../HubReturnButton";
import { HubWorldCard } from "../../HubWorldCard";

export function HubUserWorlds({ selected, setScreen }) {
	const location = useLocation();
	const servers = useMultiplayerStore((state) => state.servers);
	const username = useJacyUserStore((state) => state.username);

	const { data: initialWorlds, mutate: mutateWorlds } = useSWR(
		!lib.helpers.general.isNullish(username) ? ["/worlds", username] : null,
		() =>
			!lib.helpers.general.isNullish(username)
				? api.world.loadUserWorlds()
				: undefined,
	);

	const worlds = initialWorlds?.map((world) => {
		const collaborators = world.collaborators.map(
			(collaborator) => collaborator.username,
		);
		const host = servers.find(
			(host) =>
				host.worldID === world.identifier &&
				(host.dedicated ||
					world.owner.username === host.username ||
					collaborators.includes(host.username)),
		);

		return { ...world, host };
	});

	return (
		<div
			id="tabpanel-world"
			role="tabpanel"
			tabIndex="0"
			aria-labelledby="tab-world"
			className={
				"flex h-full-dialog flex-col justify-between overflow-y-hidden sm-h:h-m-dialog sm-h:sm:h-dialog " +
				(!selected ? "!hidden" : "")
			}
		>
			<div className="flex-1 overflow-y-auto">
				<div className="relative flex h-[40px] items-center justify-center">
					{location.pathname !== "/" && (
						<HubReturnButton
							onClick={() => setScreen(null)}
							className="absolute left-0"
						/>
					)}

					<h2 className="text-center text-2xl font-medium text-gray-900">
						Load a world
					</h2>
				</div>
				{worlds == null ? (
					<div className="flex flex-col gap-2">
						<span className="sr-only">Loading...</span>
						<div
							aria-hidden="true"
							className="flex flex-col items-center justify-between gap-4 rounded border-b-2 border-gray-400 p-4 shadow md:flex-row"
						>
							<div
								aria-hidden="true"
								className="rounded border-b-2 border-gray-400 bg-gray-200 p-4 text-gray-900"
							>
								<GlobeHemisphereWest className="size-6" />
							</div>
							<div className="flex-1">
								<Skeleton className="w-2/5" />
								<Skeleton size="sm" className="w-4/5" />
							</div>
						</div>
						<div
							aria-hidden="true"
							className="flex flex-col items-center justify-between gap-4 rounded border-b-2 border-gray-400 p-4 shadow md:flex-row"
						>
							<div
								aria-hidden="true"
								className="rounded border-b-2 border-gray-400 bg-gray-200 p-4 text-gray-900"
							>
								<GlobeHemisphereWest className="size-6" />
							</div>
							<div className="flex-1">
								<Skeleton className="w-2/5" />
								<Skeleton size="sm" className="w-4/5" />
							</div>
						</div>
						<div
							aria-hidden="true"
							className="flex flex-col items-center justify-between gap-4 rounded border-b-2 border-gray-400 p-4 shadow md:flex-row"
						>
							<div
								aria-hidden="true"
								className="rounded border-b-2 border-gray-400 bg-gray-200 p-4 text-gray-900"
							>
								<GlobeHemisphereWest className="size-6" />
							</div>
							<div className="flex-1">
								<Skeleton className="w-2/5" />
								<Skeleton size="sm" className="w-4/5" />
							</div>
						</div>
					</div>
				) : worlds.length > 0 ? (
					<ul className="flex flex-col gap-1 divide-y divide-slate-200">
						{worlds.map((world) => (
							<HubWorldCard
								world={world}
								mutateWorlds={mutateWorlds}
								key={world.identifier}
							/>
						))}
					</ul>
				) : (
					<div className="my-6 rounded border-b-2 border-gray-400 px-4 py-8 shadow">
						<p className="text-center text-base font-semibold text-gray-600">
							No saved world.
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
