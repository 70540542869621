import { IAssetKey, ICharacterChangeset, IJacyContent } from "jacy";
import { Engine } from "@stores/bb";
import { JacyActions } from "../JacyActions";
import { useJacyCharacterEditorStore } from "@stores/jacy/character-editor";
import { useJacyRerenderStore } from "@stores/jacy/rerender";
import { useEventsEditorStore } from "@stores/events-editor";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useSelectorStore } from "@stores/hud/selector";
import { useInventoryStore } from "@stores/dialogs/inventory";

export class JacyCharacterActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.client.state;
	}

	newCharacter() {
		try {
			if (!this.#state.user.canSave) {
				throw new Error("User is not allowed to create a new character.");
			}

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "InventoryNewCharacter" });

			useJacyCharacterEditorStore.getState().newCharacter();
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	createCharacter() {
		try {
			const user = this.#state.user.getUser();

			if (!user) {
				throw new Error("User is not set.");
			}

			const newCharacter = useJacyCharacterEditorStore.getState().getCharacter();

			const character = this.#state.characters.create(newCharacter);

			useJacyRerenderStore.getState().forceRerenderCharacters();
			useInventoryStore.getState().setSelectedCharacterId(character.pk);
			useJacyCharacterEditorStore.getState().close();
			useEventsEditorStore.getState().clear();

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "InventoryCreateCharacter" });

			this.#actions.setSuccess(
				`Character "${character.name}" created successfully.`,
			);
			this.#actions.updateChangeset<ICharacterChangeset>(
				character.pk,
				"create",
				character,
			);

			return character.pk;
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editCharacter(pk: IAssetKey) {
		try {
			if (!this.#state.user.canEdit) {
				throw new Error("User is not allowed to edit the character.");
			}

			const character = this.#state.characters.get(pk);

			if (!character) {
				this.#actions.setError("Character not found");
				return;
			}

			const { Metrics } = new Engine();
			Metrics.addCount({ name: "InventoryEditCharacter" });

			useJacyCharacterEditorStore.getState().editCharacter(character);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	updateCharacter() {
		try {
			const updatedCharacter = useJacyCharacterEditorStore.getState().getCharacter();

			const character = this.#state.characters.update(
				updatedCharacter.pk,
				updatedCharacter,
			);

			useSelectorStore.getState().refresh();
			useJacyRerenderStore.getState().forceRerenderCharacters();
			useInventoryStore.getState().setSelectedCharacterId(character.pk);
			useJacyCharacterEditorStore.getState().close();
			useEventsEditorStore.getState().clear();

			this.#actions.updateChangeset<ICharacterChangeset>(
				character.pk,
				"update",
				character,
			);

			const { Metrics } = new Engine();
			Metrics.addCount({
				name: "InventoryUpdateCharacter",
				properties: {
					events: Object.entries(character.events?.nodes ?? {}).length > 0,
				},
			});

			this.#actions.setSuccess(
				`Character "${character.name}" updated successfully.`,
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	async deleteCharacter() {
		try {
			const character = useJacyCharacterEditorStore.getState().getCharacter();
			const isCreate = useJacyCharacterEditorStore.getState().isCreate;

			if (isCreate) {
				useJacyCharacterEditorStore.getState().close();
				return;
			}

			const confirmPrompt = useConfirmPromptStore.getState().prompt;
			const confirmed = await confirmPrompt({
				title: "Delete Character",
				description: `Are you sure you want to "${character.name}" character? This action cannot be undone. You will lose all the data associated with this character.`,
				confirmText: "Yes, delete this character",
			});

			if (!confirmed) return;

			this.#state.characters.delete(character.pk);

			this.#actions.updateChangeset<ICharacterChangeset>(character.pk, "delete");

			useSelectorStore.getState().refresh();
			useJacyRerenderStore.getState().forceRerenderBlocks();
			useJacyCharacterEditorStore.getState().close();
			useEventsEditorStore.getState().clear();

			this.#actions.setSuccess(
				`Character "${character.name}" deleted successfully.`,
			);
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	getInstanceCount(pk: IAssetKey) {
		const { BB, Rete, server } = new Engine();
		if (!BB || !Rete || !server) return;

		const character = this.#state.characters.get(pk);
		if (!character) return;

		const createCharacterEventId = Rete.SPECIAL_ID.NPCCreateCharacter.id;
		let counter = 0;

		for (const blockGroup of BB.world.scene.blockGroups) {
			const events = blockGroup[server]?.reteEditor;
			if (!events) continue;

			for (const current in events.nodes) {
				const node = events.nodes[current];

				if (
					node.defId === createCharacterEventId &&
					node.data.name === character.id.toString()
				) {
					counter += 1;
				}
			}
		}

		return counter;
	}
}
