import { create } from "zustand";
import { lib, IAssetKey, IEnvironmentPreset, ToneMapping } from "jacy";
import { Cloud, CloudFog, Cube, Lightbulb } from "@components/icons";
import { useGameClientStore } from "@stores/game-client/game-client";

function getDefaultValue() {
	return lib.helpers.objects.cloneObject(
		lib.constants.environmentPresets.DEFAULT_ENVIRONMENT_PRESET,
	) as Partial<IEnvironmentPreset>;
}

type IJacyEnvironmentPresetEditorState = Partial<IEnvironmentPreset> & {
	assetKey?: IAssetKey;
	open: boolean;
	selectedTab: string;

	// Handlers
	close: () => void;
	setOpen: (open: boolean) => void;
	setSelectedTab: (tab: string) => void;
	setName: (name: string) => void;
	setHour: (hour: number) => void;
	setAzimuth: (azimuth: number) => void;
	setNightLuminosity: (nightLuminosity: number) => void;
	setSunColor: (sunColor: string) => void;
	setSunIntensity: (sunIntensity: number) => void;
	setToneMapping: (toneMapping: ToneMapping) => void;
	setToneMappingExposure: (toneMappingExposure: number) => void;
	setCloudColor: (cloudColor: string) => void;
	setCloudCoverage: (cloudCoverage: number) => void;
	setCloudDensity: (cloudDensity: number) => void;
	setCloudDistance: (cloudDistance: number) => void;
	setCloudSize: (cloudSize: number) => void;
	setFogColor: (fogColor: string) => void;
	setFogY: (fogY: number) => void;
	setGroundColor: (groundColor: string) => void;
	setSkyColor: (skyColor: string) => void;
	setHaze: (haze: number) => void;
	setSaturation: (saturation: number) => void;
	setSample: (sample: number) => void;
	setStep: (step: number) => void;
	setFogNear: (fogNear: number) => void;
	setFogFar: (fogFar: number) => void;
	setPreset: (preset: {
		general?: Partial<IEnvironmentPreset["preset"]["general"]>;
		light?: Partial<IEnvironmentPreset["preset"]["light"]>;
		sky?: Partial<IEnvironmentPreset["preset"]["sky"]>;
		fog?: Partial<IEnvironmentPreset["preset"]["fog"]>;
	}) => void;

	// Actions
	editPreset: (preset: IEnvironmentPreset) => void;
};

export const TABS = [
	{ label: "General", icon: Cube },
	{ label: "Lights", icon: Lightbulb },
	{ label: "Sky", icon: Cloud },
	{ label: "Fog", icon: CloudFog },
];

export const useJacyEnvironmentPresetEditorStore =
	create<IJacyEnvironmentPresetEditorState>((set, get) => ({
		...getDefaultValue(),

		assetKey: undefined,
		open: false,
		selectedTab: TABS[0].label,

		// Handlers
		close: () => set({ open: false }),
		setOpen: (open) => set({ open }),
		setSelectedTab: (tab) => {
			if (!TABS.some((t) => t.label === tab)) {
				useGameClientStore.getState().setNotification({
					type: "error",
					message: `Tab "${tab}" not found`,
				});
				return;
			}

			set({ selectedTab: tab });
		},
		setName: (name) => set({ name }),
		setHour: (hour) => get().setPreset({ general: { hour } }),
		setAzimuth: (azimuth) => get().setPreset({ general: { azimuth } }),
		setNightLuminosity: (nightLuminosity) =>
			get().setPreset({ light: { nightLuminosity } }),
		setSunColor: (sunColor) => get().setPreset({ light: { sunColor } }),
		setSunIntensity: (sunIntensity) => get().setPreset({ light: { sunIntensity } }),
		setToneMapping: (toneMapping) => get().setPreset({ sky: { toneMapping } }),
		setToneMappingExposure: (toneMappingExposure) =>
			get().setPreset({ sky: { toneMappingExposure } }),
		setCloudColor: (cloudColor) => get().setPreset({ sky: { cloudColor } }),
		setCloudCoverage: (cloudCoverage) => get().setPreset({ sky: { cloudCoverage } }),
		setCloudDensity: (cloudDensity) => get().setPreset({ sky: { cloudDensity } }),
		setCloudDistance: (cloudDistance) => get().setPreset({ sky: { cloudDistance } }),
		setCloudSize: (cloudSize) => get().setPreset({ sky: { cloudSize } }),
		setFogColor: (fogColor) => get().setPreset({ sky: { fogColor } }),
		setFogY: (fogy) => get().setPreset({ sky: { fogy } }),
		setGroundColor: (groundColor) => get().setPreset({ sky: { groundColor } }),
		setHaze: (haze) => get().setPreset({ sky: { haze } }),
		setSaturation: (saturation) => get().setPreset({ sky: { saturation } }),
		setSample: (sample) => get().setPreset({ sky: { sample } }),
		setStep: (step) => get().setPreset({ sky: { step } }),
		setSkyColor: (skyColor) => get().setPreset({ sky: { skyColor } }),
		setFogNear: (fogNear) => get().setPreset({ fog: { fogNear } }),
		setFogFar: (fogFar) => get().setPreset({ fog: { fogFar } }),
		setPreset: (preset) => {
			const existingPreset =
				get().preset ??
				getDefaultValue().preset ??
				({} as IEnvironmentPreset["preset"]);

			set({
				preset: lib.helpers.objects.mergeObject(
					preset,
					existingPreset,
				) as IEnvironmentPreset["preset"],
			});
		},

		// Actions
		editPreset: (preset) => {
			const defaultPreset = getDefaultValue();

			set({
				...lib.helpers.objects.mergeObject(preset || {}, defaultPreset),
				open: true,
				selectedTab: TABS[0].label,
			});
		},
	}));
