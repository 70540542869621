import { router } from "../Router";
import * as GameLifeCycle from "./GameLifeCycle";

export type IParams = {
	token: string | null;
	username: string | null;
	worldID: string | null;
	serverID: string | null;
	matchmake: string | null;
};

export function init() {
	router.subscribe(() => GameLifeCycle.onChangePage());

	window.addEventListener("beforeunload", GameLifeCycle.onPageUnload);
}

export function isPlayPage() {
	return location.pathname.includes("/play");
}

export function pushHistory(path: string) {
	history.pushState("", "", path);
}

export function replaceHistory(path: string) {
	window.history.replaceState(null, "", path);
}

export function navigate(path: string) {
	router.navigate(path);
}

export function getParams(): IParams {
	const params = new URLSearchParams(document.location.search);

	return {
		token: params.get("token"),
		username: params.get("username"),
		worldID: params.get("world"),
		serverID: params.get("game"),
		matchmake: params.get("matchmake"),
	};
}
