import { create } from "zustand";
import { persist } from "zustand/middleware";

import { isMobile } from "@lib/helpers/mobile";
import { Engine } from "./bb";
import { AA_SETTING } from "@engine/base/util/Const.js";

const DEFAULT_SETTINGS = {
	renderDistance: 24, // chunks
	entityRenderDistance: 15, // chunks
	sensitivity: isMobile() ? 0.005 : 0.0025, // radians/pixel
	pixelRatio: 1,
	dynamicShadowsEnabled: false,
	askBeforeQuitting: true,
	occlusionCulling: 1, // 0 - no OC, 1 - GPU OC, 2 - CPU OC (Not Supported Yet),
	fpCameraSmoothing: 0,
	tpCameraSmoothing: 0,
	zPrepassEnabled: false,
	aaSetting: AA_SETTING.FXAA,
};

export const useSettingsStore = create(
	persist(
		(set, get) => ({
			acceptedAlphaTOS: false,
			isFullscreen: false,
			showReloadPanel: false,
			...DEFAULT_SETTINGS,

			// Handlers
			setFullscreen: (isFullscreen) => set({ isFullscreen }),
			toggleAlphaTOS: () =>
				set((state) => ({
					acceptedAlphaTOS: !state.acceptedAlphaTOS,
				})),
			setRenderDistance: (renderDistance) => get().sync({ renderDistance }),
			setEntityRenderDistance: (entityRenderDistance) =>
				get().sync({ entityRenderDistance }),
			setSensitivity: (sensitivity) => get().sync({ sensitivity }),
			setPixelRatio: (pixelRatio) => get().sync({ pixelRatio }),
			setDynamicShadowsEnabled: (dynamicShadowsEnabled) =>
				get().sync({ dynamicShadowsEnabled }),
			setAskBeforeQuitting: (askBeforeQuitting) => get().sync({ askBeforeQuitting }),
			setOcclusionCulling: (occlusionCulling) => get().sync({ occlusionCulling }),
			setZPrepassEnabled: (zPrepassEnabled) => get().sync({ zPrepassEnabled }),
			setAASetting: (aaSetting) => {
				if (aaSetting !== get().aaSetting) {
					set({ showReloadPanel: true });
				}
				get().sync({ aaSetting });
			},
			setShowReloadPanel: (show) => set({ showReloadPanel: show }),
			sync: (settings) => {
				set(settings);
				const { BB, client } = new Engine();
				BB?.[client].settings.sync(settings);
			},
			toggleFullscreen: async () => {
				if (get().isFullscreen) {
					document.exitFullscreen();
					try {
						if ("keyboard" in navigator && "unlock" in navigator.keyboard) {
							navigator.keyboard.unlock();
						}
					} catch (e) {}
				} else {
					const { BB, client } = new Engine();
					try {
						// https://developer.chrome.com/articles/keyboard-lock/
						if ("keyboard" in navigator && "lock" in navigator.keyboard) {
							await navigator.keyboard.lock(["Escape"]);
						}
					} catch (e) {}
					BB?.[client].requestFullscreen(document.body);
				}
			},

			setFPCameraSmoothing: (value) => {
				set({ fpCameraSmoothing: value });
				const { BB, client } = new Engine();
				const camera = BB.world[client].camera;
				camera.CAMERA_PHI_SPEED_FP = value;
				camera.CAMERA_THETA_SPEED_FP = value;
				camera.updateProjectionMatrix();
			},
			setTPCameraSmoothing: (value) => {
				set({ tpCameraSmoothing: value });
				const { BB, client } = new Engine();
				const camera = BB.world[client].camera;
				camera.CAMERA_PHI_SPEED_TP = value;
				camera.CAMERA_THETA_SPEED_TP = value;
				camera.updateProjectionMatrix();
			},

			// Actions
			reset: () => get().sync(DEFAULT_SETTINGS),
			setSettings: ({ acceptedAlphaTOS }) => set({ acceptedAlphaTOS }),
		}),
		{
			name: "settings",
			partialize: (state) =>
				Object.fromEntries(
					Object.entries(state).filter(([key]) => !["isFullscreen"].includes(key)),
				),
		},
	),
);

export default {
	useSettings: useSettingsStore.getState,
};
