import { useMobileStore } from "@stores/game-client/mobile";
import { isMobile, mobileMedia } from "@lib/helpers/mobile";

export const state = {
	isMobile: isMobile(),
};

export function init() {
	const handleDeviceTypeChange = () => {
		state.isMobile = isMobile();
	};

	// @ts-expect-error - currently unused
	mobileMedia()?.addEventListener("change", handleDeviceTypeChange);
	useMobileStore.setState({ isMobile: state.isMobile });
}
