import { create } from "zustand";

const DEFAULT_STATE = {
	imageUrl: "",
	message: "",
	position: "",
	title: "",
};

export const useGameHudPopupStore = create((set) => ({
	...DEFAULT_STATE,

	setHudPopup: (position, title, message, imageUrl) => {
		set({
			imageUrl,
			message,
			position,
			title,
		});
	},

	clearHudPopup: () => {
		set({ ...DEFAULT_STATE });
	},
}));

export default {
	useGameHudPopup: useGameHudPopupStore.getState,
};
