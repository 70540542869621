//https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#modifier_keys
export const MODIFIER_KEYS = Object.freeze([
	"alt",
	"altgraph",
	"capslock",
	"control",
	"fn",
	"fnlock",
	"hyper",
	"meta",
	"numlock",
	"scrolllock",
	"shift",
	"super",
	"symbol",
	"symbollock",
]);

//mouse button to pointer ID conversion enum
export const BUTTON_LEFT = 0;
export const BUTTON_MIDDLE = 1;
export const BUTTON_RIGHT = 2;
export const BUTTON_BACK = 3;
export const BUTTON_FORWARD = 4;

//key+pointer state enum
const STATE_UP = undefined;
const STATE_DOWN = 0;
const STATE_DOWN_QUICK = 1; //pressed and released within the same frame
const STATE_JUST_PRESSED = 2;
const STATE_RELEASED = 3;

export class InputPoll {
	#element;
	#mobileBrowser;

	#keyState;
	#keyDownTime;
	#keyUpTime;
	#keyCount;

	#pointerState;
	#pointerCount;

	#pixelRatio;
	#pointerX;
	#pointerY;
	#pointerDX;
	#pointerDY;

	#scrollX;
	#scrollY;
	#scrollZ;

	#allowPointerLock;
	#pointerLock;

	/**
	 * HTMLElement element - The area of the screen that will be interacted with, usually a game's canvas
	 */
	constructor(element) {
		this.#element = element;
		this.#mobileBrowser = InputPoll.isMobileBrowser();

		//---Input states---//

		this.#keyState = {};
		this.#keyDownTime = {};
		this.#keyUpTime = {};
		this.#keyCount = 0;

		this.#pointerState = {};
		this.#pointerCount = 0;

		this.#pixelRatio = 1;
		this.#pointerX = {};
		this.#pointerY = {};
		this.#pointerDX = {};
		this.#pointerDY = {};

		if (!this.#mobileBrowser) {
			this.#pointerX[0] = 0;
			this.#pointerY[0] = 0;
			this.#pointerDX[0] = 0;
			this.#pointerDY[0] = 0;
		}

		this.#scrollX = 0;
		this.#scrollY = 0;
		this.#scrollZ = 0;

		this.#allowPointerLock = false;
		this.#pointerLock = false;

		//---Event listeners---///

		const e = (this.events = {
			//document
			keydown: (e) => {
				if (!e.key) return;

				const key = e.key.toLowerCase();

				// prevent default tab behaviour when pointer lock is enabled
				// allow f# shortcuts (reload, devtools)
				if (
					(this.#pointerLock && (key === "tab" || !/^f\d+$/.test(key))) ||
					key === "f11"
				) {
					e.preventDefault();
				}

				if (e.repeat) return;

				if (
					this.#keyState[key] === STATE_UP ||
					this.#keyState[key] === STATE_DOWN_QUICK
				) {
					this.#keyState[key] = STATE_JUST_PRESSED;
					this.#keyDownTime[key] = performance.now();
					this.#keyCount++;
				}
			},

			//document
			keyup: (e) => {
				if (!e.key) return;
				const key = e.key.toLowerCase();

				if (this.#keyState[key] === STATE_JUST_PRESSED) {
					this.#keyState[key] = STATE_DOWN_QUICK;
					this.#keyUpTime[key] = performance.now();
				} else {
					this.#keyState[key] = STATE_RELEASED;
					this.#keyUpTime[key] = performance.now();

					this.#keyCount = Math.max(this.#keyCount - 1, 0);
				}
			},

			//element
			wheel: (e) => {
				const r = this.#pixelRatio;

				this.#scrollX += e.deltaX * r;
				this.#scrollY += e.deltaY * r;
				this.#scrollZ += e.deltaZ * r;
			},

			//element
			contextmenu: (e) => e.preventDefault(),

			//window
			focus: () => this.reset(),

			//window
			blur: () => this.reset(),

			//element
			touchstart: (e) => {
				for (const touch of e.changedTouches) {
					const id = touch.identifier;
					const r = this.#pixelRatio;

					this.#pointerState[id] = STATE_JUST_PRESSED;
					this.#pointerX[id] = touch.clientX * r;
					this.#pointerY[id] = element.height - touch.clientY * r;
					this.#pointerDX[id] = 0;
					this.#pointerDY[id] = 0;
					this.#pointerCount++;
				}
			},

			//element
			touchend: (e) => {
				for (const touch of e.changedTouches) {
					const id = touch.identifier;

					if (this.#pointerState[id] === STATE_JUST_PRESSED) {
						this.#pointerState[id] = STATE_DOWN_QUICK;
					} else {
						delete this.#pointerState[id];
						delete this.#pointerX[id];
						delete this.#pointerY[id];
						delete this.#pointerDX[id];
						delete this.#pointerDY[id];
						this.#pointerCount = Math.max(this.#pointerCount - 1, 0);
					}
				}
			},

			//element
			touchmove: (e) => {
				e.preventDefault();

				for (const touch of e.changedTouches) {
					const id = touch.identifier;
					const r = this.#pixelRatio;

					const x = touch.clientX * r;
					const y = element.height - touch.clientY * r;
					this.#pointerDX[id] = this.#pointerDX[id] + x - this.#pointerX[id];
					this.#pointerDY[id] = this.#pointerDY[id] + y - this.#pointerY[id];
					this.#pointerX[id] = x;
					this.#pointerY[id] = y;
				}
			},

			//element
			mousedown: (e) => {
				const id = e.button;
				const r = this.#pixelRatio;

				this.#pointerState[id] = STATE_JUST_PRESSED;
				this.#pointerX[0] = e.clientX * r;
				this.#pointerY[0] = element.height - e.clientY * r;
				this.#pointerCount++;
			},

			//element
			mouseup: (e) => {
				const id = e.button;

				if (this.#pointerState[id] === STATE_JUST_PRESSED) {
					this.#pointerState[id] = STATE_DOWN_QUICK;
				} else {
					delete this.#pointerState[id];
					this.#pointerCount = Math.max(this.#pointerCount - 1, 0);
				}
			},

			//element
			mousemove: (e) => {
				const r = this.#pixelRatio;

				this.#pointerX[0] = e.clientX * r;
				this.#pointerY[0] = element.height - e.clientY * r;
				this.#pointerDX[0] = this.#pointerDX[0] + e.movementX * r;
				this.#pointerDY[0] = this.#pointerDY[0] - e.movementY * r;
			},

			//document
			pointerlockchange: () => {
				this.reset();
				this.#pointerLock = document.pointerLockElement === this.#element;
			},

			//element
			pointerdown: () => {
				if (!this.#pointerLock) element.requestPointerLock();
			},
		});

		document.addEventListener("keyup", e.keyup);
		document.addEventListener("keydown", e.keydown);
		document.addEventListener("wheel", e.wheel);
		element.addEventListener("contextmenu", e.contextmenu);
		window.addEventListener("focus", e.focus);
		window.addEventListener("blur", e.blur);

		if (this.#mobileBrowser) {
			element.addEventListener("touchstart", e.touchstart);
			element.addEventListener("touchend", e.touchend);
			element.addEventListener("touchmove", e.touchmove);
		} else {
			element.addEventListener("mousedown", e.mousedown);
			element.addEventListener("mouseup", e.mouseup);
			element.addEventListener("mousemove", e.mousemove);
		}
	}

	/**
	 * Checks whether the user is using a mobile browser.
	 * @returns boolean
	 */
	isMobileBrowser() {
		return this.#mobileBrowser;
	}

	/**
	 * Set the pixel ratio that pointer coordinates will be multiplied by
	 */
	setPixelRatio(pixelRatio) {
		this.#pixelRatio = pixelRatio;
	}

	/**
	 * Check if a key combo is currently being pressed
	 * Usage: if(input.keysAreDown(["ctrl", "a"]))
	 * List of all checkable keys (remember to lower case-ify the string): https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
	 */
	keysAreDown(keys) {
		//assert all keys in combo are pressed
		for (const k of keys) if (this.#keyState[k] === STATE_UP) return false;

		return this.testCombo(keys);
	}

	/**
	 * Check if a key combo has been held for a given amount of time
	 * Usage: if(input.keysAreHeldForTime(["ctrl", "a"], 1000))
	 *
	 * @param {string[]} keys array of keys
	 * @param {number} timeMilliseconds time in milliseconds
	 */
	keysAreHeldForTime(keys, timeMilliseconds) {
		for (const k of keys) {
			if (this.#keyState[k] !== STATE_RELEASED && this.#keyState[k] !== STATE_DOWN)
				return false;

			if (this.#keyState[k] === STATE_DOWN) {
				if (performance.now() - this.#keyDownTime[k] < timeMilliseconds)
					return false;
			}

			if (this.#keyState[k] === STATE_RELEASED) {
				if (this.#keyUpTime[k] - this.#keyDownTime[k] < timeMilliseconds)
					return false;
			}
		}

		return true;
	}

	/**
	 * Check if a key combo was pressed and released within a given time frame
	 * Usage: if(input.keysArePressedAndReleasedWithinTime(["ctrl", "a"], 1000))
	 *
	 * @param {string[]} keys array of keys
	 * @param {number} timeMilliseconds time in milliseconds
	 */
	keysArePressedAndReleasedWithinTime(keys, timeMilliseconds) {
		for (const k of keys) {
			if (this.#keyState[k] !== STATE_RELEASED) return false;

			if (this.#keyUpTime[k] - this.#keyDownTime[k] > timeMilliseconds) return false;
		}

		return true;
	}

	/**
	 * Check if a key combo was just pressed
	 * Usage: if(input.keysAreJustPressed(["ctrl", "a"]))
	 * List of all checkable keys (remember to lower case-ify the string): https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
	 */
	keysAreJustPressed(keys) {
		//assert all keys in combo are pressed
		for (let i = 0; i < keys.length; i++) {
			const state = this.#keyState[keys[i]];
			const last = i === keys.length - 1;

			if (
				(!last && state === STATE_UP) ||
				(last &&
					(state === STATE_UP || state === STATE_RELEASED || state === STATE_DOWN))
			)
				return false;
		}

		return this.testCombo(keys);
	}

	/**
	 * Prompts the user to bind a new key combo.
	 * The promise resolves with a string array that can be used in keysAreDown or keysAreJustPressed.
	 * If escape is pushed, the promise resolves with undefined
	 * @param {HTMLElement} keyDOM (optional) Element to display the current keys being entered.
	 * @returns Promise
	 */
	promptNewKeyCombo(keyDOM) {
		return new Promise((resolve) => {
			let combo = [];

			const keyDown = (e) => {
				e.preventDefault();

				if (this.isPointerLocked()) return;

				const newKey = e.key.toLowerCase();
				if (newKey === "escape") {
					resolvePromise();
					return;
				}

				if (!combo.includes(newKey)) {
					combo.push(newKey);
					if (keyDOM) keyDOM.innerText = this.getKeyComboString(combo);
				}
			};

			const keyUp = (e) => {
				e.preventDefault();

				if (this.isPointerLocked() || combo.length === 0) return;

				resolvePromise(combo);
			};

			function pointerLockChange() {
				combo = [];
			}

			document.addEventListener("keydown", keyDown);
			document.addEventListener("keyup", keyUp);
			document.addEventListener("pointerlockchange", pointerLockChange);

			function resolvePromise(result) {
				document.removeEventListener("pointerlockchange", pointerLockChange);
				document.removeEventListener("keyup", keyUp);
				document.removeEventListener("keydown", keyDown);
				resolve(result);
			}
		});
	}

	/**
	 * Transforms an array of key combinations to a human readable string.
	 * @param {string[]} keyCombo array of key combinations.
	 * @returns string
	 */
	getKeyComboString(keyCombos = []) {
		return keyCombos
			.map((key) => (key === " " ? "space" : key))
			.map((key) => (key === "control" ? "ctrl" : key))
			.map((key) => key[0].toUpperCase() + key.substr(1)) //capitalize
			.join(" + ");
	}

	/**
	 * ID is either a BUTTON_ constant (desktop) or a pointer ID (mobile)
	 */
	isPointerDown(id) {
		return this.#pointerState[id] !== STATE_UP;
	}

	/**
	 * ID is either a BUTTON_ constant (desktop) or a pointer ID (mobile)
	 */
	isPointerJustPressed(id) {
		return !!this.#pointerState[id];
	}

	/**
	 * ID is either 0 (desktop) or a pointer ID (mobile)
	 * In desktop mode, will return 0 until the first mouse event
	 * In mobile mode, will return undefined if pointer #ID is not down
	 */
	getPointerX(id) {
		return this.#pointerX[id];
	}

	/**
	 * ID is either 0 (desktop) or a pointer ID (mobile)
	 * In desktop mode, will return 0 until the first mouse event
	 * In mobile mode, will return undefined if pointer #ID is not down
	 */
	getPointerY(id) {
		return this.#pointerY[id];
	}

	/**
	 * D for delta (movement)
	 * In mobile mode, will return undefined if pointer #ID is not down
	 */
	getPointerDX(id) {
		return this.#pointerDX[id];
	}

	/**
	 * D for delta (movement)
	 * In mobile mode, will return undefined if pointer #ID is not down
	 */
	getPointerDY(id) {
		return this.#pointerDY[id];
	}

	getScrollX() {
		return this.#scrollX;
	}

	getScrollY() {
		return this.#scrollY;
	}

	getScrollZ() {
		return this.#scrollZ;
	}

	/**
	 * Returns the most recently added pointer ID whose state is JUST_PRESSED, or undefined
	 */
	getNewPointer() {
		for (const id in this.#pointerState)
			if (this.#pointerState[id] === STATE_JUST_PRESSED) return id;
	}

	isPointerLocked() {
		return this.#pointerLock;
	}

	getAllowPointerLock() {
		return this.#allowPointerLock;
	}

	setAllowPointerLock(allow) {
		if (this.#mobileBrowser) {
			this.#allowPointerLock = allow;
			this.#pointerLock = allow;
		} else {
			if (allow && !this.#allowPointerLock) {
				document.addEventListener(
					"pointerlockchange",
					this.events.pointerlockchange,
				);
				this.#element.addEventListener("pointerdown", this.events.pointerdown);
			} else if (!allow && this.#allowPointerLock) {
				document.removeEventListener(
					"pointerlockchange",
					this.events.pointerlockchange,
				);
				this.#element.removeEventListener("pointerdown", this.events.pointerdown);

				this.#pointerLock = false;
				this.reset();
				document.exitPointerLock();
			}

			this.#allowPointerLock = allow;
		}
	}

	forcePointerLock(enable) {
		if (!this.#allowPointerLock || this.#mobileBrowser) return;

		if (enable) this.#element.requestPointerLock();
		else document.exitPointerLock();
	}

	/**
	 * You must call this at the end of each animation frame
	 */
	update() {
		for (const i in this.#keyState) {
			if (
				this.#keyState[i] === STATE_DOWN_QUICK ||
				this.#keyState[i] === STATE_RELEASED
			) {
				delete this.#keyState[i];
				delete this.#keyDownTime[i];
				delete this.#keyUpTime[i];
				this.#keyCount = Math.max(this.#keyCount - 1, 0);
			} else {
				this.#keyState[i] = STATE_DOWN;
			}
		}

		for (const i in this.#pointerState) {
			if (this.#pointerState[i] === STATE_DOWN_QUICK) {
				if (this.#mobileBrowser) {
					delete this.#pointerX[i];
					delete this.#pointerY[i];
					delete this.#pointerDX[i];
					delete this.#pointerDY[i];
				}

				delete this.#pointerState[i];
				this.#pointerCount = Math.max(this.#pointerCount - 1, 0);
			} else {
				this.#pointerState[i] = STATE_DOWN;
			}
		}

		for (const i in this.#pointerDX) this.#pointerDX[i] = 0;

		for (const i in this.#pointerDY) this.#pointerDY[i] = 0;

		this.#scrollX = 0;
		this.#scrollY = 0;
		this.#scrollZ = 0;
	}

	/**
	 * Removes all event listeners
	 */
	dispose() {
		const element = this.#element;
		const e = this.events;

		this.setAllowPointerLock(false);

		if (this.#mobileBrowser) {
			element.removeEventListener("touchmove", e.touchmove);
			element.removeEventListener("touchend", e.touchend);
			element.removeEventListener("touchstart", e.touchstart);
		} else {
			element.removeEventListener("mousemove", e.mousemove);
			element.removeEventListener("mouseup", e.mouseup);
			element.removeEventListener("mousedown", e.mousedown);
		}

		window.removeEventListener("blur", e.blur);
		window.removeEventListener("focus", e.focus);
		element.removeEventListener("contextmenu", e.contextmenu);
		document.removeEventListener("wheel", e.wheel);
		document.removeEventListener("keydown", e.keydown);
		document.removeEventListener("keyup", e.keyup);
	}

	reset() {
		for (const i in this.#keyState) delete this.#keyState[i];
		for (const i in this.#keyDownTime) delete this.#keyDownTime[i];
		for (const i in this.#keyUpTime) delete this.#keyUpTime[i];

		for (const i in this.#pointerState) delete this.#pointerState[i];

		if (this.#mobileBrowser) {
			for (const i in this.#pointerX) delete this.#pointerX[i];

			for (const i in this.#pointerY) delete this.#pointerY[i];

			for (const i in this.#pointerDX) delete this.#pointerDX[i];

			for (const i in this.#pointerDY) delete this.#pointerDY[i];
		} else {
			this.#pointerDX[0] = 0;
			this.#pointerDY[0] = 0;
		}

		this.#keyCount = 0;
		this.#pointerCount = 0;
		this.#scrollX = 0;
		this.#scrollY = 0;
		this.#scrollZ = 0;
	}

	//EVERYTHING BELOW THIS LINE IS MEANT FOR INTERNAL USE ONLY

	testCombo(keys) {
		if (keys.length > 1) {
			let comboPrvI;
			for (const i in this.#keyState) {
				//fail if modifier key is pressed that isn't in the combo (ctrl+c has no shift)
				if (InputPoll.comboHasModifier(keys) && MODIFIER_KEYS.includes(i))
					for (const m of MODIFIER_KEYS)
						if (i === m && !keys.includes(m)) return false;

				//assert keys are pressed in the correct order (not c+ctrl)
				//this relies on input.keyState being iterated in the same order the keys were pressed. chrome and firefox both do it correctly at least
				const comboCurI = keys.indexOf(i);
				if (comboCurI < 0) continue;
				if (comboCurI < comboPrvI) return false;
				else comboPrvI = comboCurI;
			}
		}

		return true;
	}

	static comboHasModifier(keys) {
		for (const k of keys) if (MODIFIER_KEYS.includes(k)) return true;

		return false;
	}

	//Modified from http://detectmobilebrowsers.com/
	static isMobileBrowser() {
		const regex1 =
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
		const regex2 =
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;
		const regex3 = /iPad|iPhone|iPod/i;

		const agent = navigator.userAgent || navigator.vendor || window.opera;
		return (
			regex1.test(agent) || regex2.test(agent.substr(0, 4)) || regex3.test(agent)
		);
	}
}
