import { constants } from "rest-client";

export default `
    @font-face {
        font-family: 'Inter Tight';
        src: url(${constants.getStaticFile("assets/frontend/fonts/Inter_Tight/InterTight-Regular.woff2")}) format('woff2'); 
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
`;
