import React from "react";
import { cn } from "@lib/helpers/cn";

export type IInputProps = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	variant?: "outline" | "filled";
	squashable?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
	({ className, variant = "outline", squashable = false, ...props }, ref) => {
		return (
			<input
				ref={ref}
				className={cn(
					"flex items-center gap-2 rounded-md border px-4 text-base focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-blue-300 disabled:opacity-50",
					variant === "outline" &&
						"border-slate-400 bg-white text-slate-600 placeholder:text-slate-400",
					variant === "filled" &&
						"border-transparent bg-slate-100 text-slate-900 placeholder:text-slate-600",
					squashable ? "py-1 sm-h:py-2" : "py-2",
					className,
				)}
				{...props}
			/>
		);
	},
);

Input.displayName = "Input";
