import React from "react";
import {
	useAuthDialogStore,
	useAuthUserStore,
	LOADING_STATE,
} from "@stores/auth-user";
import { useJacyUserStore } from "@stores/jacy/user";

import { Dialog } from "@components/ui/Dialog";
import { AlertDialog } from "@components/ui/AlertDialog";
import { ErrorNote } from "@components/ui/ErrorNote";
import { Button } from "@components/ui/Button";
import { Input } from "@components/ui/Input";

function DialogContainer({ children, isAlert = false }) {
	const open = useAuthDialogStore((state) => state.open === "register");
	const openRegister = useAuthDialogStore((state) => state.openRegister);
	const close = useAuthDialogStore((state) => state.close);

	if (isAlert) {
		return (
			<AlertDialog.Root
				open={open}
				onOpenChange={(value) => (value ? openRegister() : close())}
			>
				<AlertDialog.Content
					overlayClassname="!bg-opacity-80"
					className="z-50 w-full max-w-lg"
				>
					<div className="h-full overflow-y-auto rounded border-b border-gray-400 bg-white px-8 py-6 shadow-xl">
						<div className="flex justify-between gap-8">
							<AlertDialog.Title className="text-2xl font-medium">
								Create your profile
							</AlertDialog.Title>
						</div>

						{children}
					</div>
				</AlertDialog.Content>
			</AlertDialog.Root>
		);
	}

	return (
		<Dialog.Root
			open={open}
			onOpenChange={(value) => (value ? openRegister() : close())}
		>
			<Dialog.Content
				overlayClassname="!bg-opacity-80"
				className="z-50 w-full max-w-lg"
			>
				<div className="h-full overflow-y-auto rounded border-b border-gray-400 bg-white px-8 py-6 shadow-xl">
					<div className="flex justify-between gap-8">
						<Dialog.Title className="text-2xl font-medium">
							Create your profile
						</Dialog.Title>
					</div>

					{children}
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
}

export function RegisterDialog() {
	const hasFullAccess = useJacyUserStore((state) => state.hasFullAccess);

	const isAlert = useAuthDialogStore((state) => state.isAlert);
	const openLogin = useAuthDialogStore((state) => state.openLogin);
	const register = useAuthUserStore((state) => state.register);
	const error = useAuthUserStore((state) => state.error);
	const loading = useAuthUserStore(
		(state) => state.loading === LOADING_STATE.register,
	);

	const [username, setUsername] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [accessCode, setAccessCode] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

	const submit = async (evt) => {
		evt.preventDefault();
		await register({
			accessCode,
			username,
			email,
			password,
			passwordConfirmation,
			hasFullAccess,
		});
	};

	return (
		<DialogContainer isAlert={isAlert}>
			<form className="my-4" onSubmit={submit}>
				<ErrorNote message={error} />

				{!hasFullAccess && (
					<div className="my-4">
						<label htmlFor="access-code" className="text-base text-gray-500">
							Access code
						</label>
						<Input
							id="access-code"
							type="text"
							className="my-1 w-full"
							value={accessCode}
							onChange={(evt) => setAccessCode(evt.target.value)}
							required
							autoFocus
						/>
					</div>
				)}
				<div className="my-4">
					<label htmlFor="email" className="text-base text-gray-500">
						Email
					</label>
					<Input
						type="email"
						id="email"
						name="email"
						className="my-1 w-full"
						value={email}
						onChange={(evt) => setEmail(evt.target.value)}
						required
					/>
				</div>
				<div className="my-4">
					<label htmlFor="username" className="text-base text-gray-500">
						Username
					</label>
					<Input
						type="text"
						id="username"
						className="my-1 w-full"
						value={username}
						onChange={(evt) => setUsername(evt.target.value)}
						required
					/>
				</div>
				<div className="my-4">
					<label htmlFor="password" className="text-base text-gray-500">
						Password
					</label>
					<Input
						type="password"
						id="password"
						name="password"
						className="my-1 w-full"
						value={password}
						onChange={(evt) => setPassword(evt.target.value)}
						required
					/>
				</div>
				<div className="my-4">
					<label htmlFor="password-confirmation" className="text-base text-gray-500">
						Password confirmation
					</label>
					<Input
						type="password"
						id="password-confirmation"
						name="password-confirmation"
						className="my-1 w-full"
						value={passwordConfirmation}
						onChange={(evt) => setPasswordConfirmation(evt.target.value)}
						required
					/>
				</div>
				<Button isSubmit fullWidth disabled={loading}>
					{loading ? "Creating..." : "Create an account"}
				</Button>
			</form>

			<p className="my-2 text-center text-sm text-gray-500">
				Already have an account?&nbsp;
				<button type="button" className="text-link" onClick={openLogin}>
					Log in
				</button>
				.
			</p>
		</DialogContainer>
	);
}
