import physxJS from "physx";
import { handleEngineMetric } from "@lib/helpers/analytics/analyzeMe";
import { store } from "@stores/engine";
import { helpers } from "@lib/helpers/index";
import { lib } from "jacy";
import { GameLifeCycle } from "@jamango/client";

export async function loadEngine(deattach = true) {
	try {
		const response = await fetch(globalEnv.ENGINE_URL);
		const engineJS = await response.text();
		const blobURL = URL.createObjectURL(
			new Blob([engineJS], { type: "text/javascript" }),
		);
		const engine = await import(/* @vite-ignore */ blobURL);

		const expose = await engine.default({
			apiURL: globalEnv.API_URL,
			dedicated: false,
			engineJS,
			physxJS,
			formatLog: function (format, text) {
				const [color, size] = format;
				const sizeStr = size ? `font-size: ${size}px;` : "";
				// eslint-disable-next-line no-console
				console.log(
					"%c" + text.join(" "),
					`color: #${color}; font-weight: bold; ${sizeStr}`,
				);
			},
			handleMetrics: handleEngineMetric,
		});

		GameLifeCycle.onEngineLoaded(expose);
	} catch (oops) {
		GameLifeCycle.onEngineLoadingFailed(oops);
	}

	if (!deattach) return;

	window.removeEventListener("DOMContentLoaded", loadEngine);
}

export function initEngine() {
	loadEngine();

	window.addEventListener("popstate", function (e) {
		const prevWindow = e.target.location.pathname;
		if (!prevWindow.includes("play")) return;
		// disables going into the stack by replacing the top of stack to null
		if (typeof e.state === "object" && e.state !== null) {
			history.replaceState({ obsolete: true }, "");
			history.pushState(e.state, "");
			// Navigates into the landing page when going back
			if (prevWindow.includes("play")) {
				this.window.location.replace("/");
			}
		}
	});

	window._listeners = {
		state: {
			type: "engineState", // matches engine DOMListener.EVENT_TYPE
			listener: (payload) => {
				if (!payload.detail) return;
				const { state, resolve, reject } = payload.detail;

				if (lib.helpers.general.isNullish(store[state])) {
					reject(
						`Invalid React state requested. Please check stores/engine.js for available state. State: ${state}`,
					);
				} else {
					resolve(store[state]);
				}
			},
		},
		helper: {
			type: "engineHelper",
			listener: (payload) => {
				if (!payload.detail) return;
				const { helper, resolve, reject } = payload.detail;

				if (lib.helpers.general.isNullish(helpers[helper])) {
					reject(
						`Invalid React helper requested. Please check lib/helpers/index.js for available helpers. Helper: ${helper}`,
					);
				} else {
					resolve(helpers[helper]);
				}
			},
		},
	};

	Object.values(window._listeners).forEach(({ type, listener }) => {
		document.removeEventListener(type, listener);
		document.addEventListener(type, listener);
	});
}
