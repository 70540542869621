import {
	validations,
	PermissionClass,
	POV,
	IPermission,
	IJacyContent,
	IPermissionsChangeset,
	CharacterMovementStyle,
} from "jacy";
import { JacyActions } from "../JacyActions";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

export class JacyPermissionsActions {
	#actions: JacyActions;
	#state: IJacyContent["state"];

	constructor(actions: JacyActions) {
		this.#actions = actions;
		this.#state = actions.content.state;
	}

	setPOV(permissionClass: PermissionClass, pov: POV) {
		validations.permissions.pov(pov);
		this.#updatePermissions({ [permissionClass]: { pov } });
	}

	setCanFly(permissionClass: PermissionClass, canFly: boolean) {
		validations.permissions.canFly(canFly);
		this.#updatePermissions({ [permissionClass]: { canFly } });
	}

	setCanBuild(permissionClass: PermissionClass, canBuild: boolean) {
		validations.permissions.canBuild(canBuild, permissionClass);
		this.#updatePermissions({ [permissionClass]: { canBuild } });
	}

	setCanInteract(permissionClass: PermissionClass, canInteract: boolean) {
		validations.permissions.canInteract(canInteract);
		this.#updatePermissions({ [permissionClass]: { canInteract } });
	}

	setFallDamage(permissionClass: PermissionClass, fallDamage: boolean) {
		validations.permissions.fallDamage(fallDamage);
		this.#updatePermissions({ [permissionClass]: { fallDamage } });
	}

	setCanCrouch(permissionClass: PermissionClass, canCrouch: boolean) {
		validations.permissions.canCrouch(canCrouch);
		this.#updatePermissions({ [permissionClass]: { canCrouch } });
	}

	setCanForceRespawn(permissionClass: PermissionClass, canForceRespawn: boolean) {
		validations.permissions.canForceRespawn(canForceRespawn);
		this.#updatePermissions({ [permissionClass]: { canForceRespawn } });
	}

	setPVPDamage(permissionClass: PermissionClass, pvpDamage: number) {
		validations.permissions.pvpDamage(pvpDamage);
		this.#updatePermissions({ [permissionClass]: { pvpDamage } });
	}

	setJumpVelocity(permissionClass: PermissionClass, jumpVelocity: number) {
		validations.permissions.jumpVelocity(jumpVelocity);
		this.#updatePermissions({ [permissionClass]: { jumpVelocity } });
	}

	setSpeedWalk(permissionClass: PermissionClass, speedWalk: number) {
		validations.permissions.speedWalk(speedWalk);
		this.#updatePermissions({ [permissionClass]: { speedWalk } });
	}

	setSpeedSprintMultiplier(
		permissionClass: PermissionClass,
		speedSprintMultiplier: number,
	) {
		validations.permissions.speedSprintMultiplier(speedSprintMultiplier);
		this.#updatePermissions({ [permissionClass]: { speedSprintMultiplier } });
	}

	setMovementStyle(
		permissionClass: PermissionClass,
		movementStyle: CharacterMovementStyle,
	) {
		validations.permissions.movementStyle(movementStyle);
		this.#updatePermissions({ [permissionClass]: { movementStyle } });
	}

	setMaxHorizontalSpeedMultiplier(
		permissionClass: PermissionClass,
		maxHorizontalSpeedMultiplier: number,
	) {
		validations.permissions.maxHorizontalSpeedMultiplier(
			maxHorizontalSpeedMultiplier,
		);
		this.#updatePermissions({ [permissionClass]: { maxHorizontalSpeedMultiplier } });
	}

	setAccelerationGround(
		permissionClass: PermissionClass,
		accelerationGround: number,
	) {
		validations.permissions.accelerationGround(accelerationGround);
		this.#updatePermissions({ [permissionClass]: { accelerationGround } });
	}

	setAccelerationAir(permissionClass: PermissionClass, accelerationAir: number) {
		validations.permissions.accelerationAir(accelerationAir);
		this.#updatePermissions({ [permissionClass]: { accelerationAir } });
	}

	setFrictionGround(permissionClass: PermissionClass, frictionGround: number) {
		validations.permissions.frictionGround(frictionGround);
		this.#updatePermissions({ [permissionClass]: { frictionGround } });
	}

	setFrictionGroundStopMultiplier(
		permissionClass: PermissionClass,
		frictionGroundStopMultiplier: number,
	) {
		validations.permissions.frictionGroundStopMultiplier(
			frictionGroundStopMultiplier,
		);
		this.#updatePermissions({ [permissionClass]: { frictionGroundStopMultiplier } });
	}

	setFrictionAir(permissionClass: PermissionClass, frictionAir: number) {
		validations.permissions.frictionAir(frictionAir);
		this.#updatePermissions({ [permissionClass]: { frictionAir } });
	}

	setFrictionAirStopMultiplier(
		permissionClass: PermissionClass,
		frictionAirStopMultiplier: number,
	) {
		validations.permissions.frictionAirStopMultiplier(frictionAirStopMultiplier);
		this.#updatePermissions({ [permissionClass]: { frictionAirStopMultiplier } });
	}

	setStopSpeedGround(permissionClass: PermissionClass, stopSpeedGround: number) {
		validations.permissions.stopSpeedGround(stopSpeedGround);
		this.#updatePermissions({ [permissionClass]: { stopSpeedGround } });
	}

	setHealthRegenRate(permissionClass: PermissionClass, healthRegenRate: number) {
		validations.permissions.healthRegenRate(healthRegenRate);
		this.#updatePermissions({ [permissionClass]: { healthRegenRate } });
	}

	#updatePermissions(data: Partial<IPermission>) {
		const id = this.#state.permissions.update(data);
		useJacyRerenderStore.getState().forceRerenderPermissions();
		this.#actions.updateChangeset<IPermissionsChangeset>(id, "update", data);
	}
}
