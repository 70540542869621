import {
	AssetType,
	IEnvironmentPreset,
	IEnvironmentPresetChangeset,
	IEnvironmentPresetSettings,
	IAssetKey,
	IJacyContent,
} from "jacy";

import { useJacyEnvironmentPresetEditorStore } from "@stores/jacy/environment-preset-editor";
import { useEngineStore } from "@stores/bb";
import { useConfirmPromptStore } from "@stores/dialogs";
import { useJacyRerenderStore } from "@stores/jacy/rerender";

import { JacyActions } from "../JacyActions";

export class JacyEnvironmentPresetActions {
	#state: IJacyContent["state"];
	#actions: JacyActions;

	constructor(actions: JacyActions) {
		this.#state = actions.content.state;
		this.#actions = actions;
	}

	async changePreset(id: IAssetKey) {
		const currentPreset = this.#state.environmentPreset.current;

		if (!currentPreset) {
			return this.#actions.setError("Failed to find current environment preset.");
		}

		const envPreset = this.#state.environmentPreset.get(id);

		if (!envPreset) {
			return this.#actions.setError(`Failed to find environment preset ${id}`);
		}

		this.#state.environmentPreset.reset(currentPreset.pk);

		try {
			this.#state.environmentPreset.use(id);
		} catch (error) {
			return this.#actions.setError(error);
		}

		useJacyRerenderStore.getState().forceRerenderEnvironmentPreset();

		this.#actions.updateChangeset<IEnvironmentPresetChangeset>(
			`${AssetType.ENVIRONMENT_PRESET}#${this.#state.world.bundleId}`,
			"world:update",
			{
				identifier: envPreset.identifier,
			},
		);

		const settings = this.#formatEngineSettings(envPreset.originalPreset);
		await this.changeEngineSettings(settings);
	}

	async createPreset() {
		const envPreset = this.#state.environmentPreset.create();

		this.#actions.updateChangeset<IEnvironmentPresetChangeset>(
			envPreset.pk,
			"create",
			{
				identifier: envPreset.identifier,
				name: envPreset.name,
				general: envPreset.preset.general,
				fog: envPreset.preset.fog,
				light: envPreset.preset.light,
				sky: envPreset.preset.sky,
			},
		);

		await this.changePreset(envPreset.pk);
		this.editPreset();
	}

	async deletePreset() {
		if (!this.#state.environmentPreset.canDelete) {
			return this.#actions.setError("You are not authorized to delete this preset");
		}

		const envPreset = this.#state.environmentPreset.current;

		if (!envPreset) {
			return this.#actions.setError("Failed to find current environment preset");
		}

		const confirmed = await useConfirmPromptStore.getState().prompt({
			title: "Delete Environment Preset",
			description: `Are you sure you want to delete ${envPreset.name}? This action cannot be undone. Any world using this preset will be reverted to the default preset.`,
			confirmText: "Yes, delete this preset",
			prompt: `delete ${envPreset.name}`,
		});
		if (!confirmed) return;

		this.cancelEditPreset();

		const defaultPreset = this.#state.environmentPreset.getDefault();

		if (!defaultPreset) {
			return this.#actions.setError(
				"Failed to delete preset. There must be a default preset.",
			);
		}

		try {
			await this.changePreset(defaultPreset.pk);
			this.#state.environmentPreset.delete(envPreset.pk);
		} catch (error) {
			return this.#actions.setError(error);
		}

		useJacyRerenderStore.getState().forceRerenderEnvironmentPreset();
		this.#actions.updateChangeset<IEnvironmentPresetChangeset>(
			envPreset.pk,
			"delete",
		);
	}

	async resetPreset() {
		const envPreset = this.#state.environmentPreset.current;

		if (!envPreset) {
			return this.#actions.setError("Failed to find current environment preset");
		}

		useJacyEnvironmentPresetEditorStore
			.getState()
			.setPreset(envPreset.originalPreset);

		const settings = this.#formatEngineSettings(envPreset.originalPreset);
		await this.changeEngineSettings(settings);
	}

	async cancelEditPreset() {
		useJacyEnvironmentPresetEditorStore.getState().close();
		await this.resetPreset();
	}

	keepChanges() {
		const { pk, identifier, name, preset } =
			useJacyEnvironmentPresetEditorStore.getState();

		if (!pk || !name || !preset || !identifier) {
			return this.#actions.setError(
				"Failed to edit preset. Failed to find current environment preset",
			);
		}

		useJacyEnvironmentPresetEditorStore.getState().close();

		try {
			const id = this.#state.environmentPreset.update(pk, name, preset);

			useJacyRerenderStore.getState().forceRerenderEnvironmentPreset();

			this.#actions.updateChangeset<IEnvironmentPresetChangeset>(id, "update", {
				identifier,
				name,
				general: preset.general,
				fog: preset.fog,
				light: preset.light,
				sky: preset.sky,
			});
		} catch (error) {
			this.#actions.setError(error);
		}
	}

	editPreset() {
		const envPreset = this.#state.environmentPreset.current;

		if (!envPreset) {
			return this.#actions.setError("Failed to find current environment preset");
		}

		useJacyEnvironmentPresetEditorStore.getState().editPreset(envPreset);
	}

	#formatEngineSettings(preset: IEnvironmentPreset["preset"]) {
		let settings: Partial<IEnvironmentPresetSettings> = {};

		for (const key in preset) {
			settings = {
				...settings,
				...preset[key as keyof typeof preset],
			};
		}

		return settings;
	}

	async changeEngineSettings(settings: Partial<IEnvironmentPresetSettings>) {
		const { BB, router } = await useEngineStore.getState().promise;
		BB.world.environmentSettings[router].change(settings, true);
	}
}
